import React, { Fragment, useEffect, useState } from "react";
import Popup from "../LiveScoreCardpopups/Popup";
import { PutAPI } from "../Utils/APIConnector";
import { CheckValidation } from "../Utils/common";

export default function AddUserName({
  setShowPage,
  setShowVenueBookingContent,
}) {
  const [fname, setFname] = useState();
  const founduser = parseInt(window.localStorage.getItem("userid"));
  var userid = JSON.parse(founduser);

  const handleSaveUser = async () => {
    var isFormValid = CheckValidation({ formID: "payPopup" });
    if (!isFormValid) {
      return;
    }
    try {
      var raw = JSON.stringify({
        FirstName: fname,
      });
      await PutAPI("/User/UpdateUser/" + userid, raw).then((result) => {
        if (result === undefined || result === "") {
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            window.localStorage.setItem(
              "UserName",
              result.userData.firstName
                ? result.userData.firstName
                : "" + " " + result.userData.lastName
                ? result.userData.lastName
                : ""
            );
            setShowPage({ showLogin: false, userNameShow: false });
          } else {
            alert(result.message);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className="row" id="payPopup">
        <div className="col-12 fw-bold">First Name Required</div>
        <div className="col-12 mt-3 parent">
          <input
            type="text"
            className="form-control"
            placeholder="First Name"
            required
            value={fname}
            onChange={(e) => {
              setFname(e.target.value);
            }}
          />
          <span className="form-text invalid-feedback">
            * This field is Required!
          </span>
        </div>
        <div className="col-12 mt-3 text-center">
          <button
            className="w-auto loginbtn"
            style={{ fontSize: "10px" }}
            onClick={() => {
              handleSaveUser();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Fragment>
  );
}
