import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { CustomGetAPI } from "../Utils/APIConnector";
import { convertTo12HourFormat, VenueDate } from "../Utils/common";

export default function Success() {
  const cardRef = useRef(null);
  const param = useParams();
  const [paymentStatus, setpaymentStatus] = useState();

  useEffect(() => {
    CustomGetAPI(`/payment/paymentreceipt/${param.orderid}`).then((result) => {
      if (result === "" || result === null) {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setpaymentStatus(result);
      }
    });
  }, []);
  const handlePrintCard = useReactToPrint({
    content: () => cardRef.current,
  });
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <>
        {paymentStatus?.isfromVenue && (
          <div className="paymentSuccess mt-5 ">
            {/* <div className="text-end">
              <a className="text-end" onClick={handlePrintCard}>
                Print Reciept
              </a>
            </div> */}

            <div className="mt-3" ref={cardRef}>
              <div className="text-center">
                <img
                  src={`/images/${
                    paymentStatus?.status === 1 ? "correct.svg" : "cancel.svg"
                  }`}
                  className="text-center"
                  alt=""
                />

                <h4 className="mt-3">
                  Payment{" "}
                  {paymentStatus?.status === 1 ? "successful" : "Failed"}!
                </h4>
                {paymentStatus?.status === 1 && (
                  <p className="paymentText">You can now visit and play.</p>
                )}
              </div>
              <Card
                className="mt-2"
                style={{
                  borderRadius: "10px",
                  background: "white",
                  border: "none",
                  boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Card.Body className="">
                  <div className="row">
                    <div className="col-5">
                      <span className="paymentText">Date : </span>
                    </div>
                    <div className="col-7">
                      <span className="paymentText fw-bold">
                        {VenueDate(paymentStatus?.date).formattedDate}
                      </span>
                    </div>
                  </div>
                  {paymentStatus?.packageName && (
                    <div className="row">
                      <div className="col-5">
                        <span className="paymentText">Package : </span>
                      </div>
                      <div className="col-7">
                        <span className="paymentText fw-bold">
                          {paymentStatus?.packageName}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-5">
                      <span className="paymentText">Venue Name : </span>
                    </div>
                    <div className="col-7">
                      <span className="paymentText fw-bold">
                        {paymentStatus?.venueName}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <span className="paymentText">Venue Address :</span>
                    </div>
                    <div className="col-7">
                      <span className="paymentText fw-bold">
                        {paymentStatus?.venueAddress?.addressLine1},&nbsp;
                        {paymentStatus?.venueAddress?.addressLine2}
                      </span>
                    </div>
                  </div>
                  {!paymentStatus?.packageName && (
                    <>
                      <div className="row">
                        <div className="col-5">
                          <span className="paymentText">Time : </span>
                        </div>
                        <div className="col-7">
                          <span className="paymentText fw-bold">
                            {convertTo12HourFormat(paymentStatus?.timeList)}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <span className="paymentText">Duration : </span>
                        </div>
                        <div className="col-7">
                          <span className="paymentText fw-bold">
                            {paymentStatus?.slotDuration}
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  <hr />
                  <div className="row">
                    <div className="col-5">
                      <span className="paymentText">Order number : </span>
                    </div>
                    <div className="col-7">
                      <span className="paymentText fw-bold">
                        {param?.orderid}
                      </span>
                    </div>
                  </div>
                  {paymentStatus?.status === 1 && (
                    <div className="row">
                      <div
                        className="col-5 paymentText fw-bold"
                        style={{ color: "#4CAF50" }}
                      >
                        Amount paid:
                      </div>
                      <div
                        className="col-7 paymentText fw-bold"
                        style={{ color: "#4CAF50" }}
                      >
                        ₹{paymentStatus?.amount}
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
            <div className="mt-5 mb-2">
              <button
                className="venueListingTop w-100 rounded-0 btn-danger"
                // style={{ background: "#4CAF50" }}
                onClick={() => {
                  !paymentStatus?.packageName
                    ? navigate("/venue-booking-details")
                    : navigate("/mypackages");
                }}
              >
                {paymentStatus?.packageName ? "My Packages" : "View Bookings"}
              </button>
            </div>
          </div>
        )}
      </>
    </React.Fragment>
  );
}
