import React, { Fragment } from "react";
import { convertTo12HourFormat, VenueDate } from "../Utils/common";

export default function PackageHistoryCard({ BookingHistory, venueName }) {
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <h4 className="text-center">Booking History</h4>
        </div>
        <div className="col-12">
          {BookingHistory?.length > 0 ? (
            <div className="row mt-2">
              {BookingHistory?.map((booking) => (
                <div className="col-md-12 my-2">
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                      border: "0.5px solid #C4C4C4",
                      padding: "5px 10px",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <b>{venueName}</b>
                      <div>
                        <label style={{ opacity: "0.5" }}>Date:</label>{" "}
                        {VenueDate(booking?.date).formattedDate}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div>
                        <label style={{ opacity: "0.5" }}>Time:</label>{" "}
                        {convertTo12HourFormat(booking?.startTime)}
                      </div>
                      <div>
                        <label style={{ opacity: "0.5" }}>Duration:</label>{" "}
                        {booking?.slotDuration}hrs
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="">No Booking Found</div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
