import React, { useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import { convertDecimalToTime, convertTo12HourFormat } from "../Utils/common";
import { VenueDate } from "../Utils/common";
import Loader from "../Utils/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

export default function VenueBookingList() {
  const navigate = useNavigate();
  const userID = localStorage.getItem("userid");
  const redirectURL = window.localStorage.getItem("RedirectURL");
  const [bookedVenues, setbookedVenues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [HasMore, setHasMore] = useState(false);
  const [PageNumber, setPageNumber] = useState(1);
  //   function VenueDate(dateString) {
  //     const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //     const originalDate = new Date(dateString);
  //     const dayOfWeekIndex = originalDate.getDay();
  //     const formattedDate = `${originalDate.getDate()}/${originalDate.getMonth() + 1}/${originalDate.getFullYear() % 100}`;
  //     const dayOfWeek = daysOfWeek[dayOfWeekIndex];
  //     return { formattedDate, dayOfWeek };
  // }

  useEffect(() => {
    getBookedVenues();
  }, []);

  const getBookedVenues = async () => {
    const raw = JSON.stringify({
      userID: userID ? userID : "0",
      PageNumber: PageNumber,
    });
    await CustomPostAPI(`/Venue/GetBookedVenues`, raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setIsLoading(false);
        setHasMore(result?.hasNext);
        setPageNumber((prevPage) => prevPage + 1);
        setbookedVenues((prevData) => [
          ...prevData,
          ...result.bookedVenueDetails,
        ]);
      }
    });
  };
  return (
    <>
      {isLoading ? (
        <Loader mt="30%" />
      ) : (
        <>
          <div className="Container mb-3">
            {/* <div className="Container my-3" style={{ height: "75dvh" }}> */}
            <div className="row">
              <div className="col-12 text-center position-relative">
                <img
                  className="venuePlaceArrow img-fluid position-absolute"
                  src="/images/backArrow.svg"
                  onClick={(e) => {
                    if (redirectURL) {
                      window.location = redirectURL;
                    } else {
                      navigate("/home");
                    }
                  }}
                />
                <div className="venuePlaceName">Venue Bookings</div>
              </div>
            </div>
            <InfiniteScroll
              dataLength={bookedVenues?.length}
              next={() => getBookedVenues()}
              hasMore={HasMore}
              loader={
                <img
                  className="mb-4 blur-bg"
                  height="50px"
                  width="50px"
                  src="/images/loder.svg"
                  // style={{ marginLeft: "45%" }}
                />
              }
              className="row"
            >
              {" "}
              <div className="venueCard">
                {bookedVenues?.length != 0 ? (
                  bookedVenues?.map((venue, index) => (
                    <Card
                      className="mt-3"
                      style={{
                        borderRadius: "16px",
                        border: "none",
                        boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
                        opacity: venue?.bookingstatus === 2 ? "0.3" : "1",
                      }}
                      onClick={() => {
                        if (venue?.bookingstatus === 1) {
                          navigate(`/user-booking-details`, {
                            state: {
                              id: venue.venuepaymentlogId,
                              packageBooking: venue.ispackagebooking,
                            },
                          });
                        }
                      }}
                    >
                      <Card.Body>
                        <div className="row">
                          <div className="col-12">
                            {venue?.bookingstatus === 2 ? (
                              <>
                                <div className="row">
                                  <div className="col-3">
                                    <Badge
                                      bg="danger"
                                      className="position-absolute top-0 end-0 m-2"
                                    >
                                      Cancelled
                                    </Badge>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {venue?.ispackagebooking ? (
                                  <>
                                    <div className="row">
                                      <div className="col-3 mb-3">
                                        <Badge
                                          bg="danger"
                                          className="position-absolute top-0 end-0 m-2"
                                        >
                                          Package Booking
                                        </Badge>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-4 display-center">
                            <img
                              className="img-fluid"
                              src={venue?.venueImage}
                              alt=""
                            />
                          </div>
                          <div className="col-8">
                            <span
                              className="venueName"
                              style={{ fontWeight: "600" }}
                            >
                              {venue?.venueName}
                            </span>
                            <br />
                            <span className="venueName">{venue?.surface}</span>
                            {/* <div className="row">
                        <span className="bookingText">
                          {VenueDate(venue?.date).dayOfWeek}:{" "}
                          <span className="colorCommon">
                            {VenueDate(venue?.date).formattedDate}
                          </span>
                        </span>
                      </div> */}
                            <div className="row">
                              <span className="bookingText">
                                {VenueDate(venue?.date).dayOfWeek}:{" "}
                                <span className="colorCommon">
                                  {VenueDate(venue?.date).formattedDate}
                                </span>
                              </span>
                            </div>
                            <div className="row">
                              <span className="bookingText">
                                {convertTo12HourFormat(venue.startTime)}
                              </span>
                            </div>
                            <div className="row">
                              <span className="bookingText">
                                {convertDecimalToTime(venue?.slotDuration)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <div className="citiesbox border-0 text-center mt-2">
                    No Booked Venues Found
                  </div>
                )}
              </div>
            </InfiniteScroll>
          </div>
        </>
      )}
    </>
  );
}
