import React, { Fragment, useEffect, useState } from "react";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import Loader from "../Utils/Loader";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import VerifyPhoneNumber from "../VerifyPhoneNumber";
import Popup from "../LiveScoreCardpopups/Popup";
import { convertTo12HourFormat, VenueDate } from "../Utils/common";
import BookingPopup from "./BookingPopup";

export default function PackageDetailsPopup({
  selectedPackage,
  selectedUserPackage,
  myPackages,
  venueName,
  show,
  setShow,
  showPayment,
  setShowPayment,
  expiry,
}) {
  const cookies = new Cookies();
  const Isapp = cookies.get("Isapp");
  const phoneno = window.localStorage.getItem("phoneno");
  const UserEmail = window.localStorage.getItem("UserEmail");
  const username = window.localStorage.getItem("UserName");
  const userID = window.localStorage.getItem("userid");

  useEffect(() => {
    getPackageDetails();
  }, []);
  const [loader, setLoader] = useState(true);
  const [agreedTerm, setAgreedTerm] = useState(true);
  const [paymentConfirmation, setPaymentConfirmation] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState();
  const [selectedPackageDuration, setSelectedPackageDuration] = useState();
  const [packageDetails, setPackageDetails] = useState([]);
  const getPackageDetails = async () => {
    try {
      await CustomGetAPI(
        `/packages/GetPricingByPackageId/${selectedPackage}/${
          myPackages ? userID : 0
        }/${selectedUserPackage ? selectedUserPackage : 0}`
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result?.status == "Success") {
            setLoader(false);
            setPackageDetails(result);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const payNow = async (index) => {
    if (!userID) {
      setShowPayment(true);
      return;
    }
    const existingScript = document.querySelector(
      'script[src="https://checkout.razorpay.com/v1/checkout.js"]'
    );
    if (!Isapp && !existingScript) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }
    const data = JSON.stringify({
      VenueId: packageDetails?.package?.venueId,
      Date: new Date(),
      Amount: packageDetails?.package?.price,
      UserId: userID,
      Discountamount: 0,
      PackageID: selectedPackage,
    });
    await CustomPostAPI("/payment/PackagePaymentInitialize", data).then(
      (result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            const order_id = result.orderResponse.orderId;
            const venuepaymentlogid = result.venuepaymentlogid;

            let options = {
              amount: result.orderResponse.amount,
              name: "Package Booking Fee",
              description: "Total_venue_booking_fee",
              image: "https://dev.cricbuddy.in/images/cricketFlash.svg",
              order_id: order_id,
              handler: async (data) => {
                try {
                  window.scrollTo(0, 0);

                  Swal.fire({
                    icon: "success",
                    text: "Your purchase is confirmed successfully, you will receive notification on your WhatsApp as well as you can check your packages in your account.",
                    showConfirmButton: true,
                    allowOutsideClick: true,
                    confirmButtonText: "View Packages",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.href = "/mypackages";
                    } else {
                      window.location.reload();
                    }
                  });
                  document.body.style.overflow = "auto";
                } catch (error) {
                  // Handle payment failure
                  console.error("Payment failed:", error);
                  CustomGetAPI(
                    `/payment/AddPaymenterror/${error ?? ""}/${userID}`
                  );
                }
              },
              prefill: {
                name: username,
                email: UserEmail ? UserEmail : "",
                contact: phoneno,
              },
              theme: {
                color: "#F3F3F3",
              },
              config: {
                display: {
                  hide: [
                    { method: "paylater" },
                    { method: "netbanking" },
                    { method: "wallet" },
                  ],
                },
              },
              webview_intent: true,

              timeout: 300,
              modal: {
                ondismiss: function () {
                  document.body.style.overflow = "auto";
                },
              },
            };
            if (!Isapp) {
              options.key = result.orderResponse.razorPayAPIKey;
            }
            if (Isapp) {
              options.amount = options.amount * 100;

              const encodedPaymentData = encodeURIComponent(
                JSON.stringify(options)
              );
              window.location.href = `/mobile-booking?paymentData=${encodedPaymentData}`;
            } else {
              const paymentObject = new window.Razorpay(options);
              paymentObject.on("payment.failed", function (response) {
                console.log("error", response);
              });
              paymentObject.open();
            }
          } else {
            var isBooked = document.querySelector(".bookederror");
            isBooked.classList.remove("d-none");
            isBooked.textContent = `${result.message}`;
          }
        }
      }
    );
  };
  return (
    <Fragment>
      {paymentConfirmation ? (
        <div
          className="row mx-0"
          style={{
            border: "0.5px solid #E7666A",
            textAlign: "start",
            fontWeight: "500",
            overflow: "auto",
            borderRadius: "5px",
          }}
        >
          <div className="col-12 p-3  paymentConfirmation">
            <p>
              You are making a purchase of a package at{" "}
              <b style={{ color: "#E7666A" }}>{venueName}</b> located in{" "}
              {packageDetails?.package?.venueaddress}
            </p>
            <div className="d-flex justify-content-between">
              <div className="">
                <label style={{ opacity: "0.5" }}>Package Name:</label>
                <br />
                {packageDetails?.package?.name}
              </div>
              <div className="mb-0">
                <label style={{ opacity: "0.5" }}>Package Price:</label>
                <br />₹ {packageDetails?.package?.price}/-
              </div>
            </div>
            <hr className="mb-0" />
          </div>

          <div className="col-12 px-3 d-flex justify-content-between align-items-center">
            <span
              style={{ opacity: "0.5" }}
              className="text-decoration-underline"
              onClick={() => {
                setPaymentConfirmation(false);
              }}
            >
              Back
            </span>
            <button
              className="packageDetailsBtn"
              style={{ fontSize: "16px" }}
              onClick={() => {
                if (agreedTerm) {
                  payNow();
                } else {
                  Swal.fire({
                    toast: true,
                    icon: "warning",
                    title: `Agree to terms and conditions to proceed`,
                    timer: 3000,
                    showConfirmButton: false,
                    position: "top",
                  });
                }
              }}
            >
              Make Payment
            </button>
          </div>
          <div className="col-12 p-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="termsCheckbox"
                checked={agreedTerm}
                onClick={() => {
                  setAgreedTerm(!agreedTerm);
                }}
              />
              <label
                style={{ opacity: "0.5", fontSize: "12px" }}
                className="form-check-label"
                htmlFor="termsCheckbox"
              >
                I have read and agree to the Terms and Conditions mentioned
                below.
              </label>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12 text-center">
            {/* <h4>Package Details</h4> */}
          </div>
          {loader ? (
            <div>
              <Loader mt="0" />
            </div>
          ) : (
            <>
              <div className="col-12">
                {packageDetails?.pricing?.map((pricing) => {
                  const allDays = [
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                    "Sun",
                  ];
                  const selectedDays =
                    pricing?.weekDays?.split(",").map((day) => day.trim()) ||
                    [];
                  return (
                    <div
                      className="my-3 p-3 borderRadius-6"
                      style={{ border: "0.5px solid #00000033" }}
                    >
                      <b>Applicable Week Days:</b>
                      <div className="d-flex flex-wrap mt-2">
                        {allDays?.map((day, index) => (
                          <div
                            key={index}
                            className="me-2 mb-2 px-2 d-flex align-items-center"
                            style={{
                              border: !selectedDays.includes(day)
                                ? "0.5px solid #0000001A"
                                : "none",
                              background: selectedDays.includes(day)
                                ? "#4caf50"
                                : "#F5F5F5",
                              color: selectedDays.includes(day)
                                ? "white"
                                : "#00000066",
                              fontSize: "14px",
                              borderRadius: "4px",
                            }}
                          >
                            {selectedDays.includes(day) && (
                              <img
                                src="/images/CircleCheckTick.svg"
                                alt="tick"
                                className="me-1"
                                style={{
                                  width: "12px",
                                  height: "12px",
                                }}
                              />
                            )}
                            {day.trim()}
                          </div>
                        ))}
                      </div>

                      {myPackages ? (
                        <>
                          <div className="mt-2 d-flex justify-content-between bookingPackageDetails align-items-center">
                            <div style={{ fontSize: "14px" }}>
                              <label>Timing:</label>{" "}
                              {convertTo12HourFormat(pricing?.startTime) ==
                              convertTo12HourFormat(pricing?.endTime)
                                ? "24hrs"
                                : `${convertTo12HourFormat(
                                    pricing?.startTime
                                  )} to
                          ${convertTo12HourFormat(pricing?.endTime)}`}
                            </div>
                          </div>
                          <div className="mt-2 d-flex justify-content-between bookingPackageDetails align-items-center">
                            <div style={{ fontSize: "14px" }}>
                              <label>Expires on:</label>{" "}
                              {VenueDate(expiry).formattedDate}
                            </div>
                          </div>
                          <div className="mt-1 d-flex justify-content-between bookingPackageDetails align-items-center">
                            <div style={{ fontSize: "14px" }}>
                              <label>Remaining Hours:</label>{" "}
                              {pricing?.remainingDuration}
                            </div>
                            <div>
                              {pricing?.remainingDuration > 0 && (
                                <button
                                  className="px-2 py-1"
                                  style={{
                                    fontSize: "16px",
                                    color: "white",
                                    border: "none",
                                    background: "#E7666A",
                                    borderRadius: "5px",
                                  }}
                                  onClick={() => {
                                    setShow(true);
                                    setSelectedPackageId(pricing?.id);
                                    setSelectedPackageDuration(
                                      pricing?.remainingDuration
                                    );
                                  }}
                                >
                                  Book Now
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-3 d-flex justify-content-between bookingPackageDetails align-items-center">
                            <div style={{ fontSize: "14px" }}>
                              <label>Timing:</label>{" "}
                              {convertTo12HourFormat(pricing?.startTime) ==
                              convertTo12HourFormat(pricing?.endTime)
                                ? "24hrs"
                                : `${convertTo12HourFormat(
                                    pricing?.startTime
                                  )} to
                        ${convertTo12HourFormat(pricing?.endTime)}`}
                            </div>
                            <div style={{ fontSize: "14px" }}>
                              <label>Duration:</label> {pricing?.duration}hrs
                            </div>
                          </div>
                          <div className="mt-1 d-flex justify-content-between bookingPackageDetails align-items-center">
                            <div style={{ fontSize: "14px" }}>
                              <label>Validity:</label>{" "}
                              {packageDetails?.package?.validity} Days
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              {!myPackages ? (
                <div className="col-12 text-center">
                  <div className="row align-items-center">
                    <div className="col-md-7 col-6">
                      {" "}
                      <div
                        className="px-md-3 px-2 py-1 d-flex justify-content-between"
                        style={{
                          border: "0.5px solid #E7666A",
                          textAlign: "start",
                          overflow: "auto",
                          borderRadius: "5px",
                        }}
                      >
                        Helpline
                        <div className="ms-5">
                          {" "}
                          <img
                            onClick={() => {
                              const phoneNumber = "919510923426";
                              const message =
                                "Need help (package plan helpline)";
                              const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                                message
                              )}`;
                              window.open(url, "_blank");
                            }}
                            className="me-2"
                            src="/images/AminWa.svg"
                            alt=""
                          />
                          <a href={`tel:9510923426`}>
                            <img src="/images/CallAdmin.svg" alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-6 text-end">
                      <div>
                        {" "}
                        <button
                          className="px-3 py-1"
                          style={{
                            fontSize: "16px",
                            color: "white",
                            border: "none",
                            background: "#E7666A",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            setPaymentConfirmation(true);
                          }}
                        >
                          Buy this Package
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12 text-center mt-3 ">
                  <div
                    className="px-3 py-2 d-flex justify-content-between"
                    style={{
                      border: "0.5px solid #E7666A",
                      textAlign: "start",
                      overflow: "auto",
                      borderRadius: "5px",
                    }}
                  >
                    Helpline (9510923426){" "}
                    <div>
                      {" "}
                      <img
                        onClick={() => {
                          const phoneNumber = "919510923426";
                          const message = "Need help (package plan helpline)";
                          const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                            message
                          )}`;
                          window.open(url, "_blank");
                        }}
                        className="me-2"
                        src="/images/AminWa.svg"
                        alt=""
                      />
                      <a href={`tel:9510923426`}>
                        <img src="/images/CallAdmin.svg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      <>
        <div className="col-12 text-center mt-3">
          <div
            className="p-3 borderRadius-6"
            style={{
              border: "0.5px solid #E7666A",
              textAlign: "start",
              maxHeight: "300px",
              overflow: "auto",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <b>Terms & Conditions</b>
            </div>
            <div className="py-2">
              <b>Non-Refundable Purchase:</b>
              <p>
                All package plan purchases are final and non-refundable. Under
                no circumstances will refunds be issued for purchased packages,
                regardless of usage or cancellation.
              </p>
            </div>
            <div className="py-2">
              <b>Validity Period:</b>
              <p>
                Each package plan is valid only for the specified duration as
                indicated at the time of purchase.
              </p>
              <p>
                Packages are valid for the specified period only. Unused
                portions will be lost upon expiration.
              </p>
              <p>
                If a venue is undergoing restoration, package validity may be
                extended.
              </p>
            </div>
            <div className="py-2">
              <b>Venue Booking Policies:</b>
              <p>
                Purchasing a package does not guarantee the availability of a
                venue. Any booking under a package will be subject to venue's
                availability for the desired date & time.
              </p>
              <p>
                All venue bookings made through the CricBuddy package are
                subject to the individual venue's booking policies.
              </p>
              <p>
                These policies, including but not limited to cancellation,
                rescheduling, and facility usage rules, are available for review
                on the respective venue's listing page.
              </p>
              <p>
                It is the user's responsibility to read and understand each
                venues booking policy before making a booking.
              </p>
              <p>CricBuddy is not responsible for the venues booking policy.</p>
            </div>
            <div className="py-2">
              <b>Package Usage:</b>
              <p>
                Transferring or sharing package benefits without explicit
                authorization from CricBuddy is prohibited.
              </p>
              <p>
                CricBuddy reserves the right to terminate packages when terms of
                service are broken.
              </p>
            </div>
          </div>
        </div>
      </>

      {showPayment && (
        <Popup
          show={showPayment}
          venueflag={1}
          onHide={() => setShowPayment(false)}
        >
          <VerifyPhoneNumber
            isfromvenueowner={false}
            isActive={0}
            setShowLogin={setShowPayment}
          />
        </Popup>
      )}
      {show && (
        <BookingPopup
          show={show}
          setShow={setShow}
          userID={userID}
          venueName={venueName}
          selectedPackageId={selectedPackageId}
          selectedPackageDuration={selectedPackageDuration}
          selectedUserPackageId={selectedUserPackage}
        />
      )}
    </Fragment>
  );
}
