import React from "react";
import { useState } from "react";
import { CustomPostAPI } from "../../Utils/APIConnector";
import { RedirectedPage } from "./RedirectedPage";
import "../../../css/common.css";

export function LatestLoginForm() {
  const [password, setPassword] = useState("");
  const [apiResult, setResult] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    var raw = JSON.stringify({
      password: password,
      from: null,
      to: null,
    });

    try {
      const result = await CustomPostAPI("/Auth/validatelogin", raw);
      if (result !== undefined || result !== "") {
        localStorage.setItem("User_Authenticated", true);
        const data = JSON.parse(result);
        setResult(data);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  };

  return (
    <React.Fragment>
      {apiResult.length === 0 ? (
        <section className="controlled-width h-100 d-flex justify-content-start align-items-start shadow rounded-2 my-md-4 mx-md-auto">
          <div className="custom-width-class d-flex flex-column gap-4 p-3 p-md-5">
            <h2>Admin Login</h2>
            <form
              // action="/redirected-page"
              className="d-flex flex-column gap-3"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="d-flex flex-column">
                <label>Password:</label>
                <input
                  autoComplete="false"
                  type="password"
                  value={password}
                  className="w-75"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="py-1 rounded-2 w-25 border border-black"
              >
                Login
              </button>
            </form>
          </div>
        </section>
      ) : (
        <RedirectedPage apiResult={apiResult || []} />
      )}
    </React.Fragment>
  );
}
