import React, { Fragment, useEffect, useState } from "react";
import Popup from "../LiveScoreCardpopups/Popup";
import NewVenueBooking from "../VenueListing/NewVenueBooking";
import { CustomGetAPI } from "../Utils/APIConnector";
import Loader from "../Utils/Loader";

export default function BookingPopup({
  show,
  setShow,
  userID,
  venueName,
  selectedPackageId,
  selectedUserPackageId,
  selectedPackageDuration,
}) {
  const [courtData, setCourtData] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [venueData, setVenueData] = useState();
  useEffect(() => {
    if (venueName) {
      fetchCourtData();
      fetchData();
    }
  }, [venueName]);
  async function fetchCourtData() {
    await CustomGetAPI(
      "/Venue/NewvenuebyId/" + venueName + "/" + (userID ? userID : 0)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result?.status === "success") {
          setCourtData(result);
          setIsLoader(false);
        }
      }
    });
  }
  async function fetchData() {
    await CustomGetAPI(
      "/Venue/GetvenuebyID/" + venueName + "/" + (userID ? userID : 0)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result?.status === "Success") {
          // console.log(result?.venue);
          setVenueData(result?.venue);
        }
      }
    });
  }
  return (
    <Fragment>
      {" "}
      <Popup show={show} venueflag={1} onHide={() => setShow(false)}>
        <div className="Container mt-2 ">
          {isLoader ? (
            <div style={{ height: "300px" }}>
              <Loader mt="60%" />
            </div>
          ) : (
            <>
              {venueData && courtData?.data?.length > 0 && (
                <NewVenueBooking
                  venueData={venueData}
                  // isLoading={isLoading}
                  // setIsLoading={setIsLoading}
                  setShow={setShow}
                  couponsData={[]}
                  isPackageBooking={true}
                  courtData={courtData}
                  selectedPackageId={selectedPackageId}
                  selectedUserPackageId={selectedUserPackageId}
                  selectedPackageDuration={selectedPackageDuration}
                />
              )}
            </>
          )}
        </div>
      </Popup>
    </Fragment>
  );
}
