import React, { Fragment, useState } from "react";
import { convertTo12HourFormat, VenueDate } from "../Utils/common";
import Popup from "../LiveScoreCardpopups/Popup";
import PackageHistoryCard from "./PackageHistoryCard";
import PackageDetailsPopup from "./PackageDetailsPopup";
import OffersPopup from "./OffersPopup";
export default function ListingCard({
  packageData,
  payNow,
  setPackageData,
  setSelectedIndex,
  setSelectedPackageId,
  setSelectedPackageDuration,
  userPackagesPage,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showOffers, setShowOffers] = useState(false);
  const [showOfferDetails, setShowOfferDetails] = useState(false);

  const [selectedOffers, setSelectedOffers] = useState([]);
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();
  const [BookingHistory, setBookingHistory] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [selectedPackageName, setSelectedPackageName] = useState("");
  return (
    <Fragment>
      <div className="col-md-7 col-12 m-auto">
        <div className="row mx-0">
          {packageData?.map((item, index) => (
            <div className="col-md-6 ">
              <div className="row mx-0 mb-4 packageListingCard">
                <div className="col-12 d-flex justify-content-between align-items-center p-2">
                  <div className="mt-2 truncate-text">
                    <img src="/images/Location-Booking.svg" alt="" />{" "}
                    <b className="">{item?.venuename}</b>
                  </div>
                  <div className="mt-2">
                    {/* {item?.name} */}
                    <b>({item?.packageDuration}hrs)</b>
                  </div>
                </div>
                <div className="col-12">
                  <hr className="my-1 px-2" />
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center p-2 flex-wrap">
                  <div>
                    <p>Validity (Days)</p>
                    <b> {item?.validity}</b>
                  </div>
                  <div>
                    <p>Offer Price</p>
                    <b>RS {item?.packageHourPrice?.toFixed(0)}/hr</b>
                  </div>
                  <div>
                    <p>Original Price</p>
                    <b style={{ textDecoration: "line-through" }}>
                      RS {item?.actualHourPrice?.toFixed(0)}/hr
                    </b>
                  </div>
                </div>
                <div className="col-12">
                  <hr className="my-1 px-2" />
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center p-2 flex-wrap">
                  <div className="itemPrice">
                    <b>₹ {item?.price}/- </b>
                    <span>{item?.actualprice}</span>
                  </div>
                  {item?.packageList?.length < 2 && (
                    <div>
                      <b className="packageOffTag">
                        {item?.diffPercentage?.toFixed(0)}% OFF
                      </b>
                    </div>
                  )}

                  <div>
                    <button
                      className="packageDetailsBtn"
                      // onClick={() => {
                      //   setShowDetails(true);
                      //   setSelectedVenue(item?.venuename);
                      //   setSelectedPackageName(item?.name);
                      //   setSelectedPackage(item?.id);
                      // }}
                      onClick={() => {
                        if (item?.packageList?.length > 1) {
                          setShowOffers(true);
                          setSelectedOffers(item?.packageList);
                          setSelectedVenue(item?.venuename);
                        } else {
                          setShowDetails(true);
                          setSelectedVenue(item?.venuename);
                          setSelectedPackageName(item?.name);
                          setSelectedPackage(item?.id);
                        }
                      }}
                    >
                      {item?.packageList?.length > 1
                        ? `View ${item?.packageList?.length} offers`
                        : "View Details"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Popup
        show={showHistory}
        venueflag={1}
        onHide={() => setShowHistory(false)}
      >
        <div className="Container mt-2 ">
          <PackageHistoryCard
            BookingHistory={BookingHistory}
            venueName={selectedVenue}
          />
        </div>
      </Popup>
      {showDetails && (
        <Popup
          show={showDetails}
          venueflag={1}
          title={
            <div>
              <h4>
                {selectedPackageName} at{" "}
                <span className="colorClass fw-bold">{selectedVenue}</span>
              </h4>
            </div>
          }
          hideModal={show || showPayment}
          onHide={() => setShowDetails(false)}
        >
          <div className="Container mt-2 ">
            <PackageDetailsPopup
              selectedPackage={selectedPackage}
              show={show}
              setShow={setShow}
              showPayment={showPayment}
              setShowPayment={setShowPayment}
              venueName={selectedVenue}
            />
          </div>
        </Popup>
      )}
      {showOffers && (
        <Popup
          show={showOffers}
          venueflag={1}
          hideModal={showOfferDetails}
          title={
            <div>
              <h4>{selectedVenue}</h4>
            </div>
          }
          onHide={() => setShowOffers(false)}
        >
          <div className="Container mt-2 ">
            <OffersPopup
              selectedOffers={selectedOffers}
              selectedVenue={selectedVenue}
              showDetails={showOfferDetails}
              setShowDetails={setShowOfferDetails}
            />
          </div>
        </Popup>
      )}
    </Fragment>
  );
}
