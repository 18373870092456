import React from "react";
import { Card, Modal } from "react-bootstrap";
import { CustomPostAPI } from "../Utils/APIConnector";
import {
  VenueDate,
  convertTo12HourFormat,
  formatDate,
  venueSportsData,
} from "../Utils/common";
import Swal from "sweetalert2";

export default function VenueOwnerDetailsPopup({ show, setShow }) {
  const UserType = window.localStorage.getItem("UserType");
  const cancel = (id) => {
    CustomPostAPI(`/payment/OfflineCancelbooking/${id}/false`).then(
      (result) => {
        if (result) {
          Swal.fire({
            title: "Booking Cancelled",
            text: "Your Booking Is Cancelled Successfully!",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setShow((prevState) => ({ ...prevState, popup: false }));
              window.location.reload();
            }
          });
        } else {
          alert("Something went worng");
        }
      }
    );
  };
  return (
    <React.Fragment>
      <Modal
        className="popupBtn"
        show={show?.popup}
        onHide={() => {
          setShow((prevState) => ({ ...prevState, popup: false }));
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-center">{show?.title}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div
            className="p-3"
            style={{
              backgroundColor: "#FBFAFA",
            }}
          >
            <div className="row">
              <div className="col-12 mb-2 col-md-4">
                <b>
                  Booked {!show?.data?.isOfflineBooking ? "Online" : "Offline"}{" "}
                </b>
                {show?.data?.bookingstatus === 2 ? (
                  <span style={{ color: "rgb(165, 30, 34)" }}>
                    {" "}
                    (Cancelled)
                  </span>
                ) : null}
              </div>
            </div>
            {!show?.data?.isOfflineBooking ? (
              <>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <span className="bookingdetails">Fees:</span>
                  </div>
                  <div className="col-6 col-md-8">
                    <span className="colorClass fw-bold">
                      ₹{show?.data?.amount}
                    </span>
                  </div>
                  {show?.data?.toPayAmount && (
                    <>
                      <div className="col-6 col-md-4">
                        <span className="bookingdetails">Pending Fees:</span>
                      </div>
                      <div className="col-6 col-md-8">
                        <span className="colorClass fw-bold">
                          ₹{show?.data?.toPayAmount}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : null}
            {show.data.bookingdatelist?.map((slots) => (
              <>
                <div className="row mt-3">
                  <div className="col-6 col-md-4">
                    <span className="bookingdetails">Date:</span>
                  </div>
                  <div className="col-6 col-md-8">
                    <span className="bookingans">
                      {VenueDate(slots?.date).formattedDate}
                    </span>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6 col-md-4">
                    <span className="bookingdetails">Time Slots:</span>
                  </div>
                  <div className="col-6 col-md-8">
                    {slots?.bookings?.map((time, i) => (
                      <div className="bookingans row">
                        <div className="col-12 pe-0 py-1" key={i}>
                          <span>
                            {convertTo12HourFormat(time?.start)}-
                            {convertTo12HourFormat(time?.end)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}

            <div className="row">
              <div className="col-6 col-md-4">
                <span className="bookingdetails">Duration:</span>
              </div>
              <div className="col-6 col-md-8">
                <span className="bookingans">
                  {show?.data?.durationgocart ? (
                    <>{show?.data?.durationgocart} Min</>
                  ) : (
                    <>
                      {show?.data?.duration <= 1
                        ? `${show?.data?.duration} hr`
                        : `${show?.data?.duration} hrs`}
                    </>
                  )}
                </span>
              </div>
            </div>
            {show?.data?.noOfKart && (
              <div className="row">
                <div className="col-6 col-md-4">
                  <span className="bookingdetails">No of kart:</span>
                </div>
                <div className="col-6 col-md-8">
                  <span className="bookingans">
                    <>{show?.data?.noOfKart} </>
                  </span>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-6 col-md-4">
                <span className="bookingdetails">Surface:</span>
              </div>
              <div className="col-6 col-md-8">
                <span className="bookingans">{show?.data?.surface}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-4">
                <span className="bookingdetails">Sport:</span>
              </div>
              <div className="col-6 col-md-8">
                <span className="bookingans">{show?.data?.sport}</span>
              </div>
            </div>
            {(show?.data?.username?.length != 0 ||
              show?.data?.userPhone?.length != 0 ||
              show?.data?.userEmail?.length != 0) && (
              <div className="row mb-4 mt-2">
                <div className="col-12 col-md-12">
                  <b className="">Client Contact</b>
                </div>
                {(show?.data?.username?.length != 0) != "" && (
                  <>
                    <div className="col-6 col-md-4">
                      <span className="bookingdetails">Client Name:</span>
                    </div>
                    <div className="col-6 col-md-8">
                      <span className="bookingans">{show?.data?.username}</span>
                    </div>
                  </>
                )}
                {show?.data?.userPhone?.length != 0 && (
                  <>
                    <div className="col-6 col-md-4">
                      <span className="bookingdetails">Phone:</span>
                    </div>
                    <div className="col-6 col-md-8">
                      <span
                        className="bookingans"
                        style={{ fontWeight: "600" }}
                      >
                        <a href={`tel:${show?.data?.userPhone}`}>
                          {show?.data?.userPhone}
                        </a>
                      </span>
                    </div>
                  </>
                )}

                {UserType != "Staff" && show?.data?.userEmail?.length != 0 && (
                  <>
                    <div className="col-6 col-md-4">
                      <span className="bookingdetails">Email:</span>
                    </div>
                    <div className="col-6 col-md-8">
                      <span
                        className="bookingans"
                        style={{
                          fontSize: "13px",
                          overflowWrap: "break-word",
                        }}
                      >
                        {show?.data?.userEmail}
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}

            {show?.data?.isOfflineBooking ? (
              <>
                <button
                  className="mt-3 btn btn-danger"
                  onClick={(e) => cancel(show?.data?.id)}
                >
                  Cancel Booking
                </button>
              </>
            ) : (
              <></>
            )}
          </div> */}
          <>
            <Card className="border-0 ">
              <Card.Body className="bg-FAF4F4 borderRadius-6">
                <div className="d-flex align-items-center Montserrat fw-500 cl-A51E22 fs-12 justify-content-between">
                  <div>
                    {formatDate(show?.data?.date?.split("T")[0])
                      .split(" ")
                      .slice(0, 2)
                      .join(" ")}{" "}
                    <img
                      className="mx-1"
                      src="/images/centeredDot.svg"
                      alt=""
                    />
                    {convertTo12HourFormat(show?.data?.startTimelist)}(
                    {show?.data?.slotDuration}
                    {show?.data?.slotDuration > 1 ? "hrs" : "hr"})
                  </div>{" "}
                  {show?.data?.isOfflineBooking && UserType != "Staff" && (
                    <div className="text-end">
                      {new Date(
                        `${show?.data?.date?.split("T")[0]}T${
                          show?.data?.startTimelist
                        }`
                      ).setMinutes(
                        new Date(
                          `${show?.data?.date?.split("T")[0]}T${
                            show?.data?.startTimelist
                          }`
                        ).getMinutes() +
                          show?.data?.slotDuration * 60
                      ) >= new Date() && (
                        <img
                          src="/images/CancelBookingIcon.svg"
                          alt=""
                          onClick={(e) =>
                            Swal.fire({
                              title: "Cancel Booking",
                              text: "are you sure you want to cancel?",
                              showConfirmButton: true,
                              confirmButtonText: "Yes",
                              showCancelButton: true,
                              cancelButtonText: "No",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                cancel(show?.data?.id, show?.data?.isrecurring);
                              }
                            })
                          }
                        />
                      )}

                      {show?.data?.ispackagebooking && (
                        <>
                          <br />{" "}
                          <div className="badge bg-danger">Package Booking</div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="d-flex">
                  <div className="w-50">
                    <div className="Montserrat fw-600">
                      <span className="m-0">{show?.data?.username}</span>
                      <div style={{ marginTop: "-8px" }}>
                        <span
                          className="fw-500 fs-12"
                          style={{ color: "#000000B2" }}
                        >
                          {show?.data?.userPhone}
                        </span>
                      </div>
                    </div>
                    <div
                      className="Montserrat fw-500 fs-12 pt-1"
                      style={{ color: "#000000B2" }}
                    >
                      <img
                        src={`/images/${venueSportsData(show?.data?.sport)}`}
                        alt=""
                        className="me-1"
                        style={{ opacity: "0.45" }}
                      />
                      {show?.data?.sport}
                    </div>
                    <div
                      className="Montserrat fw-500 fs-12"
                      style={{ color: "#000000B2" }}
                    >
                      ({show?.data?.surface})
                    </div>
                  </div>
                  <div className="w-50 text-end Montserrat align-items-end justify-content-end d-flex flex-column">
                    {/* {show?.data?.isOfflineshow?.data ? (
                            <div className="fw-semibold">
                              <label>Total</label> -{" "}
                              <span>₹{show?.data?.toPayAmount}</span>
                            </div>
                          ) : ( */}
                    {show?.data?.ispackagebooking ? (
                      <>
                        <div className="fs-14 cl-000000B2">
                          <b>{show?.data?.packageName}</b>
                        </div>
                        <div className="fs-14 cl-000000B2">
                          <label>Remainig Hours</label> -{" "}
                          <span>{show?.data?.packageremainingHours}hrs</span>
                        </div>
                        <div className="fs-14">
                          <label>Total Hours</label> -{" "}
                          <span>{show?.data?.packagetotalHours}hrs</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="fs-14 cl-000000B2">
                          <label>Remaining</label> -{" "}
                          <span>₹{show?.data?.toPayAmount}</span>
                        </div>
                        {show?.data?.offlineDiscountAmount > 0 && (
                          <div className="fs-14">
                            <label>Discount</label> -{" "}
                            <span>₹{show?.data?.offlineDiscountAmount}</span>
                          </div>
                        )}
                        <div className="fs-14 cl-008A45">
                          <label>Paid</label> -{" "}
                          <span>₹{show?.data?.amount}</span>
                        </div>
                        <div className="fw-semibold">
                          <label>Total</label> -{" "}
                          <span>
                            ₹
                            {show?.data?.amount +
                              show?.data?.toPayAmount +
                              show?.data?.offlineDiscountAmount}
                          </span>
                        </div>
                      </>
                    )}

                    {/* )} */}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
