import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import {
  VenueDate,
  convertDecimalToTime,
  formatDateToInput,
} from "../Utils/common";
// import { result } from "lodash";
import { convertTo12HourFormat } from "../Utils/common";
import Swal from "sweetalert2";
import VenueOwnerOfflineBooking from "../Popup/VenueOwnerOfflineBooking";

export default function VenueBookingDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [bookedVenueDetails, setBookedVenueDetails] = useState();
  const [showoffline, setShowoffline] = useState({
    popup: false,
    data: "",
    title: "",
    date: "",
  });

  // function VenueDate(dateString) {
  //   const daysOfWeek = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //   ];
  //   const originalDate = new Date(dateString);
  //   const dayOfWeekIndex = originalDate.getDay();
  //   const formattedDate = `${originalDate.getDate()}/${
  //     originalDate.getMonth() + 1
  //   }/${originalDate.getFullYear() % 100}`;
  //   const dayOfWeek = daysOfWeek[dayOfWeekIndex];
  //   return { formattedDate, dayOfWeek };
  // }

  //   for (let i = 0; i < bookedVenueDetails.startTime.length; i++) {
  //     const startTimes = bookedVenueDetails.startTime[i];
  //     const endTimes = bookedVenueDetails.endTime[i];
  //     console.log(`Start Time: ${startTimes}, End Time: ${endTimes}`);
  // }
  const cancelBooking = (bookingId) => {
    Swal.fire({
      icon: "warning",
      title: "Are You Sure?",
      text: location?.state?.packageBooking
        ? "Your Booking Will Be Cancelled!"
        : "Your Booking Will Be Cancelled!",
      showConfirmButton: true,
      confirmButtonText: "Confirm",
      // You can add logic to navigate to login page on login button click
    }).then((result) => {
      if (result.isConfirmed) {
        CustomPostAPI(`/payment/OfflineCancelbooking/${bookingId}/false`).then(
          (result) => {
            if (result) {
              Swal.fire({
                icon: "success",
                title: "Booking Cancelled",
                text: location?.state?.packageBooking
                  ? ""
                  : "Refund Process Started It Will Take 5-7 Bussiness Days!",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                // You can add logic to navigate to login page on login button click
              }).then((res) => {
                navigate("/venue-booking-details");
              });
            } else {
              alert("Something went worng");
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    CustomGetAPI(
      "/Venue/GetBookedVenueDetails/" +
        location.state.id +
        "/" +
        location.state.packageBooking
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        // console.log("setBookedVenueDetails", result);
        setBookedVenueDetails(result?.venueDetails);
      }
    });
  }, [location.state.id]);

  return (
    <>
      <div className="Container mb-3">
        <div className="row mt-2">
          <div className="col-12 text-center position-relative">
            <img
              className="venuePlaceArrow img-fluid position-absolute"
              src="/images/backArrow.svg"
              onClick={() => navigate("/venue-booking-details")}
            />
            <div className="venuePlaceName">Venue Bookings</div>
          </div>
        </div>

        <div className="venueCard" style={{ background: "#FEFEFE" }}>
          <div className="row mt-5">
            <div className="col-4 display-center">
              <img
                className="img-fluid"
                src={bookedVenueDetails?.venueImage}
                alt=""
              />
            </div>
            <div className="col-8">
              <span className="venueName" style={{ fontWeight: "600" }}>
                {bookedVenueDetails?.venueName}
              </span>
              <div className="row">
                <span className="venueText" style={{ textAlign: "start" }}>
                  {bookedVenueDetails?.venueAddress}
                </span>
              </div>
            </div>
          </div>
          <div className="dotted-line"></div>
          <div className="row mt-3">
            <span className="bookingText">
              Date:
              <span className="bookingText colorCommon">
                &nbsp;{VenueDate(bookedVenueDetails?.date).formattedDate}
              </span>
            </span>
          </div>
          <div className="row mt-2">
            <span className="bookingText">
              Time :{" "}
              <span className="bookingText">
                {convertTo12HourFormat(bookedVenueDetails?.startTime)}
              </span>
            </span>
          </div>
          <div className="row mt-2">
            <span className="bookingText">
              Duration :{" "}
              <span className="bookingText">
                {convertDecimalToTime(bookedVenueDetails?.slotDuration)}
              </span>
            </span>
          </div>

          {/* <div className="row mt-2">
            <span className="bookingText">
              Day:{" "}
              <span className="colorCommon">
                &nbsp;&nbsp;{VenueDate(bookedVenueDetails?.date).dayOfWeek}
              </span>
            </span>
          </div> */}

          <div className="row mt-2">
            <span className="bookingText">
              {bookedVenueDetails?.category === "Sports"
                ? "Sport"
                : "Event Space Type"}{" "}
              :{" "}
              <span className="colorCommon">
                &nbsp;{bookedVenueDetails?.sportName}
              </span>
            </span>
          </div>
          <div className="row mt-2">
            <span className="bookingText">
              {bookedVenueDetails?.category === "Sports"
                ? "Court/Surface"
                : "Space"}
              :{" "}
              <span className="colorCommon">
                &nbsp;{bookedVenueDetails?.surfaceName}
              </span>
            </span>
          </div>

          {bookedVenueDetails?.sportName === "Go-Karting" && (
            <>
              <div className="row mt-2">
                <span className="bookingText">
                  Duration: {bookedVenueDetails?.duration} Min
                </span>
              </div>
              <div className="row mt-2">
                <span className="bookingText">
                  No. of Cart: {bookedVenueDetails?.noOfPeople}
                </span>
              </div>
            </>
          )}

          <div className="dotted-line"></div>
          {location.state.packageBooking ? (
            <>
              <div className="d-flex justify-content-between">
                <span
                  className="float-right venueName mt-3"
                  style={{ fontWeight: "600", float: "right" }}
                >
                  This booking was made using
                  <span className="colorClass fw-bold">
                    &nbsp;{bookedVenueDetails?.packageName}
                  </span>
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span
                  className="venueName mt-3"
                  style={{ fontWeight: "600", float: "left" }}
                >
                  Total Duration:{" "}
                  <span
                    className=" fw-bold"
                    style={{ color: "rgb(76, 175, 80)" }}
                  >
                    &nbsp;{bookedVenueDetails?.packagetotalHours}hrs
                  </span>
                </span>
                <span
                  className="float-right venueName mt-3"
                  style={{ fontWeight: "600", float: "right" }}
                >
                  Remaining Duration:{" "}
                  <span className="colorClass fw-bold">
                    &nbsp;{bookedVenueDetails?.packageremainingHours}hrs
                  </span>
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <span
                  className="venueName mt-3"
                  style={{ fontWeight: "600", float: "left" }}
                >
                  Paid:{" "}
                  <span
                    className=" fw-bold"
                    style={{ color: "rgb(76, 175, 80)" }}
                  >
                    &nbsp;₹{bookedVenueDetails?.amount}
                  </span>
                </span>
                {bookedVenueDetails?.toPayAmount && (
                  <span
                    className="float-right venueName mt-3"
                    style={{ fontWeight: "600", float: "right" }}
                  >
                    Remaining:{" "}
                    <span className="colorClass fw-bold">
                      &nbsp;₹{bookedVenueDetails?.toPayAmount}
                    </span>
                  </span>
                )}
              </div>
              <div className="display-center">
                <span className="venueName mt-3" style={{ fontWeight: "600" }}>
                  Total Amount:{" "}
                  <span className="colorClass fw-bold">
                    &nbsp;₹
                    {bookedVenueDetails?.toPayAmount +
                      bookedVenueDetails?.amount +
                      bookedVenueDetails?.discount}
                  </span>
                </span>
              </div>
              {bookedVenueDetails?.discount != 0 && (
                <div>
                  <span
                    className="display-center venueName mt-3"
                    style={{ fontWeight: "600", color: "#4CAF50" }}
                  >
                    Instant Discount:{" "}
                    <span
                      className="colorClass fw-bold"
                      style={{ color: "#4CAF50" }}
                    >
                      &nbsp;₹{bookedVenueDetails?.discount}
                    </span>
                  </span>
                </div>
              )}
            </>
          )}

          <div className="row d-flex align-content-center justify-content-center">
            {bookedVenueDetails?.showbutton ? (
              <div className="col-6">
                <button
                  className="btn btn-danger mt-3 px-0"
                  onClick={(e) =>
                    cancelBooking(bookedVenueDetails?.venuebookingId)
                  }
                >
                  Cancel Booking
                </button>
              </div>
            ) : (
              <></>
            )}

            {bookedVenueDetails?.rescheduleButton ? (
              <div className="col-6">
                <button
                  className="btn btn-success mt-3 px-0"
                  onClick={(e) =>
                    setShowoffline({
                      date: formatDateToInput(bookedVenueDetails?.date),
                      popup: true,
                      data: "",
                      title: "Reschedule Booking",
                    })
                  }
                >
                  Reschedule Booking
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {showoffline?.popup && (
        <VenueOwnerOfflineBooking
          showoffline={showoffline}
          isPackageBooking={location?.state?.packageBooking}
          packageId={bookedVenueDetails?.packageId}
          setShowoffline={setShowoffline}
          VenueId={bookedVenueDetails?.venueId}
          isReschedule="true"
          totalAmount={
            bookedVenueDetails?.amount +
            bookedVenueDetails?.toPayAmount +
            bookedVenueDetails?.discount
          }
          bookedVenueDetails={bookedVenueDetails}
        />
      )}
    </>
  );
}
