import React, { Fragment, useState } from "react";
import { convertTo12HourFormat, VenueDate } from "../Utils/common";
import Popup from "../LiveScoreCardpopups/Popup";
import PackageHistoryCard from "./PackageHistoryCard";
import Loader from "../Utils/Loader";
import { CustomGetAPI } from "../Utils/APIConnector";

export default function OwnerListingCard({
  packageData,
  payNow,
  setPackageData,
  setShow,
  setSelectedIndex,
  setSelectedPackageId,
  setSelectedPackageDuration,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState("Test");
  const [BookingHistory, setBookingHistory] = useState([]);
  const getBookingHistory = async (bookingId, packageId) => {
    await CustomGetAPI(
      `/packages/GetPackageBookingByUserID/${bookingId}/${packageId}`
    ).then((result) => {
      if (result) {
        result = JSON.parse(result);
        if (result?.status == "Success") {
          setBookingHistory(result?.bookings);
          setLoader(false);
        }
      }
    });
  };
  return (
    <Fragment>
      {packageData?.map((item, index) => (
        <div className="col-md-12 mb-3">
          <div className="row mx-0 packagesDiv">
            <div className="col-7 fw-bold mb-2">
              {item.venuename}
              <span className="fw-normal"> ({item.name})</span>
            </div>
            <div className="col-5 text-end">
              <button
                className="venueListingBooknow"
                onClick={() => {
                  setPackageData((prev) =>
                    prev.map((pkg, i) =>
                      i === index
                        ? { ...pkg, flag: !pkg.flag }
                        : { ...pkg, flag: false }
                    )
                  );
                }}
              >
                View Details
              </button>
            </div>
            <div className="col-6 ">
              <p className="mb-0">Total Purchased</p>
              <b>{item.totalPurchased}</b>
            </div>
            <div className="col-6 text-end">
              <p className="mb-0">Total Earning</p>
              <b>₹{item?.totalearnings}</b>
            </div>
            <div className="col-6 ">
              <p className="mb-0">Total Booking</p>
              <b>{item.noofBooking}</b>
            </div>
            <div className="col-6 text-end">
              <p className="mb-0">Total Duration</p>
              <b>{item?.totalDuration}hrs</b>
            </div>

            {item?.flag && (
              <div
                className="bg-white mt-1 p-2 px-3 borderRadius-6"
                style={{ maxHeight: "200px", overflow: "auto" }}
              >
                <div className="col-12 fw-bold font-13 text-center">
                  User Details
                </div>
                <div className="col-12 ">
                  <div className="row mx-0">
                    {item?.venueBooking?.map((items, idx) => (
                      <div
                        key={idx}
                        className="mt-2 weekdayPriceText Montserrat py-2"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "4px",
                          border: "0.5px solid #C4C4C4",
                        }}
                      >
                        <div className="fw-semibold d-flex justify-content-between align-items-md-center">
                          <div>
                            <b>
                              {" "}
                              {items?.bookByNavigation?.firstName}{" "}
                              {items?.bookByNavigation?.lastName}
                            </b>
                          </div>
                          <div>
                            <label
                              style={{
                                opacity: 0.5,
                              }}
                            >
                              Duration:
                            </label>{" "}
                            {items?.slotDuration}hrs
                          </div>
                        </div>
                        <div className="fw-semibold d-flex justify-content-between align-items-md-center mt-1">
                          <div>
                            <label
                              style={{
                                opacity: 0.5,
                              }}
                            >
                              Phone:
                            </label>{" "}
                            <a
                              href={`tel:${items?.bookByNavigation?.phoneNumber}`}
                            >
                              {items?.bookByNavigation?.phoneNumber}
                            </a>
                          </div>
                          <div className="itemPrice ">
                            <b
                              className="text-decoration-underline cursor-pointer"
                              style={{ fontSize: "12px" }}
                              onClick={() => {
                                setShowHistory(true);
                                setSelectedVenue(item.name);
                                setLoader(true);
                                getBookingHistory(items?.bookBy, item?.id);
                              }}
                            >
                              Booking History
                            </b>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <Popup
        show={showHistory}
        venueflag={1}
        onHide={() => setShowHistory(false)}
      >
        <div className="Container mt-2 ">
          {isLoader ? (
            <>
              <Loader mt="10px" />
            </>
          ) : (
            <PackageHistoryCard
              BookingHistory={BookingHistory}
              venueName={selectedVenue}
            />
          )}
        </div>
      </Popup>
    </Fragment>
  );
}
