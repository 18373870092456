import {
  React,
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  CustomGetAPI,
  GetAPI,
  PostAPI,
  CustomPostAPI,
} from "../Utils/APIConnector";
import {
  CheckValidation,
  VenueDate,
  calculatePrice,
  checkTime,
  convertDecimalToTime,
  convertTo12HourFormat,
  convertToISOFormat,
  formatDate,
  getPriceForTime,
  isWeekend,
  minToHour,
  modifyTime,
  timeAddChange,
  timeSubtractChange,
} from "../Utils/common";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";
import { Card } from "react-bootstrap";
import Loader from "../Utils/Loader";
import VerifyPhoneNumber from "../VerifyPhoneNumber";
// import {Tooltip } from 'flowbite-react';
// import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { OverlayTrigger, Popover } from "react-bootstrap";
import Swal from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import Cookies from "universal-cookie";
import { duration } from "moment";
import AddUserName from "../Popup/AddUserName";

export default function NewVenueBooking({
  venueData,
  isLoading,
  setIsLoading,
  setShow,
  couponsData,
  courtData,
  isPackageBooking,
  selectedPackageId,
  selectedUserPackageId,
  selectedPackageDuration,
  isNearBy,
}) {
  const cookies = new Cookies();
  const Isapp = cookies.get("Isapp");
  const nearbyobj = cookies.get("nearByObj");
  const RequestTime = process.env.REACT_APP_REQUEST_TIME;
  const [hours, setHours] = useState(0);
  const [paymentType, setPaymentType] = useState("Full");
  const username = window.localStorage.getItem("UserName");
  const phoneno = window.localStorage.getItem("phoneno");
  const UserEmail = window.localStorage.getItem("UserEmail");
  const [surfacetype, setsurfacetype] = useState(null);
  const [selectedSurfaceIndex, setSelectedSurfaceIndex] = useState(0);
  const [couponName, setCouponName] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponData, setCouponData] = useState(
    couponsData[0] ? couponsData[0] : null
  );

  const [showSuccess, setShowSuccess] = useState(false);

  const [Duration, setDuration] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [timeDuration, setTimeDuration] = useState(0);
  const [cartCount, setCartCount] = useState(1);
  const [selectedCartCount, setSelectedCartCount] = useState(1);
  const [minutes, setMinutes] = useState(0);
  const [venueSlots, setVenueSlots] = useState([]);
  const params = useParams();
  const navigateToVenuePlace = useNavigate();
  const [surfaceData, setSurfaceData] = useState([]);
  const [showVenueBookingContent, setShowVenueBookingContent] = useState(1);

  const getPreciseDates = (date) => {
    const getday = new Date(date);
    let day = getday.getDay() === 0 ? 7 : getday.getDay();
    let count = 1;
    let PreciseDay;
    if (
      selectedTimeSlots[0]?.startTime.slice(-8) === "23:30:00" &&
      selectedTimeSlots[selectedTimeSlots?.length - 1]?.endTime.slice(-8) >
        "00:00:00"
    ) {
      PreciseDay = day + count;
    } else if (
      selectedTimeSlots[0]?.startTime.slice(-8) === "23:30:00" &&
      selectedTimeSlots[selectedTimeSlots?.length - 1]?.endTime.slice(-8) >
        "00:00:00" &&
      slotDuration === 24.5
    ) {
      PreciseDay = day + count + 1;
    } else if (
      selectedTimeSlots[0]?.startTime.slice(-8) === "23:30:00" &&
      selectedTimeSlots[selectedTimeSlots?.length - 1]?.endTime.slice(-8) >
        "00:00:00" &&
      slotDuration === 24.5
    ) {
      PreciseDay = day + count;
    } else {
      PreciseDay = day;
    }
    return PreciseDay;
  };

  const initialFilterObjects = JSON.parse(
    localStorage.getItem("filterObjects")
  ) || {
    name: "",
    sport: "0",
    timings: [],
    amenities: "0",
    date: new Date().toISOString().slice(0, 10),
    sortby: "0",
  };
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  // const [date, setDate] = useState(
  //   initialFilterObjects?.date
  //     ? initialFilterObjects?.date
  //     : new Date().toISOString().slice(0, 10)
  // );
  const [weekPrice, setWeekPrice] = useState([]);
  const [isEvent, setIsEvent] = useState(false);
  const [availablePackageData, setAvailablePackageData] = useState([]);
  const [usePackageData, setUsePackageData] = useState(false);

  const [slotDuration, setSlotDuration] = useState(0.5);
  const [nextAvailableSlots, setNextAvailableSlots] = useState(0);
  const [sportData, setSportData] = useState([]);
  const [venuePrice, setVenuePrice] = useState(0);
  const [venueremainingPrice, setVenueRemainingPrice] = useState(0);
  const [showPayment, setShowPayment] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [showPage, setShowPage] = useState({
    showLogin: false,
    userNameShow: false,
  });

  const navigate = useNavigate();
  const userID = localStorage.getItem("userid");
  const [showPopover, setShowPopover] = useState(false);
  const [walletBalance, setwalletBalance] = useState();
  const [redeemBalance, setredeemBalance] = useState(false);
  const overlayRef = useRef(null);
  useEffect(() => {
    const errordiv = document.getElementsByClassName("minSlotTimeError")[0];
    errordiv.classList.add("d-none");
  }, [nextAvailableSlots]);

  const handleslotadditionchange = (e) => {
    if (isPackageBooking && selectedPackageDuration < slotDuration + 0.5) {
      const errordiv = document.getElementsByClassName("minSlotTimeError")[0];
      errordiv.classList.remove("d-none");
      errordiv.textContent = `Duration cannot be greater than ${selectedPackageDuration} hours`;
      return;
    }
    if (nextAvailableSlots != 0) {
      setNextAvailableSlots((count) => count - 1);
      setSlotDuration((prevDuration) => prevDuration + 0.5);
      const nextslot = venueSlots.filter(
        (venueslot) =>
          venueslot.startTime >
          selectedTimeSlots[selectedTimeSlots.length - 1].startTime
      )[0];
      setSelectedTimeSlots((prevSelected) => [...prevSelected, nextslot]);
    } else {
      if (slotDuration < minToHour(venueData?.minSlotTime)) {
        const errordiv = document.getElementsByClassName("minSlotTimeError")[0];
        errordiv.classList.remove("d-none");
        errordiv.textContent = "Next slot not available";
        return;
      }
    }
  };
  useEffect(() => {
    setCouponName(couponsData[0]?.code);
    if (couponsData[0]?.code) {
      applyCoupon(couponsData[0]?.code, venueData?.id);
    }
  }, [couponsData]);

  useEffect(() => {
    handleDateChange(date);
  }, [isEvent]);
  useEffect(() => {
    if (selectedTimeSlots.length !== 0 && couponApplied) {
      setShowSuccess(true);
    }
  }, [selectedTimeSlots.length]);
  const applyCoupon = (name, id) => {
    if (usePackageData) {
      Swal.fire({
        toast: true,
        icon: "warning",
        title: `Coupon can't be applied on package booking!`,
        timer: 3000,
        showConfirmButton: false,
        position: "top",
      });
      return;
    }
    if (name ? name : couponName) {
      CustomGetAPI(
        `/venue/Checkcode/${name ? name : couponName}/${userID}/${
          Isapp ? Isapp : false
        }/${id ? id : 0}`
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result.status === "success") {
            setCouponApplied(true);
            setCouponData(result.data);
            const couponDiv = document.getElementsByClassName("couponError");
            const couponDivSuccess =
              document.getElementsByClassName("couponSuccess");
            couponDiv[0]?.classList?.add("d-none");
            couponDivSuccess[0]?.classList?.remove("d-none");
            if (selectedTimeSlots?.length > 0) {
              setShowSuccess(true);
            }
          } else if (result.status === "error") {
            setCouponApplied(false);
            const couponDiv = document.getElementsByClassName("couponError");
            couponDiv[0].classList.add("text-danger");
            couponDiv[0].classList.remove("d-none");
            couponDiv[0].textContent = result.message;
          } else {
            setCouponApplied(false);
            const couponDiv = document.getElementsByClassName("couponError");
            couponDiv[0].classList.add("text-danger");
            couponDiv[0].classList.remove("d-none");
            couponDiv[0].textContent = "Coupon not found!";
          }
        }
      });
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef]);

  const cardRef = useRef(null);

  const handlePrintCard = useReactToPrint({
    content: () => cardRef.current,
  });

  const [showTimeList, setShowTimeList] = useState({
    value: "",
    show: false,
  });
  const [SurfaceSportID, setSurfaceSportID] = useState({
    surface: { id: "", val: "" },
    sport: {
      id: "",
      val: "",
    },
  });

  useEffect(() => {
    if (venueData?.venueSports?.length === 1 && !isNearBy) {
      setSurfaceSportID((prevState) => ({
        ...prevState,
        sport: {
          sport: {
            ...prevState.sport,
            id: venueData?.venueSports[0]?.id,
            val: venueData?.venueSports[0]?.name,
          },
        },
      }));
      sportsHandle(
        venueData?.venueSports[0]?.id,
        venueData?.venueSports[0]?.name
      );
    }
    // else if (surfaceSportData?.label) {
    //   setSurfaceSportID((prevState) => ({
    //     ...prevState,
    //     sport: {
    //       ...prevState.sport,
    //       id: surfaceSportData?.id ? surfaceSportData?.id : "",
    //       val: surfaceSportData?.label ? surfaceSportData?.label : "",
    //     },
    //   }));
    //   sportsHandle(surfaceSportData?.id);
    // }
  }, []);

  const [paymentStatus, setPaymentStatus] = useState({
    OrderID: "",
    PaymentID: "",
    Status: "",
    loader: false,
  });

  const popover = (
    <Popover
      id="popover-basic"
      style={{ maxWidth: "50%", fontSize: "14px" }}
      onMouseLeave={() => setShowPopover(false)}
    >
      <Popover.Header as="h3">
        You can select multiple time slots
      </Popover.Header>
    </Popover>
  );
  const handleToggle = () => setShowPopover(!showPopover);

  const TimeRef = useRef(null);
  const [MinSlotTime, setMinSlotTime] = useState(venueData?.minSlotTime);
  const [MinSlot, setMinSlot] = useState();

  useEffect(() => {
    encryptId();
  }, [userID]);
  const encryptId = async () => {
    await CustomGetAPI(`/User/GetEncryptedvalue/${userID}`).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);

        setwalletBalance(result?.walletBalance);
      }
    });
  };

  useEffect(() => {
    // Calculate and set the total price for the selected time slots

    const totalPrice = selectedTimeSlots.reduce(
      (acc, selectedSlot) => acc + selectedSlot.price,
      0
    );

    const totalDuration = selectedTimeSlots.length * MinSlotTime;
    var hours = Math.floor(totalDuration / 60);
    var remainingMinutes = totalDuration % 60;
    setHours(hours);
    setMinutes(remainingMinutes);
    const finalPrice = selectedCartCount
      ? totalPrice * selectedCartCount
      : totalPrice;
    let updatedFinalPrice = finalPrice;
    if (updatedFinalPrice === 0 && venueSlots.length > 0) {
      updatedFinalPrice = venueSlots[0]?.price;
    }
    let initAmount = 0;
    if (couponData) {
      initAmount = updatedFinalPrice * (couponData?.offPercentage / 100);
      if (initAmount < couponData?.amountLimit) {
        setDiscountAmount(initAmount);
      } else {
        initAmount = couponData?.amountLimit;
        setDiscountAmount(couponData?.amountLimit);
      }
    }

    updatedFinalPrice *= venueData?.bookingAmount
      ? venueData?.bookingAmount / 100
      : 0.2;

    let price = updatedFinalPrice.toFixed(2) - (initAmount ? initAmount : 0);
    let topay = finalPrice - updatedFinalPrice.toFixed(2);
    if (price <= 0 && initAmount > 0 && couponData?.venueId) {
      price = updatedFinalPrice.toFixed(2);
      topay =
        finalPrice -
        updatedFinalPrice.toFixed(2) -
        (initAmount ? initAmount : 0);
    }
    if (redeemBalance && price >= 50) {
      price = price - 50;
    }
    setVenuePrice(price);

    setVenueRemainingPrice(topay);
  }, [
    selectedTimeSlots,
    selectedCartCount,
    venueSlots,
    couponData,
    redeemBalance,
  ]);

  const isLoggedIn = window.localStorage.getItem("UserMobileNo");
  const UserID = parseInt(window.localStorage.getItem("userid"));
  const userType = window.localStorage.getItem("UserType");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const payNow = async (bookingid) => {
    var options;

    const data = JSON.stringify({
      Isfromvenuepayment: true,
      // venueBookings: selectedTimeSlots,
      venueBookings: selectedTimeSlots?.map((slot) => ({
        date: slot.startTime.split("T")[0],
        startTime: slot.startTime.split("T")[1].substring(0, 5),
        endTime: slot.endTime.split("T")[1].substring(0, 5),
        SlotDuration: slotDuration,
      })),
      VenueId: venueData?.id,
      SurfaceId: SurfaceSportID?.surface?.id,
      Date: date,
      Amount: venuePrice,
      UserId: UserID,
      SportId: SurfaceSportID?.sport?.id,
      convertedId: bookingid,
      Duration: timeDuration ? timeDuration : 0,
      NoOfPeople: selectedCartCount ? selectedCartCount : 0,
      IsfromPartialPayment: true,
      CouponId: couponData?.id,
      Discountamount: redeemBalance ? 50 : discountAmount,
      isreferal: redeemBalance,
      PackagePrideId: usePackageData
        ? availablePackageData[0]?.id
        : selectedPackageId,
      Userpackageid: usePackageData
        ? availablePackageData[0]?.packageId
        : selectedUserPackageId,
    });
    await CustomPostAPI(
      `${
        isPackageBooking || usePackageData
          ? `/packages/BookPackageSlot`
          : `/payment/initialize`
      }`,
      data
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        // setIsLoading(true)
        if (result.status === "Success") {
          setShowPayment(false);
          setShow(false);
          if (isPackageBooking || usePackageData) {
            Swal.fire({
              icon: "success",
              text: "Your venue booking is confirmed successfully, you will receive notification on your WhatsApp as well as you can check your booking in your account.",
              showConfirmButton: true,
              allowOutsideClick: true,
              confirmButtonText: "View Bookings",
            }).then((result) => {
              if (result.isConfirmed) {
                window.localStorage.setItem(
                  "RedirectURL",
                  window.location.href
                );
                navigate("/venue-booking-details");
              }
            });
            return;
          }
          const order_id = result.orderResponse.orderId;
          const venuepaymentlogid = result.venuepaymentlogid;
          const callback_url =
              "https://cricbuddydevapi.cricbuddy.in/api/payment/Razorpaycallback",
            options = {
              // key: Isapp ? "" : result.orderResponse.razorPayAPIKey,
              amount: result.orderResponse.amount,
              name: "Venue Booking Fee",
              description: "Total_venue_booking_fee",
              image: "https://dev.cricbuddy.in/images/cricketFlash.svg",
              order_id: order_id,
              handler: async (data) => {
                try {
                  window.scrollTo(0, 0);
                  setPaymentStatus((prevState) => ({
                    ...prevState,
                    OrderID: data.razorpay_order_id,
                    PaymentID: data.razorpay_payment_id,
                    loader: true,
                  }));
                  setIsLoading(true);
                  data["Isfromvenuepayment"] = true;
                  data["venuepaymentlogid"] = venuepaymentlogid;
                  data["venueBookings"] = selectedTimeSlots?.map((slot) => ({
                    date: slot.startTime.split("T")[0],
                    startTime: slot.startTime.split("T")[1].substring(0, 5),
                    endTime: slot.endTime.split("T")[1].substring(0, 5),
                  }));
                  data["SurfaceId"] = SurfaceSportID?.surface?.id;
                  data["SportId"] = SurfaceSportID?.sport?.id;
                  data["VenueId"] = venueData?.id;
                  data["Date"] = date;
                  data["BookBy"] = userID;
                  data["convertedId"] = bookingid ? bookingid : "";

                  var paymentData = JSON.stringify(data);
                  const result = await CustomPostAPI(
                    "/payment/confirm",
                    paymentData
                  );

                  if (result === undefined || result === "") {
                    alert("Something went wrong with the payment.");
                  } else {
                    const parsedResult = JSON.parse(result);
                    if (parsedResult?.status === "Error") {
                      Swal.fire({
                        icon: "warning",
                        text: "Selected time slot for Surface and sport is already booked your amount will be refunded in 5-6 business days.",
                        showConfirmButton: true,
                        allowOutsideClick: true,
                        confirmButtonText: "View Bookings",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.localStorage.setItem(
                            "RedirectURL",
                            window.location.href
                          );
                          navigate("/venue-booking-details");
                        }
                      });
                    } else {
                      Swal.fire({
                        icon: "success",
                        text: "Your venue booking is confirmed successfully, you will receive notification on your WhatsApp as well as you can check your booking in your account.",
                        showConfirmButton: true,
                        allowOutsideClick: true,
                        confirmButtonText: "View Bookings",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.localStorage.setItem(
                            "RedirectURL",
                            window.location.href
                          );
                          navigate("/venue-booking-details");
                        }
                      });
                    }
                    setPaymentStatus((prevState) => ({
                      ...prevState,
                      Status: parsedResult.paymentStatus,
                      loader: false,
                    }));
                    setIsLoading(false);

                    if (bookingid) {
                      setIsLoading(false);
                    }
                    // Handle other success actions if needed
                  }

                  document.body.style.overflow = "auto";
                } catch (error) {
                  // Handle payment failure
                  console.error("Payment failed:", error);
                  CustomGetAPI(
                    `/payment/AddPaymenterror/${error ?? ""}/${userID}`
                  );
                }
              },
              prefill: {
                name: username,
                email: UserEmail ? UserEmail : "",
                contact: phoneno,
              },
              theme: {
                color: "#F3F3F3",
              },
              config: {
                display: {
                  hide: [
                    { method: "paylater" },
                    { method: "netbanking" },
                    { method: "wallet" },
                  ],
                },
              },
              webview_intent: true,

              // redirect: true,
              // callback_url: callback_url,
              timeout: 300,
              modal: {
                ondismiss: function () {
                  document.body.style.overflow = "auto";
                },
              },
            };
          if (!Isapp) {
            options.key = result.orderResponse.razorPayAPIKey;
          }
          if (Isapp) {
            options.amount = options.amount * 100;
            // options.redirect = true;
            // options.callback_url = callback_url;
            const encodedPaymentData = encodeURIComponent(
              JSON.stringify(options)
            );
            window.location.href = `/mobile-booking?paymentData=${encodedPaymentData}`;
          } else {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.async = true;
            document.body.appendChild(script);
            const paymentObject = new window.Razorpay(options);
            paymentObject.on("payment.failed", function (response) {
              console.log("error", response);
            });
            paymentObject.open();
          }
        } else {
          var isBooked = document.querySelector(".bookederror");
          isBooked.classList.remove("d-none");
          isBooked.textContent = `${result.message}`;
        }
      }
    });
  };
  const handlePayment = async () => {
    if (slotDuration < minToHour(venueData?.minSlotTime)) {
      const errordiv = document.getElementsByClassName("minSlotTimeError")[0];
      errordiv.classList.remove("d-none");
      errordiv.textContent = `Minimum ${convertDecimalToTime(
        minToHour(venueData?.minSlotTime)
      )} booking is required`;
      return;
    } else {
      const errordiv = document.getElementsByClassName("minSlotTimeError")[0];
      errordiv.classList.add("d-none");
    }
    var isFormValid = CheckValidation({ formID: "payPopup" });
    if (selectedTimeSlots.length > 0) {
      const validTimeSlot = document.querySelector(".selectedtimeslots");

      validTimeSlot.classList.add("d-none");
    } else {
      const validTimeSlot = document.querySelector(".selectedtimeslots");

      validTimeSlot.classList.remove("d-none");
    }

    if (isFormValid && selectedTimeSlots?.length > 0) {
      if (isLoggedIn === null || isLoggedIn === "") {
        setShowPage({ showLogin: true, userNameShow: false });
        setShowVenueBookingContent(2);
      } else if (!username.trim() || username === "Guest") {
        setShowPage({ showLogin: false, userNameShow: true });
      } else {
        if (!isPackageBooking && !usePackageData) {
          setShowPage({ showLogin: false, userNameShow: false });
          setShowVenueBookingContent(2);
        } else {
          payNow();
        }
      }
    }
  };
  const handlerequest = async () => {
    // setIsLoading(true);
    if (slotDuration < minToHour(venueData?.minSlotTime)) {
      const errordiv = document.getElementsByClassName("minSlotTimeError")[0];
      errordiv.classList.remove("d-none");
      errordiv.textContent = `Minimum ${convertDecimalToTime(
        minToHour(venueData?.minSlotTime)
      )} booking is required`;
      return;
    } else {
      const errordiv = document.getElementsByClassName("minSlotTimeError")[0];
      errordiv.classList.add("d-none");
    }
    const isLoggedIn = window.localStorage.getItem("loggedin");
    const UserID = parseInt(window.localStorage.getItem("userid"));

    var isFormValid = CheckValidation({ formID: "payPopup" });
    if (selectedTimeSlots.length > 0) {
      const validTimeSlot = document.querySelector(".selectedtimeslots");

      validTimeSlot.classList.add("d-none");
    } else {
      const validTimeSlot = document.querySelector(".selectedtimeslots");

      validTimeSlot.classList.remove("d-none");
    }

    if (isFormValid && selectedTimeSlots?.length > 0) {
      if (isLoggedIn === null || isLoggedIn === "") {
        setShowPage({ showLogin: true, userNameShow: false });
        // setShowVenueBookingContent(2);
      } else if (!username.trim() || username === "Guest") {
        setShowPage({ showLogin: false, userNameShow: true });
      } else {
        // Swal.fire({
        //   icon: "success",
        //   title: "Please wait!",
        //   text: "Your booking request has been submitted.",
        //   showConfirmButton: false,
        //   allowOutsideClick: false,
        // });
        setLoader(true);
        const data = JSON.stringify({
          Isfromvenuepayment: true,
          // venueBookings: selectedTimeSlots,
          venueBookings: selectedTimeSlots?.map((slot) => ({
            date: slot.startTime.split("T")[0],
            startTime: slot.startTime.split("T")[1].substring(0, 5),
            endTime: slot.endTime.split("T")[1].substring(0, 5),
            SlotDuration: slotDuration,
          })),
          VenueId: venueData?.id,
          SurfaceId: SurfaceSportID?.surface?.id,
          Date: date,
          Amount: venuePrice,
          BookBy: UserID,
          SportId: SurfaceSportID?.sport?.id,
          packageid: usePackageData
            ? availablePackageData[0]?.id
            : selectedPackageId
            ? selectedPackageId
            : 0,
          Userpackageid: usePackageData
            ? availablePackageData[0]?.packageId
            : selectedUserPackageId,
        });
        CustomPostAPI("/payment/Bookingrequest", data).then((result) => {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            result = JSON.parse(result);
            if (result.status === "Success") {
              setShowVenueBookingContent(3);
              setShow(false);
              setShowPage({ showLogin: false, userNameShow: false });

              let timerInterval;
              setPaymentStatus((prevState) => ({
                ...prevState,
                loader: false,
              }));
              Swal.fire({
                title: "Booking request sent successfully!",
                html: "It may take upto <b></b> minutes.<br/><br/><p class='text-danger'>*Do not navigate away from this page or close your browser until the request is complete.</p>",
                timer: RequestTime * 60 * 1000,
                timerProgressBar: true,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    const timeLeft = Swal.getTimerLeft();
                    const minutes = Math.floor(timeLeft / 60000);
                    const seconds = Math.floor((timeLeft % 60000) / 1000);
                    timer.textContent = `${minutes}:${
                      seconds < 10 ? "0" : ""
                    }${seconds}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                // if (result.dismiss === Swal.DismissReason.timer) {
                //   Swal.fire({
                //     icon: "error",
                //     title: "Please try different timeslot or venue!",
                //     text: "Your request is rejected. Please click the 'View Venues' button to explore nearby venues.",
                //     showCancelButton: true,
                //     showConfirmButton: true,
                //     confirmButtonText: "View venues",
                //     denyButtonText: "Cancel",
                //   }).then((result) => {
                //     if (result.isConfirmed) {
                //       navigate(
                //         `/nearme?location=${params?.id}&&time=${selectedTimeSlots[0]?.startTime}&&duration=${slotDuration}&&sport=${venueData?.venueSports[0]?.id}`
                //       );
                //     } else {
                //       // window.location.reload();
                //     }
                //   });
                // }
              });
              let bookingid = result.id;
              CustomGetAPI("/payment/Getstatus/" + result.id).then((result) => {
                if (result === undefined || result === "") {
                  alert("Something went wrong");
                } else {
                  result = JSON.parse(result);
                  if (result.status === "accepted") {
                    if (!isPackageBooking && !usePackageData) {
                      Swal.fire({
                        icon: "info",
                        title: "Your request has been accepted!",
                        text: "you will be redirected to payment page soon",
                        showConfirmButton: true,
                        confirmButtonText: "Pay Now",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          payNow(bookingid);
                          Swal.close();
                        }
                      });
                    } else {
                      Swal.fire({
                        icon: "success",
                        title: "Your booking is confirmed!",
                        showConfirmButton: true,
                        confirmButtonText: "View Bookings",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.localStorage.setItem(
                            "RedirectURL",
                            window.location.href
                          );
                          navigate("/venue-booking-details");
                        } else {
                          window.location.reload();
                        }
                      });
                    }

                    // Swal.close();
                  } else if (!isPackageBooking && !usePackageData) {
                    Swal.fire({
                      icon: "error",
                      title: "Please try different timeslot or venue!",
                      text: "Your request is rejected. Please click the 'View Venues' button to explore nearby venues",
                      showConfirmButton: true,
                      showCancelButton: true,
                      cancelButtonText: "Cancel",
                      confirmButtonText: "View Venues",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate(
                          `/nearme?location=${params?.id}&&time=${selectedTimeSlots[0]?.startTime}&&duration=${slotDuration}&&sport=${venueData?.venueSports[0]?.id}`
                        );
                      } else {
                        window.location.reload();
                      }
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Please try different timeslot!",
                      text: "Your request is rejected.",
                      showConfirmButton: true,
                      confirmButtonText: "Ok",
                    }).then((result) => {});
                  }
                }
              });
              // Swal.fire({
              //   icon: "success",
              //   title: "Congratulations!",
              //   text: "Your request has been sent successfully.",
              //   showConfirmButton: true,
              //   confirmButtonText: "Ok",
              // }).then((result) => {
              //   if (result.isConfirmed) {
              //     window.location.reload();
              //   }
              // });
            } else {
              Swal.fire({
                icon: "error",
                title: "Please try again later!",
                text: "Your request has failed.",
                showConfirmButton: true,
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  // window.location.reload();
                }
              });
            }
          }
        });
        // setShowPayment(true);
      }
    }
  };

  useEffect(() => {
    //set initial date and time
    const initialDate = getPreciseDates(date);

    //get selected start and end day
    const getStartday = new Date(selectedTimeSlots[0]?.startTime.slice(0, 10));
    const selectedStartDate =
      getStartday.getDay() === 0 ? 7 : getStartday.getDay();

    const getEndDay = new Date(
      selectedTimeSlots[selectedTimeSlots.length - 1]?.endTime.slice(0, 10)
    );
    const selectedEndDate = getEndDay.getDay() === 0 ? 7 : getEndDay.getDay();

    let AllDatesData = [];

    //filter available data
    const FilterOutAvailable = courtData.packageDetailsList.filter(
      (item) => item.ispackageAvailable === true
    );
    //flatten the data
    const FlattenData = FilterOutAvailable.flatMap((item) =>
      item.packagePriceData.map((data) => ({
        ...data,
        name: item.name,
      }))
    );

    // check if the selected or initial day is included in weekdays
    const IncludesInitialDate = FlattenData.filter((items) =>
      items.weekDays?.includes(initialDate > 7 ? 1 : initialDate)
    );
    const IncludesStartDate = FlattenData.filter((items) =>
      items.weekDays?.includes(selectedStartDate > 7 ? 1 : selectedStartDate)
    );
    const IncludesEndDate = FlattenData.filter((items) =>
      items.weekDays?.includes(selectedEndDate > 7 ? 1 : selectedEndDate)
    );

    //store them in a array
    AllDatesData =
      IncludesStartDate && IncludesEndDate
        ? [...IncludesStartDate, IncludesEndDate].flat()
        : [...IncludesInitialDate].flat();

    // get Precise End Time for comparison
    const SetEndTime =
      selectedTimeSlots[selectedTimeSlots?.length - 1]?.endTime;

    //filter out duplicate values
    const FilterDuplicate = AllDatesData.filter(
      (items, index) => AllDatesData.indexOf(items) === index
    );
    //get final available packages
    const getFinalAvailPackage = FilterDuplicate?.filter((toCheck) => {
      const slotDate = selectedTimeSlots[0]?.startTime.slice(0, 10);

      const slotStartDate = new Date(`${slotDate} ${toCheck?.startTime}`);
      let slotEndDate = new Date(`${slotDate} ${toCheck?.endTime}`);

      // Handle slots that cross midnight

      // Handle selected time
      const selectedStart = new Date(selectedTimeSlots[0]?.startTime);
      const selectedEnd = new Date(SetEndTime);

      // If selected time is midnight (00:00), ensure it fits within the range
      const isMidnightSelected =
        selectedStart.getHours() === 0 && selectedStart.getMinutes() === 0;
      if (!isMidnightSelected && slotEndDate <= slotStartDate) {
        slotEndDate.setDate(slotEndDate.getDate() + 1);
      }
      if (isMidnightSelected) {
        slotStartDate.setDate(slotStartDate.getDate() - 1);
      }
      // Ensure slots with the same start and end times are valid
      const isSameTime = toCheck?.startTime === toCheck?.endTime;
      const durationCheck = toCheck?.remainingDuration < slotDuration;
      return (
        !durationCheck &&
        (isSameTime ||
          (isMidnightSelected &&
            slotStartDate <= selectedStart &&
            slotEndDate >= selectedEnd) ||
          (slotStartDate <= selectedStart && slotEndDate >= selectedEnd))
      );
    });

    if (getFinalAvailPackage?.length < 1) {
      setUsePackageData(false);
    }
    setAvailablePackageData(getFinalAvailPackage);
  }, [
    date,
    courtData.packageDetailsList,
    selectedTimeSlots,
    selectedTimeSlots.length,
    slotDuration,
  ]);

  const surfaceHandle = (val) => {
    const selectedOption = $("#selectSurfaceName")
      .find("option:selected")
      .attr("data-name");
    setSurfaceSportID((prevState) => ({
      ...prevState,
      surface: { ...prevState.surface, id: val, val: selectedOption },
    }));
    getData(val);
    // setDate(new Date().toISOString().slice(0, 10));
    setVenueSlots([]);
    setShowTimeList((prevState) => ({ ...prevState, value: "" }));
    if (MinSlotTime === 30) {
      setHours(0);
      setMinutes(30);
    } else if (MinSlotTime === 60) {
      setHours(1);
      setMinutes(0);
    } else if (MinSlotTime === 90) {
      setHours(1);
      setMinutes(30);
    }
    setSelectedTimeSlots([]);
    setSlotDuration(0.5);
    setNextAvailableSlots(0);
    gettime(date, SurfaceSportID?.sport?.id, val);
    // setVenuePrice(0);
  };

  const getData = async (val) => {
    await CustomGetAPI(`/Venue/GetPrices/${venueData?.id}/0/${val}`).then(
      (result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result?.status === "Success") {
            // console.log(result);
            setSurfaceData(result?.surfaceSport?.data);
          }
        }
      }
    );
  };

  const handleTimeChange = (e) => {
    if (
      SurfaceSportID?.surface.id != "" &&
      SurfaceSportID?.surface?.id != "0"
    ) {
      setShowTimeList((prevState) => ({
        ...prevState,
        show: !prevState?.show,
      }));
    }
  };

  const handleDateChange = async (val) => {
    setDate(val);
    if (MinSlotTime === 30) {
      setHours(0);
      setMinutes(30);
    } else if (MinSlotTime === 60) {
      setHours(1);
      setMinutes(0);
    } else if (MinSlotTime === 90) {
      setHours(1);
      setMinutes(30);
    }
    // setVenuePrice(0);
    let weeks = [];
    if (!isPackageBooking) {
      if (!isWeekend(val)) {
        //sat-sun
        weeks = sportData?.prices[0]?.prices;
      } else {
        //mon-fri
        weeks = sportData?.prices[1]?.prices;
      }
    } else {
      weeks = 1;
    }

    setWeekPrice(weeks);
    setSelectedTimeSlots([]);
    setSlotDuration(0.5);
    setNextAvailableSlots(0);
    gettime(val, SurfaceSportID?.sport?.id);
  };

  async function gettime(val, sportid, surfaceId, nearByPage) {
    await CustomGetAPI(
      isPackageBooking
        ? `/Packages/GetPackageTimeslots/${venueData?.id}/${
            sportid ? sportid : SurfaceSportID?.sport?.id
          }/${surfaceId ? surfaceId : SurfaceSportID?.surface?.id}/${
            timeDuration ? timeDuration : 0
          }/${isEvent}/false/${selectedPackageId}?date=${val ? val : date}`
        : `/Venue/GetTimeslots/${venueData?.id}/${
            sportid ? sportid : SurfaceSportID?.sport?.id
          }/${surfaceId ? surfaceId : SurfaceSportID?.surface?.id}/${
            timeDuration ? timeDuration : 0
          }/${isEvent}/false?date=${val ? val : date}`
    ).then((result) => {
      if (result === undefined || result === "") {
        // alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result?.length > 0) {
          setVenueSlots(result);
          // if (venueSlots.length > 0) {
          //   setSelectedTimeSlots([venueSlots[0]]);
          // }
          // setSelectedTimeSlots(result[0]);
          // console.log("res - ", result);
          setShowTimeList((prevState) => ({
            ...prevState,
            value: result[0]?.startTimeString,
          }));
          if (nearByPage) {
            let slotDate = nearbyobj?.dateTime;
            const availableSlot = result.filter(
              (data) => data?.startTime === slotDate && !data?.booked
            );
            if (availableSlot?.length > 0) {
              handleTimeSlot(availableSlot[0], result);
            }
          }
          // let price = getPriceForTime(result[0]?.startTime, weeks);
          // setVenuePrice(price);
        } else if (result.length === 0) {
          setVenueSlots([]);
        }
      }
    });
  }

  const sportsHandle = (val, label, newDate, nearByPage) => {
    // setDate(new Date().toISOString().slice(0, 10));
    const selectedOption = $("#selectSportsName")
      .find("option:selected")
      .attr("data-name");
    setSurfaceSportID((prevState) => ({
      ...prevState,
      sport: {
        ...prevState.sport,
        id: val,
        val: selectedOption ? selectedOption : label,
      },
    }));
    setVenueSlots([]);
    setsurfacetype([]);
    setShowTimeList((prevState) => ({ ...prevState, value: "" }));
    let sportData = surfaceData?.find((item) => item.id === parseInt(val));
    setSportData(sportData);
    setSelectedTimeSlots([]);
    setSlotDuration(0.5);
    setNextAvailableSlots(0);
    if (val != 0) {
      CustomGetAPI(`/venue/Getsurfaces/${venueData?.id}/${val}`).then(
        (result) => {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            result = JSON.parse(result);
            if (result?.status === "Success") {
              setDuration(result?.duration);
              setTimeDuration(result?.duration[0]?.time);
              setCartCount(result?.noOfCarts);
              setsurfacetype(result.response);
              setSurfaceSportID((prevState) => ({
                ...prevState,
                surface: {
                  ...prevState.surface,
                  id: result.response[0].surfaceid,
                  val: result.response[0].surfacename,
                },
              }));
              gettime(
                newDate ? newDate : date,
                parseInt(val),
                result.response[0].surfaceid,
                nearByPage
              );
            }
          }
        }
      );
    }
  };

  const handleTimeSlot = (slots, result) => {
    if (!slots?.booked) {
      if (MinSlotTime === 30) {
        setHours(0);
        setMinutes(30);
      } else if (MinSlotTime === 60) {
        setHours(1);
        setMinutes(0);
      } else if (MinSlotTime === 90) {
        setHours(1);
        setMinutes(30);
      }

      let count = 0;
      const selectedStartTime = slots?.startTime;
      if (result && result?.length > 0) {
        for (let i = 0; i < result.length; i++) {
          const venueslot = result[i];

          // Check if the slot's start time is after the selected start time
          if (venueslot.startTime > selectedStartTime) {
            if (!venueslot.booked) {
              count++;
            } else {
              break;
            }
          }
        }
        setSlotDuration(parseFloat(nearbyobj?.slotDuration));
      } else {
        for (let i = 0; i < venueSlots.length; i++) {
          const venueslot = venueSlots[i];

          // Check if the slot's start time is after the selected start time
          if (venueslot.startTime > selectedStartTime) {
            if (!venueslot.booked) {
              count++;
            } else {
              break;
            }
          }
        }
        setSlotDuration(0.5);
      }
      setNextAvailableSlots(count);
      // Toggle the selected state of the time slot
      setSelectedTimeSlots((prevSelected) => {
        const isSlotSelected =
          prevSelected.length > 0 &&
          prevSelected[0].startTimeString === slots.startTimeString;

        if (isSlotSelected) {
          return [];
        } else {
          setShowTimeList((prevState) => ({
            ...prevState,
            show: false,
          }));
          let selectedIndex = result?.findIndex(
            (data) => data?.startTime === slots?.startTime
          );
          let newSelected = result?.slice(
            selectedIndex,
            selectedIndex + parseFloat(nearbyobj?.slotDuration, 10) * 2
          );
          return result && result?.length > 0 ? newSelected : [slots];
        }
      });
    }

    // let price = getPriceForTime(slots?.startTime, weekPrice);
    // setVenuePrice(price);
  };

  useEffect(() => {
    if (MinSlotTime === 30) {
      setHours(0);
      setMinutes(30);
    } else if (MinSlotTime === 60) {
      setHours(1);
      setMinutes(0);
    } else if (MinSlotTime === 90) {
      setHours(1);
      setMinutes(30);
    }
  }, [MinSlotTime]);

  useEffect(() => {
    function handleClickOutside(ref) {
      return function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowTimeList((prevState) => ({ ...prevState, show: false }));
        }
      };
    }
    const handleTimeClick = handleClickOutside(TimeRef);
    document.addEventListener("mousedown", handleTimeClick);

    return () => {
      document.removeEventListener("mousedown", handleTimeClick);
    };
  }, [TimeRef]);

  useEffect(() => {
    if (timeDuration && SurfaceSportID?.surface?.id) {
      gettime();
    }
  }, [timeDuration, SurfaceSportID?.surface?.id]);

  useEffect(() => {
    if (isNearBy) {
      setDate(nearbyobj?.dateTime?.split("T")[0]);
      setSurfaceSportID((prevState) => ({
        ...prevState,
        sport: {
          sport: {
            ...prevState.sport,
            id: nearbyobj?.selectedSport,
            val: nearbyobj?.selectedSport,
          },
        },
      }));
      sportsHandle(
        nearbyobj?.selectedSport,
        nearbyobj?.selectedSport,
        nearbyobj?.dateTime?.split("T")[0],
        true
      );
    }
  }, []);

  return (
    <>
      {paymentStatus.loader || isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          {showPage?.showLogin ? (
            <VerifyPhoneNumber
              setShowPage={setShowPage}
              // setshowpayment={setShowPayment}
              isfromvenueowner={false}
              isActive={venueData?.isAcrive}
              handlerequest={handlerequest}
            />
          ) : showPage?.userNameShow ? (
            <AddUserName
              show={showPage?.userNameShow}
              setShowPage={setShowPage}
              setShowVenueBookingContent={setShowVenueBookingContent}
            />
          ) : (
            <>
              {paymentStatus.Status === "" && (
                <div>
                  {showVenueBookingContent === 1 ? (
                    <>
                      <div className="px-md-3">
                        <h5 className="colorClass text-center">
                          Booking Details
                        </h5>
                        {isPackageBooking && (
                          <div className="venueText text-center">
                            {" "}
                            <span>Please choose from applicable days</span>
                          </div>
                        )}
                        <div className="mt-4 parent" id="payPopup">
                          <div className="row mt-3">
                            <div className="col-4">
                              <span className="venueText">
                                {courtData?.data[0]?.category}
                              </span>
                            </div>
                            <div className="col-8 parent">
                              <select
                                id="selectSportsName"
                                required
                                className="customize-select"
                                aria-label="Select Sports"
                                name=""
                                onChange={(e) => {
                                  sportsHandle(e.target.value);
                                }}
                                value={SurfaceSportID?.sport?.id}
                                disabled={
                                  venueData?.venueSports?.length === 1 ||
                                  venueData?.venueSports === null
                                }
                                style={{
                                  background:
                                    venueData?.venueSports?.length === 1 ||
                                    venueData?.venueSports === null
                                      ? "#F7F7F7"
                                      : "",
                                  opacity:
                                    venueData?.venueSports?.length === 1 ||
                                    venueData?.venueSports === null
                                      ? "0.4"
                                      : "1",
                                  lineHeight: "1.7",
                                }}
                              >
                                <option value="0">Select Sports</option>

                                {venueData?.venueSports?.map((data, i) => (
                                  <option
                                    key={data?.id}
                                    value={data?.id}
                                    data-name={data?.name}
                                    selected={
                                      venueData?.venueSports?.length === 1 &&
                                      i === 0
                                    }
                                  >
                                    {data?.name}
                                  </option>
                                ))}
                              </select>
                              <span className="form-text invalid-feedback">
                                * This field is Required!
                              </span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-4">
                              <span className="venueText">
                                {courtData?.data[0]?.category === "Sports"
                                  ? "Court/Surface"
                                  : "Space"}
                              </span>
                            </div>
                            <div className="col-8 parent">
                              <select
                                id="selectSurfaceName"
                                required
                                className="customize-select"
                                style={{
                                  background:
                                    surfacetype?.length < 2 ||
                                    surfacetype === null
                                      ? "#F7F7F7"
                                      : "",
                                  opacity:
                                    surfacetype?.length < 2 ||
                                    surfacetype === null
                                      ? "0.4"
                                      : "1",
                                }}
                                aria-label="Select Sports"
                                onChange={(e) => {
                                  surfaceHandle(e.target.value);
                                  setSelectedSurfaceIndex(
                                    e.target.selectedIndex - 1
                                  );
                                }}
                                name=""
                                value={SurfaceSportID?.surface?.id}
                                disabled={
                                  surfacetype?.length < 2 ||
                                  surfacetype === null
                                }
                              >
                                <option value="0">
                                  Select{" "}
                                  {courtData?.data[0]?.category === "Sports"
                                    ? "Surface"
                                    : "Event"}
                                </option>
                                {surfacetype?.map((surface, i) => (
                                  <option
                                    value={surface.surfaceid}
                                    data-name={surface?.surfacename}
                                    data-id={i}
                                    selected={
                                      surfacetype.length === 1 && i === 0
                                    }
                                  >
                                    {surface.surfacename}
                                  </option>
                                ))}
                              </select>
                              <span className="form-text invalid-feedback">
                                * This field is Required!
                              </span>
                            </div>
                          </div>
                          {SurfaceSportID?.sport?.val === "Auditorium" &&
                            SurfaceSportID?.surface?.id != 0 &&
                            courtData?.data[selectedSurfaceIndex]
                              ?.isrehearsal && (
                              <div className="row mt-3">
                                <div className="col-12">
                                  <span className="venueText">
                                    Are you booking for rehearsal?
                                  </span>
                                </div>
                                <div className="col-8 parent">
                                  <div className="row">
                                    <div className="col-md-3 col-4 my-1">
                                      <input
                                        type="radio"
                                        name="isEvent"
                                        value="yes"
                                        id="yes"
                                        className="form-check-input me-1 "
                                        onClick={(e) => {
                                          setIsEvent(true);
                                        }}
                                        checked={isEvent}
                                      />
                                      <label
                                        htmlFor="yes"
                                        className="venueText"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div className="col-md-4 col-6 my-1">
                                      <input
                                        type="radio"
                                        name="isEvent"
                                        value="no"
                                        id="no"
                                        className="form-check-input me-1 "
                                        onClick={(e) => {
                                          setIsEvent(false);
                                        }}
                                        checked={!isEvent}
                                      />
                                      <label htmlFor="no" className="venueText">
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                          <div className="row mt-3">
                            <div className="col-4">
                              <span className="venueText">Date</span>
                            </div>
                            <div className="col-8 parent">
                              <input
                                type="date"
                                min={new Date().toJSON().slice(0, 10)}
                                required
                                className="form-control"
                                id="dateInput"
                                style={{
                                  background: "#F7F7F7",
                                  opacity:
                                    SurfaceSportID?.surface?.id === "" ||
                                    SurfaceSportID?.surface?.id === "0"
                                      ? "0.4"
                                      : "1",
                                }}
                                value={date}
                                onChange={(e) => {
                                  handleDateChange(e.target.value);
                                }}
                                disabled={
                                  SurfaceSportID?.surface?.id === "" ||
                                  SurfaceSportID?.surface?.id === "0"
                                }
                              />
                              <span className="form-text invalid-feedback">
                                * This field is Required!
                              </span>
                            </div>
                          </div>
                          {SurfaceSportID?.sport?.val &&
                          SurfaceSportID?.sport?.val === "Go-Karting" ? (
                            <>
                              <div className="row ">
                                <div className="col-4 mt-3">
                                  <span className="venueText">Duration</span>
                                </div>
                                <div className="col-8">
                                  <div className="row">
                                    {Duration?.map((item) => (
                                      <>
                                        <div className="col-md-4 col-6 parent position-relative mt-3 ">
                                          <div className="input-group ">
                                            <div
                                              className={`form-control text-center ${
                                                timeDuration === item?.time
                                                  ? "active-time"
                                                  : ""
                                              }`}
                                              id=""
                                              onClick={() => {
                                                setTimeDuration(item?.time);
                                                setSelectedTimeSlots([]);
                                                setSlotDuration(0.5);
                                                setNextAvailableSlots(0);
                                              }}
                                            >
                                              <span>{item?.time} Min</span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-4 ">
                                  <span className="venueText">No. of Cart</span>
                                </div>
                                <div className="col-8">
                                  <select
                                    className="customize-select"
                                    value={selectedCartCount}
                                    onChange={(e) =>
                                      setSelectedCartCount(e.target.value)
                                    }
                                  >
                                    {Array.from(
                                      { length: cartCount },
                                      (_, i) => i + 1
                                    ).map((num) => (
                                      <option key={num} value={num}>
                                        {num}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="row mt-3">
                              <div className="col-4">
                                <span className="venueText">Min Duration</span>
                              </div>
                              <div className="col-8 parent position-relative">
                                <div className="input-group">
                                  {/* <div
                                    className="form-control"
                                    id=""
                                    style={{
                                      background: "#F7F7F7",
                                      opacity: "0.7",
                                    }}
                                  >
                                    <span>{MinSlotTime} Mins</span>
                                  </div> */}
                                  <span>{MinSlotTime} Mins</span>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row mt-3">
                            <div className="col-4 ">
                              <span className="venueText">Start Time</span>
                            </div>
                            <div
                              className="col-8 parent position-relative"
                              ref={TimeRef}
                            >
                              <div
                                className="input-group"
                                onClick={handleTimeChange}
                                style={{
                                  opacity:
                                    SurfaceSportID?.surface?.id === "" ||
                                    SurfaceSportID?.surface?.id === "0"
                                      ? "0.4"
                                      : "1",
                                }}
                              >
                                <div
                                  className="form-control"
                                  id="TimeInput"
                                  style={{ background: "#F7F7F7" }}
                                >
                                  {selectedTimeSlots?.length === 0 ? (
                                    "-- : --"
                                  ) : (
                                    <span>
                                      {selectedTimeSlots[0]?.startTimeString}
                                    </span>
                                  )}
                                </div>
                                <span
                                  className="input-group-text"
                                  id="basic-time"
                                >
                                  <img
                                    src="/images/clock.svg"
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </div>
                              <small className="d-none selectedtimeslots text-danger">
                                * This field is Required!
                              </small>
                              {showTimeList?.show && (
                                <div className="time-dropdown ">
                                  <div
                                    className="filtersOpen mx-2"
                                    style={{
                                      maxHeight: "150px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <div className="row mx-0">
                                      {venueSlots?.length !== 0 ? (
                                        venueSlots
                                          ?.filter(
                                            (slot) =>
                                              slot.startTime.split("T")[0] ===
                                              date
                                          )
                                          ?.map((slots, i) => {
                                            const isSlotSelected =
                                              selectedTimeSlots[0]
                                                ?.startTimeString ===
                                              slots?.startTimeString;

                                            return (
                                              <div
                                                className="col-6 py-1 px-1 my-1"
                                                key={i}
                                              >
                                                <button
                                                  className={`citiesbox px-2 ${
                                                    isSlotSelected
                                                      ? "slot-active"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    handleTimeSlot(slots);
                                                  }}
                                                  disabled={slots?.booked}
                                                  style={{
                                                    opacity: slots.booked
                                                      ? "0.3"
                                                      : "",
                                                  }}
                                                >
                                                  {slots?.startTimeString}
                                                </button>
                                              </div>
                                            );
                                          })
                                      ) : (
                                        <div className="citiesbox border-0">
                                          No Time Slots available
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="row mt-3"
                            style={{
                              display:
                                SurfaceSportID?.sport?.val != "Go-Karting"
                                  ? ""
                                  : "none",
                            }}
                          >
                            <div className="col-4 ">
                              <span className="venueText">Duration</span>
                            </div>
                            <div className="col-8 parent">
                              <div className="row align-items-center">
                                <div className="col-2 pe-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class={`cursor-pointer br-50 ${
                                      slotDuration === 0.5
                                        ? "DisableDurationBtn"
                                        : "AddDurationBtn"
                                    }`}
                                    onClick={(e) => {
                                      if (slotDuration != 0.5) {
                                        setNextAvailableSlots(
                                          (count) => count + 1
                                        );
                                        setSlotDuration(
                                          (prevDuration) => prevDuration - 0.5
                                        );
                                        setSelectedTimeSlots((prevSelected) => [
                                          ...prevSelected.slice(0, -1),
                                        ]);
                                      }
                                    }}
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                      clip-rule="evenodd"
                                    ></path>
                                  </svg>
                                </div>
                                <div className="col-8 p-0 text-center">
                                  {convertDecimalToTime(slotDuration)}
                                </div>
                                <div className="col-2 ps-0 text-end">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class={`${
                                      nextAvailableSlots === 0
                                        ? "DisableDurationBtn"
                                        : "AddDurationBtn"
                                    } ${
                                      isPackageBooking &&
                                      selectedPackageDuration <
                                        slotDuration + 0.5 &&
                                      "DisableDurationBtn"
                                    } cursor-pointer br-50`}
                                    onClick={(e) => {
                                      handleslotadditionchange(e);
                                    }}
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                      clip-rule="evenodd"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                              <small className="form-text minSlotTimeError text-danger d-none">
                                <>
                                  Minimum{" "}
                                  {convertDecimalToTime(
                                    minToHour(venueData?.minSlotTime)
                                  )}{" "}
                                  booking is required{" "}
                                </>
                              </small>
                            </div>
                          </div>
                          {!isPackageBooking && (
                            <>
                              {" "}
                              <div className="row mt-3">
                                <div className="col-4 pe-0">
                                  <span className="venueText">
                                    Have Promo Code?
                                  </span>
                                </div>
                                <div className="col-8">
                                  <div className="input-group">
                                    <input
                                      name="coupon"
                                      type="text"
                                      value={couponName}
                                      disabled={couponApplied || redeemBalance}
                                      onChange={(e) => {
                                        setCouponName(e.target.value);
                                      }}
                                      className="form-control custom-placeholder"
                                      autoComplete="off"
                                      placeholder="Coupon Code"
                                    />
                                    <span
                                      className="input-group-text durationSelection cursor-pointer"
                                      onClick={(e) => {
                                        if (!redeemBalance) {
                                          if (
                                            isLoggedIn === null ||
                                            isLoggedIn === ""
                                          ) {
                                            setShowPage({
                                              showLogin: true,
                                              userNameShow: false,
                                            });
                                          } else {
                                            setShowPage({
                                              showLogin: false,
                                              userNameShow: false,
                                            });
                                            applyCoupon("", venueData?.id);
                                          }
                                        } else {
                                          Swal.fire({
                                            toast: true,
                                            icon: "warning",
                                            title: `Referral Point's already used! Can't use both at same time`,
                                            timer: 3000,
                                            showConfirmButton: false,
                                            position: "top",
                                          });
                                        }
                                      }}
                                    >
                                      Apply
                                    </span>
                                  </div>

                                  <span className="form-text couponSuccess text-success d-none">
                                    {showSuccess && (
                                      <>
                                        {" "}
                                        Woohoo! You just saved{" "}
                                        {couponData?.offPercentage}% with your
                                        coupon. You Saved ₹
                                        {discountAmount &&
                                          discountAmount?.toFixed(2)}
                                      </>
                                    )}
                                  </span>

                                  <span className="form-text couponError d-none"></span>
                                </div>
                              </div>
                              {walletBalance > 0 && (
                                <div className="row mt-3 align-items-center">
                                  <div className="col-4 pe-0">
                                    <span className="venueText">
                                      Available Balance!
                                    </span>
                                  </div>
                                  <div className="col-8">
                                    <div className="input-group d-flex align-items-center justify-content-between">
                                      ₹{walletBalance}
                                      <span
                                        className="input-group-text durationSelection cursor-pointer"
                                        onClick={(e) => {
                                          if (usePackageData) {
                                            Swal.fire({
                                              toast: true,
                                              icon: "warning",
                                              title: `Wallet balance can't be redeemed on package booking!`,
                                              timer: 3000,
                                              showConfirmButton: false,
                                              position: "top",
                                            });
                                            return;
                                          } else if (!couponApplied) {
                                            setredeemBalance(true);
                                          } else {
                                            Swal.fire({
                                              toast: true,
                                              icon: "warning",
                                              title: `Coupon already applied! Can't use both at same time`,
                                              timer: 3000,
                                              showConfirmButton: false,
                                              position: "top",
                                            });
                                          }
                                        }}
                                      >
                                        Redeem
                                      </span>
                                    </div>
                                    {redeemBalance && (
                                      <span className="form-text couponSuccess text-success">
                                        <>
                                          Woohoo! You Saved ₹
                                          {walletBalance > 0 && 50}
                                        </>
                                      </span>
                                    )}

                                    <span className="form-text couponError d-none"></span>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {!isPackageBooking &&
                            availablePackageData?.length > 0 && (
                              <div className="row mt-3 mx-0">
                                <div className="d-flex align-items-start justify-content-end form-check">
                                  <input
                                    type="checkbox"
                                    className="me-3 mt-2 form-check-input"
                                    id="packageBooking"
                                    checked={usePackageData}
                                    onClick={() => {
                                      setUsePackageData(!usePackageData);
                                      setCouponApplied(false);
                                      setShowSuccess(false);
                                      setredeemBalance(false);
                                      setCouponData([]);
                                    }}
                                    disabled={availablePackageData?.length < 1}
                                  />
                                  <label
                                    htmlFor="packageBooking"
                                    className="fw-500"
                                  >
                                    You own a package(
                                    {availablePackageData[0]?.name}) , use it to
                                    book
                                  </label>
                                </div>
                              </div>
                            )}
                        </div>
                        {venueData?.isAcrive === 5 && (
                          <div
                            className="mt-3 p-3"
                            style={{
                              textAlign: "justify",
                              border: "2px dashed red",
                            }}
                          >
                            <small>
                              Venue manager will review your request and give
                              booking confirmation with-in {RequestTime}{" "}
                              minutes. Please do not close or press back button
                              during the process, we will keep you posted about
                              process on the screen.
                            </small>
                          </div>
                        )}
                        <div className="mt-4 text-center">
                          {venueData?.isAcrive === 4 ? (
                            <>
                              {" "}
                              <button
                                className="venueListingTop booking  px-4 "
                                onClick={() => {
                                  !isLoader && handlePayment();
                                }}
                              >
                                <img
                                  className="me-2"
                                  src="/images/booking.svg"
                                  alt="book"
                                />
                                Confirm Booking{" "}
                                {!isPackageBooking &&
                                  !usePackageData &&
                                  `₹${venuePrice}`}{" "}
                              </button>
                              {isLoader && (
                                <img
                                  className="img-fluid ms-2"
                                  src="/images/loder.svg"
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <button
                                className="venueListingTop booking  px-4 "
                                onClick={() => {
                                  !isLoader && handlerequest();
                                }}
                              >
                                <img
                                  className="me-2"
                                  src="/images/booking.svg"
                                  alt="book"
                                />
                                Confirm{" "}
                                {!isPackageBooking &&
                                  !usePackageData &&
                                  `₹${venuePrice}`}{" "}
                              </button>
                              {isLoader && (
                                <img
                                  className="img-fluid ms-2"
                                  src="/images/loder.svg"
                                />
                              )}
                            </>
                          )}

                          {SurfaceSportID?.sport?.val === "Go-Karting" && (
                            <>
                              <div className="row text-start disclaimerbox py-3 mt-3 px-1">
                                <div className="col-12 text-center ">
                                  <b>Go-Karting Booking Disclaimer</b>
                                </div>
                                <div className="col-12 my-2">
                                  1. While online booking is recommended, venues
                                  do accept walk-in customers. This may result
                                  in a maximum 30-minute delay for online
                                  bookings; however, we prioritize online
                                  reservations whenever possible.
                                </div>
                                <div className="col-12">
                                  2. Please be aware that occasional delays may
                                  occur due to power outages or other technical
                                  difficulties.
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {!usePackageData && venueremainingPrice > 0 && (
                          <div className="mt-2 text-center">
                            <label>Pay At Venue: </label>
                            <span>&nbsp;₹{venueremainingPrice}</span>
                          </div>
                        )}
                      </div>
                      {!isPackageBooking && venueData?.isAcrive && (
                        <>
                          <div className="row text-start disclaimerbox py-3 mt-3 mx-md-1">
                            <div className="col-12 text-center ">
                              <b>Payment Policy</b>
                            </div>
                            <div className="col-12 mt-2">
                              1.To reserve your slot, simply pay a{" "}
                              {showVenueBookingContent &&
                                venueData?.bookingAmount}
                              % deposit at the time of booking. The remaining{" "}
                              {showVenueBookingContent &&
                                100 - venueData?.bookingAmount}
                              % can be paid conveniently when you arrive at the
                              venue.
                            </div>
                            <div className="col-12 my-2">
                              2.
                              {venueData?.cancellationTime > 0
                                ? `According to this venue owner's policy, cancellation
                        is possible up to 
                        ${
                          venueData?.cancellationTime > 48
                            ? `${venueData.cancellationTime / 24} days`
                            : `${venueData.cancellationTime} hours`
                        } 
                        hours prior to the event, in this case a 100% refund
                        will be provided to the user.`
                                : `This venue has a no-cancellation policy(You can't cancel once you've booked here)`}
                            </div>
                            {courtData?.data[selectedSurfaceIndex]
                              ?.extrahourecharge > 0 && (
                              <div className="col-12">
                                3.In case of extra hours you will be charged{" "}
                                {
                                  courtData?.data[selectedSurfaceIndex]
                                    ?.extrahourecharge
                                }{" "}
                                rs per hour
                              </div>
                            )}
                          </div>
                          <div className="row text-start disclaimerbox py-3 mt-3 mx-md-1">
                            <div className="col-12 text-center ">
                              <b>Reschedule Policy</b>
                            </div>
                            <div className="col-12 mt-2">
                              1.
                              {venueData?.rescheduleCount > 0
                                ? `To reschedule the booking, the rescheduling
                              request must comply with the venue's rescheduling
                              policy, which allows for
                              ${venueData?.rescheduleCount} reschedules up to
                              ${venueData?.rescheduleHours} hours prior to the
                              event.`
                                : "Rescheduling is not possible at this venue"}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : showVenueBookingContent === 2 ? (
                    <>
                      <div className="row mt-2">
                        <div className="col-12">
                          <img
                            className="cursorClass"
                            src="/images/backArrow.svg"
                            onClick={() => setShowVenueBookingContent(1)}
                          />
                        </div>
                      </div>
                      <div className="row text-center mb-4">
                        <h1 className="text-center">Payment</h1>
                        <div
                          className="col-12 text-center"
                          style={{ fontSize: "20px" }}
                        >
                          You are booking
                        </div>
                        <div className="col-12 text-center">
                          <b style={{ fontSize: "26px" }}>{venueData.name}</b>
                        </div>
                        <div className="col-12 text-center">
                          {venueData.addresses}
                        </div>
                        {/* <div className="col-12 text-center">For playing</div> */}
                        <div className="col-12 text-center">
                          {/* <b>{SurfaceSportID.sport?.val}</b> */}
                          on {VenueDate(date).formattedDate}
                        </div>
                        <div className="col-12 text-center">
                          at {selectedTimeSlots[0].startTimeString} -{" "}
                          {
                            selectedTimeSlots[selectedTimeSlots.length - 1]
                              .endTimeString
                          }
                          {venueData?.isAcrive && (
                            <div className="col-12 text-center">
                              Pay ₹{venuePrice} ({venueData?.bookingAmount}%)
                              now to secure your slot and
                            </div>
                          )}
                          {venueData?.isAcrive && (
                            <div className="col-12 text-center">
                              pay<b> ₹{venueremainingPrice} </b>at venue.
                            </div>
                          )}
                        </div>
                        <small className="d-none bookederror text-center text-danger w-100"></small>
                        <div className="mt-3">
                          <button
                            onClick={() => {
                              payNow("");
                            }}
                            className="venueListingTop booking w-75"
                          >
                            Pay Now (₹{venuePrice})
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <div>
                {paymentStatus.Status === "captured" && (
                  <div className="paymentSuccess mt-5 ">
                    <div className="text-end">
                      <a className="text-end" onClick={handlePrintCard}>
                        Print Reciept
                      </a>
                    </div>

                    <div className="mt-3" ref={cardRef}>
                      <div className="text-center">
                        <img
                          src="/images/correct.svg"
                          className="text-center"
                          alt=""
                        />

                        <h4 className="mt-3">Payment successful!</h4>
                        <p className="paymentText">
                          You can now visit and play.
                        </p>
                      </div>
                      <Card
                        className="mt-2"
                        style={{
                          borderRadius: "10px",
                          background: "white",
                          border: "none",
                          boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Card.Body className="">
                          <div className="row">
                            <div className="col-5">
                              <span className="paymentText">Date : </span>
                            </div>
                            <div className="col-7">
                              <span className="paymentText fw-bold">
                                {VenueDate(date).formattedDate}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <span className="paymentText">Venue Name : </span>
                            </div>
                            <div className="col-7">
                              <span className="paymentText fw-bold">
                                {venueData?.name}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <span className="paymentText">
                                Venue Address :
                              </span>
                            </div>
                            <div className="col-7">
                              <span className="paymentText fw-bold">
                                {venueData?.addresses}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <span className="paymentText">Time : </span>
                            </div>
                            <div className="col-7">
                              <span className="paymentText fw-bold">
                                {selectedTimeSlots?.map(
                                  (selectedSlot, index) => (
                                    <>
                                      {index > 0 && <br />}
                                      {selectedSlot?.startTimeString}-
                                      {selectedSlot?.endTimeString}
                                    </>
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-5">
                              <span className="paymentText">
                                Order number :{" "}
                              </span>
                            </div>
                            <div className="col-7">
                              <span className="paymentText fw-bold">
                                {paymentStatus?.OrderID}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-5 paymentText fw-bold"
                              style={{ color: "#4CAF50" }}
                            >
                              Amount paid:
                            </div>
                            <div
                              className="col-7 paymentText fw-bold"
                              style={{ color: "#4CAF50" }}
                            >
                              ₹{venuePrice}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="mt-5 mb-2">
                      <button
                        className="venueListingTop w-100 rounded-0 btn-danger"
                        // style={{ background: "#4CAF50" }}
                        onClick={(e) => {
                          window.localStorage.setItem(
                            "RedirectURL",
                            window.location.href
                          );
                          navigate("/venue-booking-details");
                        }}
                      >
                        View Bookings
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
