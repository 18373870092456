import React, { useEffect, useMemo, useState } from "react";
import { convertTo12HourFormat } from "../../Utils/common";
import $ from "jquery";
import { useLocation } from "react-router-dom";

export default function PackageDays({
  data,
  dispatch,
  setShow,
  ShowEnterData,
}) {
  const [wholeWeekChecked, setWholeWeekChecked] = useState(false);
  const [daysToShow, setDaysToShow] = useState([]);
  const [days, setDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const listData = {
    weekDays: "",
    data: [
      {
        startTime: "00:00",
        endTime: "00:00",
        amount: "0",
        duration1: "",
        duration2: "",
        duration3: "",
        id: 0,
      },
    ],
  };

  const [formValuesSportsFacilities, setFormValues] = useState(listData);
  const [errorMessages, setErrorMessages] = useState({});

  const [errormsg, setErrormsg] = useState("");
  const handleChange = (i, e) => {
    if (
      e.target.name === "amount" &&
      (e.target.value.length > 5 || e.target.value < 0)
    ) {
      return;
    }
    if (
      e.target.name !== "amount" &&
      e.target.name !== "duration1" &&
      e.target.name !== "duration2" &&
      e.target.name !== "duration3"
    ) {
      let newFormValues = { ...formValuesSportsFacilities };
      newFormValues.data[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
      validateFields(newFormValues.data, i);
      $("#invalid-time-MonFri-" + i).text("");
    } else {
      let newFormValues = { ...formValuesSportsFacilities };
      newFormValues.data[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    }
  };

  const addFormFields = () => {
    let arrayData = listData;
    arrayData = { ...arrayData };
    arrayData.startTime = "00:00";
    arrayData.endTime = "00:00";
    arrayData.amount = "";
    const updatedFormValues = { ...formValuesSportsFacilities };
    updatedFormValues.data.push(arrayData);
    setFormValues(updatedFormValues);
  };

  const removeFormFields = (i) => {
    const newFormValues = { ...formValuesSportsFacilities };
    newFormValues.data.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleWholeWeekChange = (e) => {
    const isChecked = e.target.checked;
    setDays({
      Mon: isChecked,
      Tue: isChecked,
      Wed: isChecked,
      Thu: isChecked,
      Fri: isChecked,
      Sat: isChecked,
      Sun: isChecked,
    });
    if (!isChecked) {
      setDays({
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
      });
    }
    setWholeWeekChecked(isChecked);
  };

  const handleDayChange = (event) => {
    const { id, checked } = event.target;

    setDays((prevDays) => ({
      ...prevDays,
      [id]: checked,
    }));
    setWholeWeekChecked(false);
  };

  const validateFields = (data, index) => {
    let errors = {};

    data.forEach((element, idx) => {
      if (!element.startTime) {
        errors[`startTime-${idx}`] = "Start Time is required.";
      } else {
        errors[`startTime-${idx}`] = "";
      }
      if (!element.endTime) {
        errors[`endTime-${idx}`] = "End Time is required.";
      } else {
        errors[`endTime-${idx}`] = "";
      }
    });

    const lastIndex = data.length - 1;
    const currentSlot = data[lastIndex];
    const currentStart = new Date(`1970-01-01T${currentSlot.startTime}:00`);
    const currentEnd =
      currentSlot.endTime === "00:00"
        ? new Date(`1970-01-02T${currentSlot.endTime}:00`)
        : new Date(`1970-01-01T${currentSlot.endTime}:00`);

    for (let j = 0; j < data.length - 1; j++) {
      const compareSlot = data[j];
      const compareStart = new Date(`1970-01-01T${compareSlot.startTime}:00`);
      const compareEnd =
        compareSlot.endTime === "00:00"
          ? new Date(`1970-01-02T${compareSlot.endTime}:00`)
          : new Date(`1970-01-01T${compareSlot.endTime}:00`);

      if (
        (currentStart < compareEnd && currentEnd > compareStart) ||
        (compareStart < currentEnd && compareEnd > currentStart) ||
        (currentStart.getTime() === compareStart.getTime() &&
          currentEnd.getTime() === compareEnd.getTime())
      ) {
        errors[`startTime-${lastIndex}`] =
          "This time slot overlaps with another slot.";
        errors[`endTime-${lastIndex}`] =
          "This time slot overlaps with another slot.";
        break;
      }
    }

    setErrorMessages(errors);
  };

  const AddData = () => {
    const selectedDays = Object.keys(days).filter((day) => days[day]);
    const checkedDays = selectedDays.join(", ");
    let newFormValues = { ...formValuesSportsFacilities };
    let isValid = true;
    if (!wholeWeekChecked && selectedDays.length === 0) {
      setErrormsg("Please select at least one day or Whole Week.");
      return;
    } else {
      setErrormsg("");
    }
    formValuesSportsFacilities.data.forEach((element, index) => {
      if (!element.startTime) {
        errorMessages[`startTime-${index}`] = "Start Time is required.";
        isValid = false;
      }

      if (!element.endTime) {
        errorMessages[`endTime-${index}`] = "End Time is required.";
        isValid = false;
      }

      if (!element.amount || element.amount <= 0) {
        errorMessages[`amount-${index}`] = "Amount must be greater than 0.";
        isValid = false;
      }
    });
    for (let i = 0; i < formValuesSportsFacilities.data.length; i++) {
      const currentSlot = formValuesSportsFacilities.data[i];
      const currentStart = new Date(`1970-01-01T${currentSlot.startTime}:00`);
      const currentEnd =
        currentSlot.endTime === "00:00"
          ? new Date(`1970-01-02T${currentSlot.endTime}:00`)
          : new Date(`1970-01-01T${currentSlot.endTime}:00`);

      for (let j = 0; j < formValuesSportsFacilities.data.length; j++) {
        if (i === j) continue;

        const compareSlot = formValuesSportsFacilities.data[j];
        const compareStart = new Date(`1970-01-01T${compareSlot.startTime}:00`);
        const compareEnd =
          compareSlot.endTime === "00:00"
            ? new Date(`1970-01-02T${compareSlot.endTime}:00`)
            : new Date(`1970-01-01T${compareSlot.endTime}:00`);

        if (
          (currentStart < compareEnd && currentEnd > compareStart) ||
          (compareStart < currentEnd && compareEnd > currentStart) ||
          (currentStart.getTime() === compareStart.getTime() &&
            currentEnd.getTime() === compareEnd.getTime())
        ) {
          errorMessages[`startTime-${i}`] =
            "This time slot overlaps with another slot.";
          errorMessages[`endTime-${i}`] =
            "This time slot overlaps with another slot.";
          errorMessages[`startTime-${j}`] =
            "This time slot overlaps with another slot.";
          errorMessages[`endTime-${j}`] =
            "This time slot overlaps with another slot.";
          isValid = false;
        }
      }
    }

    setErrorMessages({ ...errorMessages });

    if (!isValid) {
      return;
    }

    const updatedData = formValuesSportsFacilities?.data?.map((item) => ({
      ...item,
      id: 0,
    }));
    const newWeekDayEntry = {
      weekDays: checkedDays,
      data: updatedData,
    };

    setFormValues(newFormValues);
    dispatch((prevState) => {
      const newState = { ...prevState };
      newState.weekDay = [...newState.weekDay, newWeekDayEntry];
      return newState;
    });
    setShow(false);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      const amPm = i < 12 ? "AM" : "PM";
      const hour = (i % 12 ? i % 12 : 12).toString().padStart(2, "0");
      const hourValue = i.toString().padStart(2, "0");
      options.push(
        <option key={hourValue} value={`${hourValue}:00`}>
          {hour}:00 {amPm}
        </option>
      );
    }
    return options;
  };

  useEffect(() => {
    generateTimeOptions();
  }, [formValuesSportsFacilities, wholeWeekChecked, days]);

  useEffect(() => {
    let weekDaysToExclude = [];

    ShowEnterData?.forEach((item) => {
      if (item.weekDays) {
        weekDaysToExclude = weekDaysToExclude.concat(
          item.weekDays.split(",").map((day) => day.trim())
        );
      }
    });

    const filteredDays = Object.keys(days).filter(
      (day) => !weekDaysToExclude.includes(day)
    );
    setDaysToShow(filteredDays);
    setDays({
      ...days,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
      Sun: false,
    });
  }, []);
  const showhidediv = useMemo(() => {
    setErrormsg("");
    return wholeWeekChecked || !daysToShow.every((day) => days[day] === false);
  }, [wholeWeekChecked, days, daysToShow]);
  useEffect(() => {
    if (ShowEnterData) {
      setFormValues((prevValues) => {
        const newValues = { ...prevValues };
        newValues.data = newValues.data.map((item) => ({
          ...item,
          amount: item.id !== 0 && 0,
        }));
        return newValues;
      });
    }
  }, [ShowEnterData]);

  const shouldHideWholeWeek = () => {
    return ShowEnterData?.some(
      (item) => item?.weekDays && item?.weekDays?.length > 0
    );
  };
  return (
    <React.Fragment>
      <div className="row px-3">
        <div className="text-center fw-normal fs-4">New Day Period</div>
        <div className="col-12 text-justify py-2 px-0 checkBoxLabel">
          <p className="signuppolicy fw-500">
            Select days, and set start and end times to allocate hours for the
            selected period.
          </p>

          <div className="row my-3 px-2"></div>
          <div className="fw-600 mb-3">Week Days</div>
          <>
            {!shouldHideWholeWeek(data) && (
              <div className="mb-2" style={{ fontSize: "15px" }}>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={wholeWeekChecked}
                  onChange={handleWholeWeekChange}
                  id="wholeWeek"
                />
                <label htmlFor="wholeWeek">Whole Week</label>
              </div>
            )}
            <div style={{ fontSize: "15px" }}>
              {Object.keys(days).map(
                (day) =>
                  daysToShow?.includes(day) && (
                    <div className="checkbox-container" key={day}>
                      <input
                        className="custom-checkbox"
                        type="checkbox"
                        id={day}
                        checked={days[day]}
                        onChange={handleDayChange}
                      />
                      <label htmlFor={day}>{day}</label>
                    </div>
                  )
              )}
            </div>
          </>
        </div>
        {formValuesSportsFacilities?.data?.map((element, i) => {
          return (
            <div className="col-12 px-0" key={i}>
              <>
                {showhidediv && (
                  <div className="AddslotDiv">
                    <div
                      className="my-2 p-3"
                      style={{
                        backgroundColor: "#e8e8e8",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="row parent">
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            className="fw-600 mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            Slot {i + 1}
                          </div>
                          <div>
                            {formValuesSportsFacilities.data.length !== 1 ? (
                              <img
                                className="cursor-pointer mx-2"
                                src="/images/deleteicon.svg"
                                title="Delete Slot"
                                alt="icon"
                                onClick={() => removeFormFields(i)}
                                height="22px"
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div>
                            <label>Start Time</label>
                            <br />
                            <select
                              className="form-control time-select"
                              name="startTime"
                              value={element?.startTime}
                              onChange={(e) => handleChange(i, e)}
                            >
                              {generateTimeOptions()}
                            </select>
                            {errorMessages[`startTime-${i}`] && (
                              <div className="text-danger">
                                {errorMessages[`startTime-${i}`]}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div>
                            <label>End Time</label>
                            <br />
                            <select
                              className="form-control time-select"
                              name="endTime"
                              value={element?.endTime}
                              onChange={(e) => handleChange(i, e)}
                            >
                              {generateTimeOptions()}
                            </select>
                            {errorMessages[`endTime-${i}`] && (
                              <div className="text-danger">
                                {errorMessages[`endTime-${i}`]}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <span
                              className="form-text text-danger"
                              id={`invalid-time-MonFri-${i}`}
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-1 parent">
                        <label className="">Duration in hour</label>
                        <div className="col-lg-12 col-md-10 col-sm-10 col-12">
                          <div className="input-group">
                            <input
                              name="amount"
                              type="number"
                              inputMode="numeric"
                              value={element.amount ? element.amount : ""}
                              onChange={(e) => handleChange(i, e)}
                              className="form-control custom-placeholder"
                              autoComplete="off"
                              placeholder="Hours"
                            />
                            <span className="input-group-text">hr</span>
                          </div>
                          {errorMessages[`amount-${i}`] && (
                            <div className="text-danger">
                              {errorMessages[`amount-${i}`]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* {i === formValuesSportsFacilities.data.length - 1 && (
                      <div className="row mt-3 d-flex justify-content-center">
                        <div className="col-md-4 col-6">
                          <button
                            onClick={() => addFormFields()}
                            className="btn btn-secondary"
                          >
                            Add More Slot
                          </button>
                        </div>
                      </div>
                    )} */}
                  </div>
                )}
              </>
            </div>
          );
        })}
        <div className="row justify-content-center pt-3">
          <div className="col-12">
            {errormsg && ( // Display error message if present
              <div className="text-danger mb-2">{errormsg}</div>
            )}
          </div>

          <div className="col-12 d-flex justify-content-center px-0">
            <button
              onClick={() => {
                AddData();
              }}
              className="btn btn-danger"
            >
              Save Slot(s)
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
