import React, { Fragment, useEffect, useState } from "react";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import Loader from "../Utils/Loader";
import { convertTo12HourFormat } from "../Utils/common";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ListingCard from "./ListingCard";
import BookingPopup from "./BookingPopup";
import VerifyPhoneNumber from "../VerifyPhoneNumber";
import Popup from "../LiveScoreCardpopups/Popup";

export default function PackageListing() {
  const cookies = new Cookies();
  const Isapp = cookies.get("Isapp");
  const [packageData, setPackageData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedPackageId, setSelectedPackageId] = useState(0);
  const [selectedPackageDuration, setSelectedPackageDuration] = useState(0);
  const [userPackageData, setUserPackageData] = useState([]);
  const [isLoading, SetIsLoading] = useState(false);
  const phoneno = window.localStorage.getItem("phoneno");
  const UserEmail = window.localStorage.getItem("UserEmail");
  const username = window.localStorage.getItem("UserName");
  const userID = window.localStorage.getItem("userid");
  const userLocation = window.localStorage.getItem("userLocation");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getPackages();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getPackages = async () => {
    SetIsLoading(true);
    try {
      const response = await CustomGetAPI(
        `/Packages/PackageByID/0/${userID ? userID : 0}?cityname=${
          userLocation && userLocation
        }`
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result?.status == "Success") {
            const uniquePackages = Object.values(
              result?.packages?.reduce((acc, item) => {
                if (!acc[item.venueId]) {
                  acc[item.venueId] = {
                    ...item,
                    flag: false,
                    packageList: [],
                  };
                }

                acc[item.venueId].packageList.push({
                  id: item?.id,
                  name: item?.name,
                  validity: item?.validity,
                  packageDuration: item?.packageDuration,
                  price: item?.price,
                  actualprice: item?.actualprice,
                  diffPercentage: item?.diffPercentage,
                });

                return acc;
              }, {})
            );
            const sortedPackages = uniquePackages?.sort(
              (a, b) => b.diffPercentage - a.diffPercentage
            );
            console.log(sortedPackages);
            setPackageData(sortedPackages);
            const updatedUserPackage = result?.userPackages?.map((item) => ({
              ...item,
              flag: false,
            }));
            setUserPackageData(updatedUserPackage);
          }
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      SetIsLoading(false);
    }
  };

  const [showPayment, setShowPayment] = useState(false);
  const payNow = async (index) => {
    if (!userID) {
      setShowPayment(true);
      return;
    }
    const existingScript = document.querySelector(
      'script[src="https://checkout.razorpay.com/v1/checkout.js"]'
    );
    if (!Isapp && !existingScript) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }
    const data = JSON.stringify({
      VenueId: packageData[index]?.venueId,
      Date: new Date(),
      Amount: packageData[index]?.price,
      UserId: userID,
      Discountamount: 0,
      PackageID: packageData[index]?.id,
    });
    await CustomPostAPI("/payment/PackagePaymentInitialize", data).then(
      (result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            const order_id = result.orderResponse.orderId;
            const venuepaymentlogid = result.venuepaymentlogid;

            let options = {
              amount: result.orderResponse.amount,
              name: "Venue Booking Fee",
              description: "Total_venue_booking_fee",
              image: "https://dev.cricbuddy.in/images/cricketFlash.svg",
              order_id: order_id,
              handler: async (data) => {
                try {
                  window.scrollTo(0, 0);

                  Swal.fire({
                    icon: "success",
                    text: "Your purchase is confirmed successfully, you will receive notification on your WhatsApp as well as you can check your packages in your account.",
                    showConfirmButton: true,
                    allowOutsideClick: true,
                    confirmButtonText: "View Packages",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    } else {
                      window.location.reload();
                    }
                  });
                  document.body.style.overflow = "auto";
                } catch (error) {
                  // Handle payment failure
                  console.error("Payment failed:", error);
                  CustomGetAPI(
                    `/payment/AddPaymenterror/${error ?? ""}/${userID}`
                  );
                }
              },
              prefill: {
                name: username,
                email: UserEmail ? UserEmail : "",
                contact: phoneno,
              },
              theme: {
                color: "#F3F3F3",
              },
              config: {
                display: {
                  hide: [
                    { method: "paylater" },
                    { method: "netbanking" },
                    { method: "wallet" },
                  ],
                },
              },
              webview_intent: true,

              timeout: 300,
              modal: {
                ondismiss: function () {
                  document.body.style.overflow = "auto";
                },
              },
            };
            if (!Isapp) {
              options.key = result.orderResponse.razorPayAPIKey;
            }
            if (Isapp) {
              options.amount = options.amount * 100;

              const encodedPaymentData = encodeURIComponent(
                JSON.stringify(options)
              );
              window.location.href = `/mobile-booking?paymentData=${encodedPaymentData}`;
            } else {
              const paymentObject = new window.Razorpay(options);
              paymentObject.on("payment.failed", function (response) {
                console.log("error", response);
              });
              paymentObject.open();
            }
          } else {
            var isBooked = document.querySelector(".bookederror");
            isBooked.classList.remove("d-none");
            isBooked.textContent = `${result.message}`;
          }
        }
      }
    );
  };
  return (
    <Fragment>
      <div className="row mt-3">
        {isLoading ? (
          <>
            <Loader mt="20%" />
          </>
        ) : (
          <>
            {/* {userPackageData?.length > 0 && (
              <>
                <div className="text-center mb-3">
                  <h4>Purchased Packages</h4>
                </div>
                <ListingCard
                  packageData={userPackageData}
                  setPackageData={setUserPackageData}
                  setShow={setShow}
                  setSelectedIndex={setSelectedIndex}
                  setSelectedPackageId={setSelectedPackageId}
                  setSelectedPackageDuration={setSelectedPackageDuration}
                />
                <div className="text-center mt-3">
                  <h4>More Packages</h4>
                </div>
              </>
            )} */}

            {packageData?.length > 0 ? (
              <>
                <ListingCard
                  packageData={packageData}
                  setPackageData={setPackageData}
                  payNow={payNow}
                />
              </>
            ) : (
              <div className="text-center mt-2 fw-bold">
                No More Package Available
              </div>
            )}
          </>
        )}
      </div>

      {show && (
        <BookingPopup
          show={show}
          setShow={setShow}
          userID={userID}
          venueName={userPackageData[selectedIndex]?.venuename}
          selectedPackageId={selectedPackageId}
          selectedPackageDuration={selectedPackageDuration}
        />
      )}
      {showPayment && (
        <Popup
          show={showPayment}
          venueflag={1}
          onHide={() => setShowPayment(false)}
        >
          <VerifyPhoneNumber
            isfromvenueowner={false}
            isActive={0}
            setShowLogin={setShowPayment}
          />
        </Popup>
      )}
    </Fragment>
  );
}
