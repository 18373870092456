import "./App.css";
import React, { createContext, useEffect, useState } from "react";
import { Loginpage } from "./Files/login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NewHomePage from "./Files/Homepage/NewHomePage";
import { ForgotpwdPage } from "./Files/forgotpwd";
import { Profile } from "./Files/profile";
import Createownmatch from "./Files/createownmatch";
import { Welcomepage } from "./Files/welcomepage";
import { UpcomingMatches } from "./Files/UpcomingMatches";
import Layout from "./Files/Components/Layout";
import { PaymentPage } from "./Files/PaymentPage";
import { Notification } from "./Files/Notification";
import { CreatedMatches } from "./Files/MyMatches/CreatedMatches";
import { JoinedMatches } from "./Files/MyMatches/JoinedMatches";
import { MatchDetails } from "./Files/MatchDetails";
import PaymentConfirmed from "./Files/UpcomingMatches/PaymentConfirmed";
import FriendRequest from "./Files/FriendRequest";
import LiveScoreCard from "./Files/LiveScoreCard";
import LiveScorepage from "./Files/LiveScorepage";
import ScoreCard from "./Files/ScoreCard";
import { Registration } from "./Files/Registration";
import { WelcomeUser } from "./Files/WelcomeUser";
import ChangeColor from "./Files/redux/ChangeColor";
import { InviteAndEarn } from "./Files/InviteAndEarn";
import MatchScenario from "./Files/StartMatch/MatchScenario";
import VenueInformation from "./Files/venueInformation";
import VenuePlace from "./Files/VenueListing/venuePlace";
import SavedVenues from "./Files/VenueListing/savedVenues";
import SavedQueries from "./Files/VenueListing/savedqueries";
import VenueSearch from "./Files/VenueListing/venueSearch";
import VenueBookingList from "./Files/VenueListing/VenueBookingList";
import VenueBookingDetails from "./Files/VenueListing/VenueBookingDetails";
import VenuesBookedOwnerDashboard from "./Files/VenueListing/VenuesBookedOwnerDashboard";
import VenueVerification from "./Files/VenueVerification/VenueVerification";
import ShowListAdmin from "./Files/ShowListAdmin";
import ShowListDetailsAdmin from "./Files/ShowListDetailsAdmin";
import ShowVenueDetails from "./Files/ShowVenueDetails";
import ContactUs from "./Files/Contactus/ContactUs";
import Faqpage from "./Files/FAQ/Faqpage";
import Withdrawalhistory from "./Files/Withdrawal/Withdrawalhistory";
import PrivacyPolicy from "./Files/Policies/PrivacyPolicy";
import TermsAndConditions from "./Files/Policies/TermsAndConditions";
import Success from "./Files/Success/Success";
import Bookingrequest from "./Files/Success/Bookingrequest";
import Index from "./Files/LatestLandingPage/Index";
import VenueRegistration from "./Files/VenueRegistration/VenueRegistration";
import AddCoupon from "./Files/Coupon/AddCoupon";
import CouponPage from "./Files/Coupon/CouponPage";
import StaffMembers from "./Files/StaffMembers/StaffMembers";
import FirstCampain from "./Files/Campain/FirstCampain";
import PageNotFound from "./Files/NotFound/PageNotFound";
import NewVenueListing from "./Files/VenueListing/NewVenueListing";
import VenueOwnerHome from "./Files/VenueOwnerDashBoard/VenueOwnerHome";
import RescheduleRequest from "./Files/Success/RescheduleRequest";
import { LatestLoginForm } from "./Files/LatestLandingPage/LoginFolder/LatestLoginForm";
import PackageListing from "./Files/PackagesComp/PackageListing";
import UserPackageListing from "./Files/PackagesComp/UserPackageListing";
import NearbyVenue from "./Files/NearbyVenue";

export const UserContext = createContext();
const App = () => {
  const [Auth, setAuth] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 300);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    let storedUserType = localStorage.getItem("UserType");
    if (!storedUserType) {
      localStorage.setItem("UserType", "Guest");
      storedUserType = "Guest";
    }
  }, []);

  return (
    <>
      <UserContext.Provider value={{ Auth, setAuth }}>
        <Router>
          <Layout>
            <Routes>
              <Route path="/home-venue-owner" element={<VenueOwnerHome />} />
              <Route
                path="/venue-owner-booking-details"
                element={<VenuesBookedOwnerDashboard />}
              />
              <Route
                path="/venue-booking-request/:bookingid"
                element={<Bookingrequest />}
              />
              <Route
                path="/venue-reschedule-request/:bookingid"
                element={<RescheduleRequest />}
              />
              <Route
                path="/venue-owner-venue-details"
                element={<ShowVenueDetails />}
              />
              <Route path="/venue-information" element={<VenueInformation />} />
              <Route
                path="/venue-registration"
                element={<VenueRegistration />}
              />
              <Route path="/user-profile" element={<Profile />} />
              <Route path="/all-listed-venues" element={<ShowListAdmin />} />
              <Route
                path="/admin-venue-details"
                element={<ShowListDetailsAdmin />}
              />
              <Route path="/home" element={<NewHomePage />} />
              <Route path="/add-coupon" element={<AddCoupon />} />
              <Route path="/edit-coupon" element={<AddCoupon />} />
              <Route path="/coupon-home" element={<CouponPage />} />
              <Route
                path="/withdrawal-history"
                element={<Withdrawalhistory />}
              />
              <Route
                path="/venue-verification"
                element={<VenueVerification />}
              />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/faq" element={<Faqpage />} />
              <Route path="/venue-search" element={<VenueSearch />} />
              <Route
                path="/venue-search/:cityName/sports/:sportName"
                element={<NewVenueListing />}
              />
              <Route
                path="/venue-search/:cityName/events/:sportName"
                element={<NewVenueListing />}
              />
              <Route path="/venue-search/:city/:id" element={<VenuePlace />} />
              <Route path="/" element={<Index />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/find-players" element={<Createownmatch />} />
              <Route path="/play-cricket" element={<UpcomingMatches />} />
              <Route path="/saved-venues" element={<SavedVenues />} />
              <Route
                path="/venue-booking-details"
                element={<VenueBookingList />}
              />
              <Route
                path="/user-booking-details"
                element={<VenueBookingDetails />}
              />
              <Route path="/savedqueries" element={<SavedQueries />} />
              <Route path="/paymentpage" element={<PaymentPage />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/created-matches" element={<CreatedMatches />} />
              <Route path="/joined-matches" element={<JoinedMatches />} />
              <Route path="/match-details" element={<MatchDetails />} />
              <Route path="/PaymentConfirmed" element={<PaymentConfirmed />} />
              <Route path="/match-join-request" element={<FriendRequest />} />
              <Route path="/LiveScoreCard" element={<LiveScoreCard />} />
              <Route path="/LiveScorepage" element={<LiveScorepage />} />
              <Route path="/ScoreCard" element={<ScoreCard />} />
              <Route path="/welcomeuser" element={<WelcomeUser />} />
              <Route path="/changeColor" element={<ChangeColor />} />
              <Route path="/referandearn" element={<InviteAndEarn />} />
              <Route path="/packages" element={<PackageListing />} />
              <Route path="/nearme" element={<NearbyVenue />} />
              <Route path="/mypackages" element={<UserPackageListing />} />
              <Route path="/start-match" element={<MatchScenario />} />
              <Route path="/payment/:orderid" element={<Success />} />
              <Route path="/staff-members" element={<StaffMembers />} />
              <Route path="/campaign" element={<FirstCampain />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/not-found" element={<PageNotFound />} />
            </Routes>
          </Layout>
          <Routes>
            <Route path="/admin-stats" element={<LatestLoginForm />} />
            <Route path="/welcome" element={<Welcomepage />} />
            <Route path="/login" element={<Loginpage />} />
            <Route path="/forgot-password" element={<ForgotpwdPage />} />
            <Route path="/registration" element={<Registration />} />
          </Routes>
        </Router>
      </UserContext.Provider>
    </>
  );
};

export default App;
