import React, { useEffect, useLayoutEffect, useState } from "react";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import { Modal } from "react-bootstrap";
import { Registration } from "../Registration";
import VerifyPhoneNumber from "../VerifyPhoneNumber";
import Loader from "../Utils/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import TimePicker from "react-bootstrap-time-picker";
import {
  compressImage,
  convertDecimalToTime,
  validateEmail,
} from "../Utils/common";
import Select from "react-select";

export const VenueInfo = ({
  state,
  dispatch,
  currentStep,
  setcurrentStep,
  location,
  stateid,
  setLocation,
  setFormValues,
  formValuesSportsFacilities,
  isFirstTime,
  setIsFirstTime,
  setEditFirst,
  isEditFirst,
  time,
  setTime,
  CityFilter,
  setCityFilter,
  multipleImages,
  setMultipleImages,
  listData,
  setSlotDuration,
  slotDuration,
}) => {
  const [showLogin, setshowLogin] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setshowWarning] = useState(false);
  const [deletedimages, setdeletedimages] = useState([]);
  const [geoData, setGeoData] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [startTime, setStartTime] = useState(); // State for start time
  const [endTime, setEndTime] = useState();
  const [zipCodeLength, setZipCodeLength] = useState(0);
  // useLayoutEffect(() => {
  //   if (state?.StartTime && state?.EndTime) {
  //     setFormValues((prevFormValues) => {
  //       const updatedValues = [...prevFormValues];
  //       updatedValues[0].MonFri[0].startTime = state.StartTime;
  //       updatedValues[0].MonFri[0].endTime = state.EndTime;
  //       updatedValues[0].SatSun[0].startTime = state.StartTime;
  //       updatedValues[0].SatSun[0].endTime = state.EndTime;
  //       return updatedValues;
  //     });
  //   }
  // }, [state?.StartTime, state?.EndTime, setFormValues]);

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setZipCodeLength(state?.ZipCode?.toString()?.length);
  }, [state?.ZipCode]);

  useEffect(() => {
    if (stateid === 0) {
      const resetSportsArray = formValuesSportsFacilities.map((facility) => {
        return {
          ...facility,
          Sports: [],
        };
      });
      setFormValues(resetSportsArray);
    }
  }, [state.category]);
  useLayoutEffect(() => {
    if (geoData.length != 0 && state.ZipCode.length === 6) {
      validateZipCode(state.ZipCode);
    }
  }, [geoData]);

  function convertSecondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    const timeString = `${formattedHours}:${formattedMinutes}`;

    return timeString;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "CHANGE_INPUT",
      payload: {
        name: name,
        value: name === "VenueName" ? value : value,
      },
    });
    isFirstTime === "0" ? setIsFirstTime("0") : setIsFirstTime("2");
  };

  const handleStartTimeChange = (newTime) => {
    let newTimes = convertSecondsToTime(newTime);
    setStartTime(newTimes);
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: "StartTime", value: newTimes },
    });
    isFirstTime === "0" ? setIsFirstTime("0") : setIsFirstTime("2");
    setFormValues((prevFormValues) => {
      const updatedValues = [...prevFormValues];
      for (let i = 0; i < updatedValues.length; i++) {
        // Assign listData values to MonFri and SatSun properties
        updatedValues[i].MonFri = listData.MonFri;
        updatedValues[i].SatSun = listData.SatSun;

        // Assign new values to MonFri and SatSun properties
        updatedValues[i].MonFri = [
          { startTime: newTimes, endTime: state?.EndTime, amount: "" },
        ];
        updatedValues[i].SatSun = [
          { startTime: newTimes, endTime: state?.EndTime, amount: "" },
        ];
      }
      return updatedValues;
    });
  };

  const handleEndTimeChange = (newTime) => {
    setEndTime(convertSecondsToTime(newTime));
    let newTimes = convertSecondsToTime(newTime);
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: "EndTime", value: convertSecondsToTime(newTime) },
    });
    isFirstTime === "0" ? setIsFirstTime("0") : setIsFirstTime("2");
    setFormValues((prevFormValues) => {
      const updatedValues = [...prevFormValues];
      for (let i = 0; i < updatedValues.length; i++) {
        // Assign listData values to MonFri and SatSun properties
        updatedValues[i].MonFri = listData.MonFri;
        updatedValues[i].SatSun = listData.SatSun;

        // Assign new values to MonFri and SatSun properties
        updatedValues[i].MonFri = [
          { startTime: state?.StartTime, endTime: newTimes, amount: "" },
        ];
        updatedValues[i].SatSun = [
          { startTime: state?.StartTime, endTime: newTimes, amount: "" },
        ];
      }
      return updatedValues;
    });
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    if (value === "24Hours") {
      state.StartTime = "00:00";
      state.EndTime = "23:59";
      setTime({
        isCustomHours: false,
      });
    } else if (value === "CustomHours") {
      setTime({
        ...time,
        isCustomHours: true,
      });
    }
    isFirstTime === "0" ? setIsFirstTime("0") : setIsFirstTime("2");
  };

  const handlePhoneNumber = (e) => {
    if (e.target.value.length > 10) {
      return;
    }
    handleChange(e);
    validatePhoneNumber(e.target.value);
  };

  const handleCancelPolicy = (e) => {
    handleChange(e);
    validateCancelPolicy(e.target.value);
  };
  const handleRescheduleHours = (e) => {
    handleChange(e);
    validateRescheduleHours(e.target.value);
  };
  const handleRescheduleCount = (e) => {
    handleChange(e);
    validateRescheduleCount(e.target.value);
  };
  const handleZipCode = async (e) => {
    setGeoData([]);
    setAreaOptions([]);
    dispatch({
      type: "CHANGE_INPUT",
      payload: {
        name: "Address2",
        value: "",
      },
    });
    handleChange(e);
    if (e.target.value.length === 6) {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            e.target.value
          )}&key=AIzaSyBeR6pBza9LE_fFj5akK8dbfWxiST2fk38`
        );

        if (response.ok) {
          const data = await response.json();
          if (data?.results?.length === 0) {
            dispatch({
              type: "SET_ERROR",
              payload: {
                name: "ZipCode",
                message: "*Invalid Zip Code",
              },
            });
          }
          setGeoData(data?.results);
          let options = [];
          if (data?.results[0]?.postcode_localities != undefined) {
            options = data?.results[0]?.postcode_localities?.map(
              (locality) => ({
                value: locality,
                label: locality,
              })
            );
            const addressPart = data?.results[0]?.formatted_address?.split(",");
            const state = addressPart[addressPart.length - 2];
            const city = addressPart[addressPart.length - 3];
            dispatch({
              type: "CHANGE_INPUT",
              payload: {
                name: "stateid",
                value: state?.split(" ")[1],
              },
            });
            dispatch({
              type: "CHANGE_INPUT",
              payload: {
                name: "cityid",
                value: city,
              },
            });
          } else {
            dispatch({
              type: "CHANGE_INPUT",
              payload: {
                name: "Address2",
                value: "",
              },
            });
            dispatch({
              type: "CHANGE_INPUT",
              payload: {
                name: "cityid",
                value: "",
              },
            });
            dispatch({
              type: "CHANGE_INPUT",
              payload: {
                name: "stateid",
                value: "",
              },
            });
          }

          setAreaOptions(options);
        }
      } catch (error) {}
    } else {
      validateZipCode(e.target.value);
    }
  };

  useEffect(async () => {
    if (stateid != "0" || state?.ZipCode.length === 6) {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            state?.ZipCode
          )}&key=AIzaSyBeR6pBza9LE_fFj5akK8dbfWxiST2fk38`
        );

        if (response.ok) {
          const data = await response.json();
          if (data?.results?.length === 0) {
            dispatch({
              type: "SET_ERROR",
              payload: {
                name: "ZipCode",
                message: "*Invalid Zip Code",
              },
            });
          }
          setGeoData(data?.results);
          let options = [];
          if (data?.results[0]?.postcode_localities != undefined) {
            options = data?.results[0]?.postcode_localities?.map(
              (locality) => ({
                value: locality,
                label: locality,
              })
            );
          }

          setAreaOptions(options);
        }
      } catch (ex) {}
    }
  }, [stateid, state?.ZipCode]);
  const handleAddress1 = (e) => {
    handleChange(e);
    validateAddress1(e.target.value);
  };

  const handleAddress2 = (e) => {
    handleChange(e);
    validateAddress2(e.target.value);
  };
  const [selectedArea, setSelectedArea] = useState();
  useEffect(() => {
    const selected = areaOptions?.find((area) => area.value === state.Address2);
    if (selected) {
      setSelectedArea(selected);
    } else {
      setSelectedArea("");
    }
  }, [state.Address2, areaOptions]);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Phone",
          message: "*Please Enter 10 digit Number",
        },
      });
      return false;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Phone",
          message: "",
        },
      });
      return true;
    }
  };

  const ValidateEmail = (e) => {
    handleChange(e);
    const { name, value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value) && value) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: name,
          message: "*Invalid email format",
        },
      });
      return false;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: name,
          message: "",
        },
      });
      return true;
    }
  };

  const validateAddress1 = (address1) => {
    if (address1.length > 400) {
      // console.log("Address length exceeds 400 characters");
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Address1",
          message: "*Street Address cannot be more than 400 characters",
        },
      });
      return false;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Address1",
          message: "",
        },
      });
      return true;
    }
  };

  const validateAddress2 = (address2) => {
    if (address2.length > 100) {
      // console.log("Address length exceeds 400 characters");
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Address2",
          message: "*Area/Locality cannot be more than 100 characters",
        },
      });
      return false;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Address2",
          message: "",
        },
      });
      return true;
    }
  };

  const validateZipCode = (zip) => {
    const zipRegex = /^\d{6}$/;
    if (!zipRegex.test(zip)) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "ZipCode",
          message: "*Please Enter 6 digit Zip Code",
        },
      });
      return false;
    } else if (
      !geoData ||
      geoData.length === 0 ||
      geoData[0]?.postcode_localities === undefined
    ) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "ZipCode",
          message: "*Invalid Zip Code",
        },
      });
      return false;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "ZipCode",
          message: "",
        },
      });
      return true;
    }
  };

  const validateCancelPolicy = (cancel) => {
    if (cancel >= 0 && cancel <= 72) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Cancelpolicy",
          message: "",
        },
      });
      return true;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Cancelpolicy",
          message: "Hours valid between 0 to 72",
        },
      });
      return false;
    }
  };
  const validateRescheduleHours = (cancel) => {
    if (cancel >= 0 && cancel <= 72) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "RescheduleHours",
          message: "",
        },
      });
      return true;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "RescheduleHours",
          message: "Hours valid between 0 to 72",
        },
      });
      return false;
    }
  };
  const validateRescheduleCount = (cancel) => {
    if (cancel >= 0 && cancel <= 3) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "RescheduleCount",
          message: "",
        },
      });
      return true;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "RescheduleCount",
          message: "Count valid between 0 to 3",
        },
      });
      return false;
    }
  };

  var deleteimgarray = [];

  function removeMultipleImages(indexToRemove, id) {
    isFirstTime === "0" ? setIsFirstTime("0") : setIsFirstTime("2");
    if (id != 0) {
      let ob = new Object();
      ob.Id = id;
      setdeletedimages((prev) => [...prev, ob]);
    }
    const updatedImages = [...multipleImages];
    URL.revokeObjectURL(updatedImages[indexToRemove]);
    updatedImages.splice(indexToRemove, 1);
    setMultipleImages(updatedImages);
    dispatch({
      type: "CHANGE_ARRAY",
      payload: { name: "venueimage", value: updatedImages },
    });
  }

  async function handleMultipleImagesUpload(e) {
    isFirstTime === "0" ? setIsFirstTime("0") : setIsFirstTime("2");
    handleChange(e);
    const files = e.target.files;
    let list = [];
    let errors = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split(".").pop().toLowerCase();
      if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
        errors.push(
          `Invalid file type for ${file.name}. Only JPG, JPEG, and PNG files are allowed.`
        );
        continue;
      }
      let compressedFile = await compressImage(file);

      let base64 = await toBase64(compressedFile);
      let ob = {
        image: base64,
        id: 0,
      };
      list.push(ob);
    }
    const venueImageErrorElement = document.querySelector(".venueimageerror");
    if (errors.length > 0) {
      venueImageErrorElement.textContent =
        "*Only png,jpg,jpeg extension are supported!";
    } else {
      venueImageErrorElement.textContent = "";
    }
    // Add valid images to state
    setMultipleImages((prevImages) => [...prevImages, ...list]);
    // Dispatch action to update state if needed
    dispatch({
      type: "CHANGE_ARRAY",
      payload: { name: "venueimage", value: [...multipleImages, ...list] },
    });
  }

  useEffect(() => {
    if (state?.stateid) {
      // Filter cities based on the selected state ID
      var CityFilter = location?.stateName?.filter(
        (item) => item?.stateId === parseInt(state?.stateid)
      )[0]?.cities;

      // Update the state with the filtered cities
      setLocation({
        ...location,
        cityName: CityFilter,
      });
    }
  }, [location.stateName, state?.stateid]);

  const handleStateChange = (e) => {
    // setLocation({ ...location, stateName: e.target.value })
    handleChange(e);

    if (e.target.value != "0") {
      var CityFilter = location?.stateName?.filter(
        (item) => item?.stateId === parseInt(e.target.value)
      )[0]?.cities;
    }
    setLocation({
      ...location,
      cityName: CityFilter,
    });
  };

  const handleCityChange = (e) => {
    // setLocation({ ...location, cityName: e.target.value })
    handleChange(e);
  };

  const handleSubmit = async () => {
    const requiredFields = [
      { field: "VenueName" },
      { field: "venueimage" },
      { field: "DescText" },
      { field: "MinSlotTime" },
      { field: "StartTime" },
      { field: "EndTime" },
      // { field: "Email" },
      { field: "Cancelpolicy" },
      { field: "RescheduleHours" },
      { field: "RescheduleCount" },
      { field: "Phone" },
      { field: "Address1" },
      { field: "Address2" },
      { field: "stateid" },
      { field: "cityid" },
      { field: "ZipCode" },
    ];

    let errorFields = [];

    requiredFields.forEach(({ field }) => {
      if (field === "venueimage" && state[field] === "") {
        dispatch({
          type: "SET_ERROR",
          payload: {
            name: field,
            message: "* This field is Required!",
          },
        });

        errorFields.push(field);
      } else if (
        (field === "stateid" || field === "cityid") &&
        state[field] === "0"
      ) {
        dispatch({
          type: "SET_ERROR",
          payload: {
            name: field,
            message: "* This field is Required!",
          },
        });

        errorFields.push(field);
      } else if (
        (field === "RescheduleHours" || field === "RescheduleCount") &&
        state[field] === ""
      ) {
        dispatch({
          type: "SET_ERROR",
          payload: {
            name: field,
            message: "* This field is Required!",
          },
        });

        errorFields.push(field);
      } else if (
        !state[field] &&
        field !== "RescheduleHours" &&
        field !== "RescheduleCount"
      ) {
        dispatch({
          type: "SET_ERROR",
          payload: {
            name: field,
            message: "* This field is Required!",
          },
        });

        errorFields.push(field);
      }
    });

    if (errorFields.length > 0) {
      const elements = document.getElementsByName(errorFields[0]);

      if (elements.length > 0) {
        elements[0].focus();
        window.scrollTo(0, 0);
      }
      setIsLoading(false);
      return;
    }
    const emailIsValid = validateEmail(state.Email);

    if (!emailIsValid) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Email",
          message: "*Invalid email format",
        },
      });
      const elements = document.getElementsByName("Email");
      if (elements.length > 0) {
        elements[0].focus();
      }
      return;
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: {
          name: "Email",
          message: "",
        },
      });
    }

    const phoneIsValid = validatePhoneNumber(state.Phone);

    if (!phoneIsValid) {
      const elements = document.getElementsByName("Phone");
      if (elements.length > 0) {
        elements[0].focus();
      }
      return;
    }

    const zipCodeIsValid = validateZipCode(state.ZipCode);

    if (!zipCodeIsValid) {
      const elements = document.getElementsByName("ZipCode");
      if (elements.length > 0) {
        elements[0].focus();
      }
      return;
    }

    const address1IsValid = validateAddress1(state.Address1);

    if (!address1IsValid) {
      const elements = document.getElementsByName("Address1");
      if (elements.length > 0) {
        elements[0].focus();
      }
      return;
    }

    const address2IsValid = validateAddress1(state.Address2);

    if (!address2IsValid) {
      const elements = document.getElementsByName("Address2");
      if (elements.length > 0) {
        elements[0].focus();
      }
      return;
    }

    const isCancelPolicyValid = validateCancelPolicy(state.Cancelpolicy);
    const isRescheduleHoursValid = validateRescheduleHours(
      state.RescheduleHours
    );
    const isRescheduleCountValid = validateRescheduleCount(
      state.RescheduleCount
    );
    if (!isCancelPolicyValid) {
      const elements = document.getElementsByName("Cancelpolicy");
      if (elements.length > 0) {
        elements[0].focus();
      }
      return;
    }
    if (!isRescheduleHoursValid) {
      const elements = document.getElementsByName("RescheduleHours");
      if (elements.length > 0) {
        elements[0].focus();
      }
      return;
    }
    if (!isRescheduleCountValid) {
      const elements = document.getElementsByName("RescheduleCount");
      if (elements.length > 0) {
        elements[0].focus();
      }
      return;
    }
    setIsLoading(true);
    setshowWarning(false);
    if (isFirstTime === "0" && multipleImages.length > 0) {
      var raw = JSON.stringify({
        VenueName: state.VenueName,
        Addressline1: state.Address1,
        Addressline2: state.Address2,
        Addressline3: state.Address3,
        zipcode: state.ZipCode,
        description: state.DescText,
        cityid: state.cityid,
        stateid: state.stateid,
        CancellationTime: state?.Cancelpolicy,
        Phone: state.Phone,
        Email: state.Email,
        StartTime: state.StartTime,
        EndTime: state.EndTime,
        Venuelogo: "",
        venueimage: multipleImages,
        Sports: state.Sports,
        VenueAmenities: state.VenueAmenities,
        stage: 1,
        deletedimages: null,
        RescheduleCount: state.RescheduleCount,
        RescheduleHours: state.RescheduleHours,
        category: state.category,
        MinSlotTime: Math.round(slotDuration * 60),
      });

      await CustomPostAPI("/Venue/Createvenue", raw).then((result) => {
        if (result === undefined || result === "") {
          // alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          // console.log(result)
          if (result.status === "Success") {
            setIsLoading(false);

            setMultipleImages(result?.venueimg);
            formValuesSportsFacilities[0].VenueId = result.venueid;

            var isLoggedIn = window.localStorage.getItem("UserMobileNo");
            if (isLoggedIn === null || isLoggedIn === "") {
              setshowLogin(true);
            } else {
              // setIsLoading(false)
              setIsFirstTime("1");
              setcurrentStep(2);
            }
          } else {
            // alert(result.message);
          }
        }
      });
    } else if (isFirstTime === "2") {
      var raw = JSON.stringify({
        VenueName: state.VenueName,
        Addressline1: state.Address1,
        Addressline2: state.Address2,
        Addressline3: state.Address3,
        zipcode: state.ZipCode,
        description: state.DescText,
        cityid: state.cityid,
        stateid: state.stateid,
        CancellationTime: state?.Cancelpolicy,
        Phone: state.Phone,
        Email: state.Email,
        StartTime: state.StartTime,
        EndTime: state.EndTime,
        Venuelogo: "",
        venueimage: multipleImages,
        Sports: state.Sports,
        VenueAmenities: state.VenueAmenities,
        stage: 1,
        VenueId: formValuesSportsFacilities[0].VenueId,
        deletedimages: deletedimages,
        RescheduleCount: state.RescheduleCount,
        RescheduleHours: state.RescheduleHours,
        category: state.category,
        MinSlotTime: Math.round(slotDuration * 60),
      });

      await CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
        if (result === undefined || result === "") {
          // alert("Something went wrong");
        } else {
          result = JSON.parse(result);

          setMultipleImages(result?.venueimg);
          setIsLoading(false);
          // formValuesSportsFacilities[0].VenueId = result.venueid;
          setIsFirstTime("1");
          setcurrentStep(2);
        }
      });
    } else {
      setIsFirstTime("1");
      setcurrentStep(2);
      setIsLoading(false);
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  async function ImageBase64(multipleImages) {
    try {
      const venueImages = await Promise.all(
        multipleImages.map((file, index) => {
          return new Promise((resolve, reject) => {
            if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = reject;
              // reader.onload = (event) => {
              //   resolve({
              //     ["Image"]: event.target.result,
              //   });
              // };

              // reader.onerror = (error) => {
              //   reject(error);
              // };

              // reader.readAsDataURL(file);
            } else {
              resolve({
                ["Image"]: "",
              });
            }
          });
        })
      );

      return venueImages; // Return the venueImages array
    } catch (error) {
      // console.error("Error processing images:", error);
      throw error; // Rethrow the error if needed
    }
  }
  var BucketS3Link = process.env.REACT_APP_Base_URL;

  return (
    <React.Fragment>
      <>
        {isLoading ? (
          <Loader mt="30%" />
        ) : (
          <div className="row px-2">
            <div className="col-12 venueInformationHeader">
              Venue Information
            </div>
            <div className="col-12 venueInformationBody mt-2">
              <div className="row">
                <div className="col-6">
                  <span className="venueText">Venue Name*</span>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    name="VenueName"
                    value={state?.VenueName}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    autoComplete="off"
                    placeholder="Name of venue"
                  />
                  <small className="text-danger">
                    {state.errors.VenueName}
                  </small>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <span className="venueText">
                    Is this venue for Sports or Events?*
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-4 col-4 my-1">
                    <input
                      type="radio"
                      name="category"
                      value="sports"
                      id="sports"
                      className="form-check-input me-1 "
                      onChange={(e) => handleChange(e)}
                      checked={state?.category === "sports"}
                      disabled={stateid != 0}
                    />
                    <label htmlFor="sports" className="venueText">
                      Sports
                    </label>
                  </div>
                  <div className="col-md-4 col-6 my-1">
                    <input
                      type="radio"
                      name="category"
                      value="events"
                      id="events"
                      className="form-check-input me-1 "
                      onChange={(e) => handleChange(e)}
                      checked={state?.category === "events"}
                      disabled={stateid != 0}
                    />
                    <label htmlFor="events" className="venueText">
                      Events
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-3 col-6">
                  <span className="venueText">Photos of the Venue*</span>
                </div>
                <div className="col-6">
                  <label htmlFor="filesInput">
                    <img
                      src="/images/Add.svg"
                      alt="logo"
                      style={{ cursor: "pointer", height: "20px" }}
                    />
                  </label>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  {multipleImages?.length > 0 && (
                    <>
                      {multipleImages?.map((file, index) => (
                        <div className="col-4">
                          <div
                            key={index}
                            style={{ margin: "5px", position: "relative" }}
                          >
                            <img
                              className="img-fluid"
                              key={index}
                              src={file?.id === 0 ? file?.image : file?.image}
                              // src={file.Img}
                              alt="preview"
                              style={{
                                marginRight: "3px",
                                borderRadius: "5px",
                              }}
                            />
                            <img
                              src="/images/Closeicon.svg"
                              alt="close"
                              id={file?.id}
                              style={{
                                position: "absolute",
                                top: "1px",
                                right: "1px",
                                cursor: "pointer",
                                borderRadius: "13px",
                                background: "white",
                                padding: "2px",
                              }}
                              onClick={() =>
                                removeMultipleImages(index, file?.id)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <div className="col-4">
                    <input
                      type="file"
                      id="filesInput"
                      style={{ display: "none" }}
                      onChange={(e) => handleMultipleImagesUpload(e)}
                      name="venueimage"
                      multiple
                      accept=".jpg, .jpeg, .png"
                    />
                  </div>
                  <small className="text-danger">
                    {state.errors.venueimage}
                  </small>
                  <small className="text-danger venueimageerror"></small>
                </div>
              </div>
              {/* </div> */}
              <hr />
              <div className="row">
                <div className="col-12">
                  <span className="venueText">
                    Description/About the Venue*
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-2">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    name="DescText"
                    value={state?.DescText}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    autoComplete="off"
                    placeholder="About venue"
                  ></textarea>
                  <small className="text-danger">{state.errors.DescText}</small>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-6">
                  <span className="venueText">Operating Hours</span>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-md-4 my-1">
                      <input
                        type="radio"
                        name="hours"
                        value="24Hours"
                        id="24Hours"
                        className="form-check-input me-1 "
                        onChange={handleRadioChange}
                        checked={!time.isCustomHours}
                      />
                      <label htmlFor="24Hours" className="venueText">
                        24 Hours
                      </label>
                    </div>
                    <div className="col-md-6 my-1">
                      <input
                        type="radio"
                        name="hours"
                        value="CustomHours"
                        id="CustomHours"
                        className="form-check-input me-1 "
                        onChange={handleRadioChange}
                        checked={time.isCustomHours}
                      />
                      <label htmlFor="CustomHours" className="venueText">
                        Specific Hours
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-6">
                  <label
                    className="form-check-label  venueText"
                    htmlFor="customHours1"
                  >
                    Open Time*
                  </label>
                </div>
                <div className="col-6">
                  {/* <input
                    type="time"
                    className="form-control"
                    id="start-time"
                    name="StartTime"
                    value={state?.StartTime}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    disabled={!time.isCustomHours}
                  /> */}
                  <TimePicker
                    format={12}
                    start="00:00"
                    end="23:59"
                    step={60}
                    className="form-control"
                    name="StartTime"
                    value={state?.StartTime}
                    onChange={handleStartTimeChange}
                    disabled={!time.isCustomHours}
                  />
                  <small className="text-danger">
                    {state.errors.StartTime}
                  </small>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label
                    className="form-check-label  venueText"
                    htmlFor="customHours2"
                  >
                    Close Time*
                  </label>
                </div>
                <div className="col-6">
                  {/* <input
                    type="time"
                    className="form-control"
                    id="end-time"
                    name="EndTime"
                    value={state?.EndTime}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    disabled={!time.isCustomHours}
                  /> */}
                  <TimePicker
                    format={12}
                    start="00:00"
                    end="23:59"
                    step={60}
                    className="form-control"
                    name="EndTime"
                    value={state?.EndTime}
                    onChange={handleEndTimeChange}
                    disabled={!time.isCustomHours}
                  />
                  <small className="text-danger">{state.errors.EndTime}</small>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 col-md-6">
                  <label className="venueText">Minimum Booking Time*</label>
                </div>

                <div className="col-8 col-md-6 mt-2 mt-md-0">
                  <div className="row align-items-center">
                    <div className="col-2 pe-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        class={`cursor-pointer br-50 ${
                          slotDuration === 0.5
                            ? "DisableDurationBtn"
                            : "AddDurationBtn"
                        }`}
                        onClick={(e) => {
                          if (slotDuration != 0.5) {
                            setSlotDuration(
                              (prevDuration) => prevDuration - 0.5
                            );

                            isFirstTime === "0"
                              ? setIsFirstTime("0")
                              : setIsFirstTime("2");
                          }
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>

                    <div className="col-8 p-0 text-center">
                      {convertDecimalToTime(slotDuration)}
                    </div>

                    <div className="col-2 ps-0 text-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        class={`${
                          slotDuration === 5
                            ? "DisableDurationBtn"
                            : "AddDurationBtn"
                        } cursor-pointer br-50`}
                        onClick={(e) => {
                          if (slotDuration < 5) {
                            setSlotDuration(
                              (prevDuration) => prevDuration + 0.5
                            );

                            isFirstTime === "0"
                              ? setIsFirstTime("0")
                              : setIsFirstTime("2");
                          }
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-6">
                  <span className="venueText">
                    Cancellation Policy* <br />
                    <small style={{ fontSize: "10px" }}>
                      ( How many hours in prior User can cancel the booking? )
                    </small>
                  </span>
                </div>
                <div className="col-6">
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    placeholder="Cancellation time"
                    name="Cancelpolicy"
                    value={state?.Cancelpolicy}
                    onChange={handleCancelPolicy}
                    max="99"
                    inputMode="numeric"
                    autoComplete="off"
                  />

                  <small className="text-danger">
                    {state.errors.Cancelpolicy}
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <span className="venueText">
                    Reschedule Hours* <br />
                    <small style={{ fontSize: "10px" }}>
                      ( How many hours in prior User can reschedule the booking?
                      )
                    </small>
                  </span>
                </div>
                <div className="col-6">
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    placeholder="Reschedule Hours"
                    name="RescheduleHours"
                    value={state?.RescheduleHours}
                    onChange={handleRescheduleHours}
                    max="99"
                    inputMode="numeric"
                    autoComplete="off"
                  />

                  <small className="text-danger">
                    {state.errors.RescheduleHours}
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <span className="venueText">
                    Reschedule Count* <br />
                    <small style={{ fontSize: "10px" }}>
                      ( How many time User can reschedule the booking? )
                    </small>
                  </span>
                </div>
                <div className="col-6">
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    placeholder="Reschedule Count"
                    name="RescheduleCount"
                    value={state?.RescheduleCount}
                    onChange={handleRescheduleCount}
                    max="99"
                    inputMode="numeric"
                    autoComplete="off"
                  />

                  <small className="text-danger">
                    {state.errors.RescheduleCount}
                  </small>
                </div>
              </div>
              <hr />
              <span className="venueText">Contact Details</span>

              <div className="row mt-3">
                <div className="col-6">
                  <span className="contactInfo">Email</span>
                </div>
                <div className="col-6">
                  <input
                    type="email"
                    className="form-control"
                    name="Email"
                    value={state?.Email}
                    // onBlur={handleEmailChange}
                    onChange={ValidateEmail}
                    autoComplete="off"
                    placeholder="Email"
                  />
                  <small className="text-danger">{state.errors.Email}</small>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <span className="contactInfo">Phone Number*</span>
                  <br />
                  <small style={{ fontSize: "10px" }}>
                    ( Please use WhatsApp number )
                  </small>
                </div>
                <div className="col-6">
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    name="Phone"
                    value={state?.Phone}
                    onChange={(e) => handlePhoneNumber(e)}
                    maxLength={10}
                    placeholder="Phone number"
                  />
                  <small className="text-danger">{state.errors.Phone}</small>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <span className="venueText">Location</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="contactInfo">
                    Street Address*{" "}
                    <small style={{ fontSize: "10px" }}>
                      ( Please give precise address as it will be used to
                      provide directions )
                    </small>
                  </span>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    name="Address1"
                    value={state?.Address1}
                    onChange={handleAddress1}
                    autoComplete="off"
                    placeholder="Street address"
                  />
                  <small className="text-danger">{state.errors.Address1}</small>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <span className="contactInfo">Landmark</span>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    name="Address3"
                    value={state?.Address3}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    autoComplete="off"
                    placeholder="Near by landmark(optional)"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <span className="contactInfo py-4">Zip Code*</span>
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    name="ZipCode"
                    value={state?.ZipCode}
                    onChange={handleZipCode}
                    placeholder="Pin code"
                  />
                  <small className="text-danger">{state.errors.ZipCode}</small>
                </div>

                <div className="col-6 ">
                  <span className="contactInfo">Area/Locality*</span>
                  <Select
                    name="Address2"
                    id="Address2"
                    value={selectedArea}
                    isSearchable="true"
                    options={areaOptions}
                    menuPlacement="auto"
                    isDisabled={areaOptions?.length === 0}
                    onChange={(selectedOption) => {
                      dispatch({
                        type: "CHANGE_INPUT",
                        payload: {
                          name: "Address2",
                          value: selectedOption.label,
                        },
                      });
                      isFirstTime === "0"
                        ? setIsFirstTime("0")
                        : setIsFirstTime("2");
                    }}
                  />
                  <small className="text-danger">{state.errors.Address2}</small>
                </div>
                <div className="col-6 mt-2">
                  <span
                    className="contactInfo py-4"
                    style={{ fontSize: "12px" }}
                  >
                    City*
                  </span>
                  <input
                    className="form-control"
                    aria-label="Select City"
                    name="cityid"
                    value={state?.cityid}
                    placeholder="City name"
                    onChange={handleCityChange}
                    type="text"
                    disabled={
                      areaOptions?.length === 0 || state?.Address2 === ""
                    }
                  />
                  <small className="text-danger">{state.errors.cityid}</small>
                </div>

                <div className="col-6 mt-2">
                  <span className="contactInfo py-4">State*</span>
                  <input
                    className="form-control"
                    aria-label="Select State"
                    name="stateid"
                    value={state?.stateid}
                    placeholder="State name"
                    // onChange={handleStateChange}
                    disabled
                  />
                  <small className="text-danger">{state.errors.stateid}</small>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-6">
                  <span className="contactInfo py-4">Zip Code*</span>
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control"
                    name="ZipCode"
                    value={state?.ZipCode}
                    onChange={handleZipCode}
                  />
                  <small className="text-danger">{state.errors.ZipCode}</small>
                </div>
              </div> */}
              <div className="row mt-3 d-flex justify-content-center">
                <div className="col-3">
                  <button
                    onClick={handleSubmit}
                    // onClick={
                    //   isFirstTime === "2" && stateid != 0
                    //     ? () => setshowWarning(true)
                    //     : handleSubmit
                    // }
                    className="btn btn-danger"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          show={showLogin}
          onHide={() => setshowLogin(false)}
          // backdrop="static"
        >
          {/* <Modal.Header closeButton onClick={() => setshowLogin(false)}>
                  <Modal.Title className="colorClass">Confirm Phone Number</Modal.Title>
                </Modal.Header> */}

          <Modal.Body>
            <img
              src="/images/Closeicon.svg"
              className="closeicon"
              alt=""
              onClick={() => setshowLogin(false)}
            />
            <VerifyPhoneNumber
              phonenumber={state.Phone}
              setshowLogin={setshowLogin}
              setIsFirstTime={setIsFirstTime}
              setcurrentStep={setcurrentStep}
              isfromvenueowner={true}
              isActive={0}
            />
          </Modal.Body>

          {/* <Modal.Footer>
                  <small className="d-none bookederror text-center text-danger w-100"></small>
                  <button
                   
                    className="venueListingTop booking w-100"
                  >
                   Verify
                  </button>
                </Modal.Footer> */}
        </Modal>
        <SweetAlert
          show={showWarning}
          warning
          showCancel
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          title="Edit details?"
          onConfirm={handleSubmit}
          onCancel={() => {
            setshowWarning(false);
            window.location.reload();
          }}
          focusCancelBtn
        >
          Your venue will be delisted and sent for reverification!
        </SweetAlert>
      </>
    </React.Fragment>
  );
};
export default VenueInfo;
