import React, { useEffect, useRef, useState } from "react";
import Loader from "../Utils/Loader";
import { CustomPostAPI } from "../Utils/APIConnector";
import VenueOwnerDashbooard from "./VenueOwnerDashbooard";
import VenueOwnerDashbooardBooking from "./VenueOwnerDashbooardBooking";
import StaffMembers from "../StaffMembers/StaffMembers";
import VenueDashboardEditDetails from "./VenueDashboardEditDetails";
import PackagesDetails from "./PackagesDetails";

export default function VenueOwnerDashboard({
  selectedVenue,
  setIsLoading,
  isLoading,
  venueDetails,
  setVenueDetails,
  setCalendarCourtId,
  activeCalendarID,
  dateid,
  setDateid,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  activeCourtID,
  showoffline,
  isMainPage,
  setIsMainPage,
}) {
  const [activeLink, setActiveLink] = useState("dashboard");
  const targetRef = useRef(null);

  const handleNavClick = (link, shouldScroll) => {
    setActiveLink(link);
    if (link === "booking" && shouldScroll) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between d-flex align-items-center p-3 dashboardHeaderBg">
        <div
          className={`d-flex align-items-center cursor-pointer ${
            activeLink === "dashboard" ? "venueOwnerNavActive" : ""
          }`}
          onClick={() => {
            handleNavClick("dashboard");
            setIsMainPage(true);
          }}
        >
          <img
            src="/images/dashboard-icon.svg"
            className={`${activeLink === "dashboard" ? "checked-img" : ""}`}
            style={{ height: "23px" }}
          />
          {activeLink === "dashboard" && (
            <span className="Montserrat fw-semibold fs-14 ms-2">Dashboard</span>
          )}
        </div>
        <div
          className={`d-flex align-items-center cursor-pointer ${
            activeLink === "booking" ? "venueOwnerNavActive" : ""
          }`}
          onClick={() => {
            handleNavClick("booking", false);
            setIsMainPage(true);
          }}
        >
          <img
            src="/images/bookings-icon.svg"
            className={`${activeLink === "booking" ? "checked-img" : ""}`}
            style={{ height: "23px" }}
          />
          {activeLink === "booking" && (
            <span className="Montserrat fw-semibold fs-14 ms-2">Bookings</span>
          )}
        </div>
        <div
          className={`d-flex align-items-center cursor-pointer ${
            activeLink === "staff" ? "venueOwnerNavActive" : ""
          }`}
          onClick={() => {
            handleNavClick("staff");
            setIsMainPage(true);
          }}
        >
          <img
            src="/images/staff-icon.svg"
            className={`${activeLink === "staff" ? "checked-img" : ""}`}
            style={{ height: "23px" }}
          />
          {activeLink === "staff" && (
            <span className="Montserrat fw-semibold fs-14 ms-2">Staff</span>
          )}
        </div>
        <div
          className={`d-flex align-items-center cursor-pointer ${
            activeLink === "package" ? "venueOwnerNavActive" : ""
          }`}
          onClick={() => {
            handleNavClick("package");
            setIsMainPage(true);
          }}
        >
          <img
            src="/images/packageicon.svg"
            className={`${activeLink === "package" ? "checked-img" : ""}`}
            style={{ height: "23px" }}
          />
          {activeLink === "package" && (
            <span className="Montserrat fw-semibold fs-14 ms-2">Package</span>
          )}
        </div>
        <div
          className={`d-flex align-items-center cursor-pointer ${
            activeLink === "settings" ? "venueOwnerNavActive" : ""
          }`}
          onClick={() => {
            handleNavClick("settings");
            setIsMainPage(true);
          }}
        >
          <img
            src="/images/settings-icon.svg"
            className={`${activeLink === "settings" ? "checked-img" : ""}`}
            style={{ height: "23px" }}
          />
          {activeLink === "settings" && (
            <span className="Montserrat fw-semibold fs-14 ms-2">Settings</span>
          )}
        </div>
      </div>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          {activeLink === "dashboard" && (
            <VenueOwnerDashbooard
              dateid={dateid}
              setDateid={setDateid}
              venueDetails={venueDetails}
              setVenueDetails={setVenueDetails}
              selectedVenue={selectedVenue}
              setActiveLink={setActiveLink}
              activeCalendarID={activeCalendarID}
              setCalendarCourtId={setCalendarCourtId}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              startDate={startDate}
              endDate={endDate}
              setIsLoading={setIsLoading}
            />
          )}
          {activeLink === "booking" && (
            <VenueOwnerDashbooardBooking
              selectedVenue={selectedVenue}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              activeCourtID={activeCourtID}
              showoffline={showoffline}
            />
          )}
          {activeLink === "staff" && <StaffMembers venueID={selectedVenue} />}
          {activeLink === "package" && (
            <PackagesDetails venueID={selectedVenue} />
          )}
          {activeLink === "settings" && (
            <VenueDashboardEditDetails
              venueID={selectedVenue}
              venueDetails={venueDetails}
              setIsMainPage={setIsMainPage}
              isMainPage={isMainPage}
              activeCourtID={activeCourtID}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}
