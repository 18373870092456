import React, { Fragment, useEffect, useState } from "react";
import { CustomGetAPI } from "../Utils/APIConnector";
import ListingCard from "../PackagesComp/ListingCard";
import OwnerListingCard from "../PackagesComp/OwnerListingCard";
import Loader from "../Utils/Loader";

export default function PackagesDetails({ venueID }) {
  const [packageData, setPackageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getPackages();
  }, []);
  const getPackages = async () => {
    try {
      const response = await CustomGetAPI(
        `/Packages/PackageByID/${venueID}/0`
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result?.status == "Success") {
            setPackageData(result.packages);
          }
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Fragment>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {packageData?.length > 0 ? (
            <div className="row mt-4 mx-0">
              <OwnerListingCard
                packageData={packageData}
                setPackageData={setPackageData}
              />
            </div>
          ) : (
            <div className="row mt-4 mx-0 justify-content-center fw-bold text-center">
              No Active Packages
            </div>
          )}
        </>
      )}
    </Fragment>
  );
}
