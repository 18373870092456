import React, { useEffect, useState } from "react";
import { CustomPostAPI } from "../Utils/APIConnector";
import Loader from "../Utils/Loader";
import { compressImage } from "../Utils/common";

export default function EditVenuePage3({
  venueDetails,
  venueId,
  setIsMainPage,
  setSelectedPage,
}) {
  const [isLoading, SetIsLoading] = useState(true);
  const [filteredAmenities, setFilteredAmenities] = useState();
  const [venueOffersImage, setVenueOffersImage] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [Amenities, setAmenities] = useState();
  const [checkedAmenities, setCheckedAmenities] = useState();
  const [ErrorMessage, setErrorMessage] = useState("");
  const [venueOffers, setVenueOffers] = useState();

  useEffect(() => {
    const raw = JSON.stringify({ VenueId: venueId, stage: 3 });
    CustomPostAPI(`/venue/NewEditVenueDetails`, raw).then((result) => {
      result = JSON.parse(result);
      if (result[0]?.status === "Success") {
        SetIsLoading(false);
        setFilteredAmenities(result[0]?.allamenities);
        setAmenities(result[0]?.allamenities);
        setVenueOffersImage(result[0]?.venueOffers[0]?.image);
        setVenueOffers(result[0]?.venueOffers[0]?.description);
        setCheckedAmenities(
          result[0]?.venueAmenities
            ?.map((amenity) => parseInt(amenity.id))
            .filter((id) => !isNaN(id))
        );
      }
    });
  }, [venueId]);

  const handleSubmit = (e) => {
    const venueOffer = [{ Image: venueOffersImage, Description: venueOffers }];
    const venueAmenities = checkedAmenities.map((amenity) => ({
      Id: amenity,
    }));
    SetIsLoading(true);
    var raw = JSON.stringify({
      stage: 3,
      Userid: window.localStorage.getItem("userid"),
      VenueId: venueId,
      VenueAmenities: venueAmenities,
      venueOffers: venueOffer,
      // VenueName: venueDetails?.venuename,
    });
    CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
      if (result === undefined || result === "") {
        // alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result.status === "Success") {
          window.location.reload();
        }
      }
    });
    SetIsLoading(false);
  };
  const handleBannerChange = async (event) => {
    setErrorMessage("");
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      setErrorMessage(
        `Invalid file type for ${file.name}. Only JPG, JPEG, and PNG files are allowed.`
      );
      return;
    }
    let compressedFile = await compressImage(file);
    if (compressedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVenueOffersImage(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    }
  };

  useEffect(() => {
    setFilteredAmenities(
      Amenities?.filter((data) =>
        data?.amenityName?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    );
  }, [searchTerm]);
  const handleCheckboxChange = (e, i) => {
    let list = [...Amenities];
    const foundItem = list.find((item) => item.amenityId === i);
    if (foundItem) {
      foundItem.checked = e.target.checked;

      setCheckedAmenities((prevChecked) => {
        const updatedChecked = e.target.checked
          ? [...prevChecked, i]
          : prevChecked.filter((id) => id !== i);
        return updatedChecked;
      });
    }
  };

  return (
    <React.Fragment>
      <div className="row mx-0 Montserrat fw-600 mt-3">Amenities</div>
      <div className="row mx-0 Montserrat fw-600">
        <div className="posRel px-0 pt-2">
          <input
            type="search"
            className="form-control py-2"
            placeholder="Search Amenities"
            style={{ padding: "6px 10px 6px 50px" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <img
            src="/images/AmenitiesSearch.svg"
            style={{ marginTop: "-67px", marginLeft: "5px" }}
          />
        </div>
      </div>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          <div className="row mx-0">
            <div className="col-12 bg-F0F0F061 px-0 py-2">
              <div className="row mx-0 px-0">
                {filteredAmenities?.map((data, i) => (
                  <div className="col-6 my-2" key={i}>
                    <div className="amentiesDiv">
                      {" "}
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`flexCheckDefault-${i}`}
                        onClick={(e) => {
                          handleCheckboxChange(e, data?.amenityId);
                        }}
                        checked={checkedAmenities?.includes(data?.amenityId)}
                      />
                      <label
                        className="form-check-label mx-1"
                        htmlFor={`flexCheckDefault-${i}`}
                      >
                        {data.amenityName}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row mx-0 mt-3">
            <div className="col-12 bg-F0F0F061 py-3">
              {" "}
              <b className="px-0">
                Do you have any ongoing offer at your venue?
              </b>
              <br />
              <small className="mt-2 px-0">
                (You can put simple text below, for example, "Book 1 hour and
                get 1 hour free on weekdays!" OR, you can simply upload any
                digital banner of your ongoing offer! We will promote your offer
                on your behalf to all of our users and followers of Instagram at
                zero cost. Also, you will get the benefit of being listed at the
                top with Ongoing Offers in your area/city compared to other
                venues!)
              </small>
              <input
                type="text"
                placeholder="Type offer(optional)"
                className="form-control mt-3"
                name="venueOffers"
                value={venueOffers}
                onChange={(e) => setVenueOffers(e.target.value)}
              />
              <p className="text-center my-1">or</p>
              <div className="d-flex justify-content-center position-relative">
                {!venueOffersImage && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <label
                          htmlFor="bannerUpload"
                          className="venueBannerbtn"
                        >
                          Click here to upload banner
                        </label>
                      </div>
                      <div className="col-12">
                        {ErrorMessage != "" && (
                          <small className="text-danger">{ErrorMessage}</small>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <input
                  type="file"
                  id="bannerUpload"
                  className="d-none"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => handleBannerChange(e)}
                />

                {venueOffersImage && (
                  <div className="">
                    <img
                      src={venueOffersImage}
                      alt="Banner Preview"
                      className="img-fluid"
                    />
                    <img
                      src="/images/Closeicon.svg"
                      className="venueBannerCross"
                      onClick={() => setVenueOffersImage()}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <button
              className="EditVenueSaveBtn"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Save
            </button>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
