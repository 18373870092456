import React, { useEffect, useState } from "react";
import { CustomPostAPI } from "../../Utils/APIConnector";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parse } from "date-fns";
import { formatDateTime } from "../../Utils/common";

export const OuterCardWrapper = ({ Heading, children }) => {
  return (
    <div className="py-5 px-3 bg-white shadow rounded">
      <h3 className="fs-2 fw-bold">{Heading}</h3>
      <div className="d-flex flex-column gap-4 py-4">{children}</div>
    </div>
  );
};

export const InnerCard = ({ subHeading, children }) => {
  return (
    <div className="inner-custom-card p-3 rounded-3 shadow-sm">
      <h4 className="text-center fw-bold">{subHeading}</h4>
      <ul className=" list-unstyled d-flex flex-column gap-1 p-0 m-0">
        {children}
      </ul>
    </div>
  );
};

const LiItem = ({ label, value, onClick, customClass, strongClass }) => {
  return (
    <li className={`d-flex gap-2 ${customClass}`}>
      <label
        className={`d-block d-md-flex gap-2 ${
          onClick ? "cursor-pointer text-decoration-underline " : ""
        }`}
        onClick={onClick ? onClick : undefined}
      >
        <strong className={`${strongClass}`}>{label}</strong>
        {value}
      </label>
    </li>
  );
};

export function RedirectedPage({ apiResult }) {
  const [toogleButton, setToogleButton] = useState("details");
  const [isDisplayDetails, setDisplayDetails] = useState(false);
  const [isDisplayUser, setDisplayUser] = useState(false);

  const [FilteredData, setFilteredData] = useState([]);
  const [backendFiltered, setBackendFilteredData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });

  const [selectedBooking, setSelectedBooking] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [sortingDataOrder, setSortDataOrder] = useState({
    order: "",
    value: "",
  });

  const totalItems =
    FilteredData.length !== 0
      ? FilteredData.length
      : selectedBooking
      ? backendFiltered?.abs?.offlineBookings?.length
      : backendFiltered?.abs?.bookings?.length || 0;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getDateRange = (filterID) => {
    const today = new Date();
    let fromDate, toDate;

    switch (filterID) {
      case "todaybooking":
        fromDate = toDate = new Date(today);
        break;
      case "lastdaybooking":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 1);
        toDate = fromDate;
        break;
      case "lastweekbooking":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 7);
        toDate = today;
        break;
      case "thismonthbooking":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 30);
        toDate = today;
        break;
      case "before30days":
        fromDate = new Date(today.getDate() - 1);
        toDate = new Date(today);
        toDate.setDate(today.getDate() - 30);
        break;
      default:
        fromDate = toDate = today;
    }

    return {
      fromDate: fromDate ? fromDate.toISOString().split("T")[0] : null,
      toDate: toDate ? toDate.toISOString().split("T")[0] : null,
    };
  };

  const ResetData = () => {
    setFilteredData([]);
    setSortedData([]);
    setPaginatedData([]);
    setCurrentPage(1);
  };

  const handleBackButton = () => {
    setSelectedBooking();
    setDisplayDetails(false);
    setDisplayUser(false);
    setCurrentPage(1);
    setSortDataOrder({ order: "", value: "" });
    setItemsPerPage(10);
    ResetData();
  };

  const handleListDetails = (filterID) => {
    if (filterID == "totalUsers") {
      setDisplayUser(true);
      const newFilteredData = apiResult.abs["uniqueUSerData"] || [];
      setFilteredData(newFilteredData);
      setShowLoader(false);
    } else {
      setDisplayDetails(true);
      ResetData();

      if (filterID === "todaybooking" || filterID === "lastdaybooking") {
        setDateRange(getDateRange(filterID));
        const newFilteredData = apiResult.abs[filterID] || [];
        setFilteredData(newFilteredData);
        setShowLoader(false);
      } else {
        setDateRange(getDateRange(filterID));
      }
    }
  };

  const fetchFilteredData = async () => {
    setShowLoader(true);
    const fetchPassword = localStorage.getItem("User_Authenticated");
    var raw = JSON.stringify({
      password: fetchPassword ? "SprigSt@ck99" : "",
      from: dateRange.fromDate,
      to: dateRange.toDate,
    });

    try {
      const result = await CustomPostAPI("/Auth/validatelogin", raw);
      if (result !== undefined || result !== "") {
        const data = JSON.parse(result);
        setShowLoader(false);
        setBackendFilteredData(data);
        if (selectedBooking) {
          getPaginatedData(data.abs.offlineBookings);
        } else {
          getPaginatedData(data.abs.bookings);
        }
      } else {
        setShowLoader(true);
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleDateChange = (date, field) => {
    setDateRange((prev) => ({
      ...prev,
      [field]: date ? date.toISOString().split("T")[0] : "",
    }));

    ResetData();
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const paginated = dataToPaginate?.slice(startIndex, endIndex);
    setPaginatedData(paginated);
  };

  useEffect(() => {
    if (
      FilteredData.length > 0 ||
      backendFiltered?.abs?.bookings ||
      backendFiltered?.abs?.offlineBookings
    ) {
      if (selectedBooking) {
        getPaginatedData(
          FilteredData.length > 0
            ? FilteredData
            : backendFiltered?.abs?.offlineBookings
        );
      } else {
        getPaginatedData(
          FilteredData.length > 0
            ? FilteredData
            : backendFiltered?.abs?.bookings
        );
      }
    }
  }, [FilteredData, backendFiltered, currentPage, itemsPerPage]);

  const handleSort = (sortId) => {
    const newSortOrder = sortingDataOrder.order === "asc" ? "desc" : "asc";
    setSortDataOrder({ order: newSortOrder, value: sortId });

    let sorted = [
      ...(FilteredData.length > 0
        ? FilteredData
        : selectedBooking
        ? backendFiltered?.abs?.offlineBookings
        : backendFiltered?.abs?.bookings),
    ];

    newSortOrder === "asc"
      ? sorted?.sort((a, b) =>
          a[sortId]?.toString()?.localeCompare(b[sortId]?.toString())
        )
      : sorted?.sort((a, b) =>
          b[sortId]?.toString().localeCompare(a[sortId]?.toString())
        );

    setSortedData(sorted);
  };

  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      getPaginatedData(sortedData);
    } else if (FilteredData.length > 0) {
      getPaginatedData(FilteredData);
    } else if (selectedBooking) {
      if (backendFiltered?.abs?.offlineBookings) {
        getPaginatedData(backendFiltered?.abs?.offlineBookings);
      }
    } else if (backendFiltered?.abs?.bookings) {
      getPaginatedData(backendFiltered?.abs?.bookings);
    }
  }, [sortedData, FilteredData, backendFiltered, currentPage, itemsPerPage]);

  useEffect(() => {
    if (dateRange.fromDate !== "" && dateRange.toDate !== "") {
      fetchFilteredData();
    }
  }, [dateRange]);

  const getPageNumbers = () => {
    const pages = [];

    // write the maximum visible pages number in the below condition
    if (totalPages <= 7) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 3) {
      pages.push(1, 2, 3, 4, ". . .", totalPages);
    } else if (currentPage >= totalPages - 2) {
      pages.push(
        1,
        ". . .",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages
      );
    } else {
      pages.push(
        1,
        ". . .",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        ". . .",
        totalPages
      );
    }

    return pages;
  };

  document.body.style.overflow = !isDisplayDetails ? "hidden scroll" : "hidden";

  return (
    <React.Fragment>
      {isDisplayUser ? (
        <section className="d-flex flex-column gap-3 custom-section my-5 controlled-height">
          <div className="d-flex flex-md-row flex-column gap-2 gap-md-4 align-items-start align-items-md-center">
            <button
              className="border border-black text-black rounded p-1"
              onClick={() => handleBackButton()}
            >
              Back
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  {[
                    { key: "firstName", label: "User Name" },
                    { key: "cityName", label: "City" },
                    { key: "phone_Number", label: "Mobile Number" },
                    { key: "creatAt", label: "Created At" },
                    { key: "bookingCount", label: "Bookings" },
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      className="cursor-pointer text-nowrap"
                      style={{ whiteSpace: "nowrap", position: "relative" }}
                    >
                      {label}
                      {sortingDataOrder.value === key ? (
                        <span className="ms-2">
                          {sortingDataOrder.order === "asc" ? (
                            <i class="fa fa-solid fa-arrow-up"></i>
                          ) : (
                            <i class="fa fa-solid fa-arrow-down"></i>
                          )}
                        </span>
                      ) : (
                        <span className="ms-2">
                          <i class="fa fa-solid fa-arrow-up"></i>{" "}
                          <i class="fa fa-solid fa-arrow-down"></i>
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.firstName ?? "N/A"}</td>
                    <td>{item.cityName ?? "N/A"}</td>
                    <td>{item.phone_Number ?? "N/A"}</td>
                    <td> {item?.creatAt?.slice(0, 10) ?? "N/A"}</td>
                    <td> {item?.bookingCount ?? "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex flex-wrap w-100 justify-content-center gap-2 gap-md-4 align-items-center">
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))}
              className="rounded border border-black p-1"
            >
              <option value={10} className="fs-6">
                10
              </option>
              <option value={15} className="fs-6">
                15
              </option>
              <option value={20} className="fs-6">
                20
              </option>
              <option value={30} className="fs-6">
                30
              </option>
            </select>
            <button
              className="border border-black text-black rounded p-1"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              {"<<"}
            </button>
            <button
              className="border border-black text-black rounded py-1 px-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            {getPageNumbers().map((page, index) =>
              page === ". . ." ? (
                <span key={index} className="text-black">
                  {". . ."}
                </span>
              ) : (
                <button
                  key={index}
                  style={{
                    backgroundColor: `${currentPage === page ? "#D0D0D0	" : ""}`,
                  }}
                  className={`rounded px-3 py-1 ${
                    currentPage === page
                      ? "border-2 border-primary shadow-lg"
                      : "border border-black"
                  }`}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="border border-black text-black rounded py-1 px-2"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
            <button
              className="border border-black text-black rounded p-1"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </button>
          </div>
        </section>
      ) : !isDisplayDetails ? (
        <section className="my-3 py-4 container overflow-x-hidden">
          <div className="shadow rounded p-4 d-flex flex-column gap-2">
            <h2>Admin Login</h2>
            <div>
              <div className="d-flex gap-2">
                <button
                  className="p-1 rounded border border-black"
                  onClick={() => setToogleButton("details")}
                >
                  Details
                </button>
                <button
                  className="p-1 rounded border border-black"
                  onClick={() => setToogleButton("stats")}
                >
                  Stats
                </button>
              </div>
              {toogleButton === "details" ? (
                <OuterCardWrapper Heading={"Details"}>
                  <InnerCard subHeading={"Venues"}>
                    <LiItem
                      customClass={"mb-3"}
                      label={"Total Venues:"}
                      value={apiResult?.abs?.totalvenues}
                    />
                    <LiItem
                      label={"Online Venues:"}
                      value={apiResult?.abs?.onlinebookable}
                    />
                    <LiItem
                      label={"Offline Venues:"}
                      value={apiResult?.abs?.offlinebookable}
                    />
                    <LiItem
                      label={"Info Only Venues:"}
                      value={apiResult?.abs?.infoonly}
                    />
                  </InnerCard>
                  <InnerCard subHeading={"Bookings"}>
                    <LiItem
                      customClass={"mb-3"}
                      label={"Total Bookings:"}
                      value={apiResult?.abs?.totalBookings}
                    />
                    <LiItem
                      label={"Total Bookings Today:"}
                      value={apiResult?.abs?.todaybooking.length}
                      strongClass={"pe-2 pe-md-0 w-100"}
                      onClick={() => handleListDetails("todaybooking")}
                    />
                    <LiItem
                      label={"Total Bookings Yesterday:"}
                      value={apiResult?.abs?.lastdaybooking.length}
                      strongClass={"pe-2 pe-md-0 w-100"}
                      onClick={() => handleListDetails("lastdaybooking")}
                    />
                    <LiItem
                      label={"Total Bookings in last 7 days:"}
                      value={apiResult?.abs?.lastweekbooking.length}
                      strongClass={"pe-2 pe-md-0 w-100"}
                      onClick={() => handleListDetails("lastweekbooking")}
                    />
                    <LiItem
                      label={"Total Bookings in last 30 days:"}
                      value={apiResult?.abs?.thismonthbooking.length}
                      strongClass={"pe-2 pe-md-0 w-100"}
                      onClick={() => handleListDetails("thismonthbooking")}
                    />
                    <LiItem
                      label={"Total Bookings before last 30 days:"}
                      value={apiResult?.abs?.before30days.length}
                      strongClass={"pe-2 pe-md-0 w-100"}
                      onClick={() => handleListDetails("before30days")}
                    />
                  </InnerCard>
                  <InnerCard subHeading={"Offline Request"}>
                    <LiItem
                      customClass={"mt-3"}
                      label={"Total Request's:"}
                      value={apiResult?.abs?.totalOfflineRequest}
                      strongClass={"pe-2 pe-md-0 w-100"}
                      onClick={() => {
                        setSelectedBooking("offlineBookings");
                        handleListDetails("offlineBookings");
                      }}
                    />
                    <LiItem
                      label={"Total Request's Accepted:"}
                      value={apiResult?.abs?.totalOfflineAccept}
                    />
                    <LiItem
                      label={"Total Payment's Made:"}
                      value={apiResult?.abs?.totalOfflinePay}
                    />
                  </InnerCard>
                  <InnerCard subHeading={"Users"}>
                    <LiItem
                      customClass={"mb-3"}
                      label={"Total Users:"}
                      value={apiResult?.abs?.uniqueUsersCount}
                      strongClass={"pe-2 pe-md-0 w-100"}
                      onClick={() => handleListDetails("totalUsers")}
                    />
                  </InnerCard>
                </OuterCardWrapper>
              ) : (
                <OuterCardWrapper Heading={"Statistics"}>
                  <InnerCard subHeading={"Attempts"}>
                    <LiItem
                      customClass={"mb-3"}
                      label={"Total Attempts:"}
                      value={apiResult?.cbs?.attempts}
                    />
                    <LiItem
                      label={"Last 30 days attempts:"}
                      value={apiResult?.cbs?.last30days}
                    />
                    <LiItem
                      label={"Before 30 days attempts:"}
                      value={apiResult?.cbs?.before30daysAttempts}
                      strongClass={"pe-2 pe-md-0"}
                    />
                    <LiItem
                      label={"Last 7 days attempts:"}
                      value={apiResult?.cbs?.last7days}
                      strongClass={"pe-2 pe-md-0"}
                    />
                    <LiItem
                      label={"Today's attempts:"}
                      value={apiResult?.cbs?.today}
                      strongClass={"pe-2 pe-md-0"}
                    />
                  </InnerCard>
                  <InnerCard subHeading={"Clicks"}>
                    <LiItem
                      customClass={"mb-3"}
                      label={"Total Clicks:"}
                      value={apiResult?.cbs?.clicks}
                    />
                    <LiItem
                      label={"Last 30 days Clicks:"}
                      value={apiResult?.cbs?.last30clicks}
                    />
                    <LiItem
                      label={"Last 7 days Clicks:"}
                      value={apiResult?.cbs?.last7clicks}
                      strongClass={"pe-2 pe-md-0"}
                    />
                    <LiItem
                      label={"Today's Clicks:"}
                      value={apiResult?.cbs?.todayclick}
                      strongClass={"pe-2 pe-md-0"}
                    />
                    <LiItem
                      label={"Unique Clicks:"}
                      value={apiResult?.cbs?.uniqueclicks}
                      strongClass={"pe-2 pe-md-0"}
                    />
                  </InnerCard>
                  <InnerCard subHeading={"First step crossed"}>
                    <LiItem
                      label={"First step crossed:"}
                      value={apiResult?.cbs?.firstStepcrossed}
                    />
                  </InnerCard>
                </OuterCardWrapper>
              )}
            </div>
          </div>
        </section>
      ) : (
        <section className="d-flex flex-column gap-3 custom-section my-5 controlled-height">
          <div className="d-flex flex-md-row flex-column gap-2 gap-md-4 align-items-start align-items-md-center">
            <button
              className="border border-black text-black rounded p-1"
              onClick={() => handleBackButton()}
            >
              Back
            </button>
            <div className="d-flex gap-1 align-items-center">
              <label className="text-nowrap">From Date:</label>
              <DatePicker
                selected={
                  dateRange.fromDate ? new Date(dateRange.fromDate) : null
                }
                onChange={(date) => handleDateChange(date, "fromDate")}
                dateFormat="yyyy-MM-dd"
                className="form-control profileformControl"
                showIcon
              />
            </div>

            <div className="d-flex gap-1 align-items-center">
              <label className="text-nowrap">To Date:</label>
              <DatePicker
                selected={dateRange.toDate ? new Date(dateRange.toDate) : null}
                onChange={(date) => handleDateChange(date, "toDate")}
                dateFormat="yyyy-MM-dd"
                className="form-control profileformControl"
                showIcon
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  {[
                    { key: "isaccept", label: "Accepted" },
                    { key: "venueName", label: "Venue" },
                    { key: "area", label: "Area" },
                    { key: "cityName", label: "City" },
                    { key: "userName", label: "Name" },
                    { key: "userPhone", label: "Number" },
                    { key: "paymentcreateat", label: "Payment Date" },
                    // { key: "bookingTime", label: "Time" },
                    { key: "bookingDate", label: "Play Date" },
                    { key: "venuepaymentlog", label: "Paid" },
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      className="cursor-pointer text-nowrap"
                      style={{ whiteSpace: "nowrap", position: "relative" }}
                    >
                      {label}
                      {sortingDataOrder.value === key ? (
                        <span className="ms-2">
                          {sortingDataOrder.order === "asc" ? (
                            <i class="fa fa-solid fa-arrow-up"></i>
                          ) : (
                            <i class="fa fa-solid fa-arrow-down"></i>
                          )}
                        </span>
                      ) : (
                        <span className="ms-2">
                          <i class="fa fa-solid fa-arrow-up"></i>{" "}
                          <i class="fa fa-solid fa-arrow-down"></i>
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.isaccept ? "yes" : "no"}</td>
                    <td>{item.venueName}</td>
                    <td>{item.area}</td>
                    <td>{item.cityName}</td>
                    <td>{item.userName}</td>
                    <td>{item.userPhone}</td>
                    <td>
                      {formatDateTime(item.paymentcreateat, "DD/MM/YY hh:mm A")}
                    </td>
                    {/* <td>
                      {item.bookingTime}
                    </td> */}
                    <td>
                      {formatDateTime(
                        item?.bookingDate?.split("T")[0] +
                          "T" +
                          item?.bookingTime,
                        "DD/MM/YY hh:mm A"
                      )}
                    </td>
                    <td>
                      {item.venuepaymentlog == 2 ||
                      item.venuepaymentlog == 4 ||
                      item.venuepaymentlog == 10
                        ? "yes"
                        : "no"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex flex-wrap w-100 justify-content-center gap-2 gap-md-4 align-items-center">
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))}
              className="rounded border border-black p-1"
            >
              <option value={10} className="fs-6">
                10
              </option>
              <option value={15} className="fs-6">
                15
              </option>
              <option value={20} className="fs-6">
                20
              </option>
              <option value={30} className="fs-6">
                30
              </option>
            </select>
            <button
              className="border border-black text-black rounded p-1"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              {"<<"}
            </button>
            <button
              className="border border-black text-black rounded py-1 px-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            {getPageNumbers().map((page, index) =>
              page === ". . ." ? (
                <span key={index} className="text-black">
                  {". . ."}
                </span>
              ) : (
                <button
                  key={index}
                  style={{
                    backgroundColor: `${currentPage === page ? "#D0D0D0	" : ""}`,
                  }}
                  className={`rounded px-3 py-1 ${
                    currentPage === page
                      ? "border-2 border-primary shadow-lg"
                      : "border border-black"
                  }`}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="border border-black text-black rounded py-1 px-2"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
            <button
              className="border border-black text-black rounded p-1"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </button>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}
