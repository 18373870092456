import React from "react";
import { CustomPostAPI } from "../Utils/APIConnector";
import { useState } from "react";
import $ from "jquery";
import Swal from "sweetalert2";
import { useLocation, useSearchParams } from "react-router-dom";

export default function FirstCampain() {
  const [data, setData] = useState({ name: "", phone: "" });
  const [searchParams] = useSearchParams();
  const handlePromo = () => {
    let isValid = true;
    if (!data?.name || !data?.phone) {
      $(".errorDiv").text("All field's are required");
      isValid = false;
    } else if (data?.phone?.length != 10) {
      $(".errorDiv").text("Please enter 10 digit number");
      isValid = false;
    }
    if (isValid) {
      $(".errorDiv").text("");
      const raw = JSON.stringify({
        PromotionId: "1",
        Name: data?.name,
        Phone: data?.phone,
        LeadSource: searchParams.get("utm_source")
          ? searchParams.get("utm_source")
          : "Organic",
      });
      CustomPostAPI("/venue/Promotionlogsadd", raw).then((result) => {
        if (result) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Promo code sent on WhatsApp.",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setData({ name: "", phone: "" });
            }
          });
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="position-relative bg-Banner">
        {/* <img
          src="./images/Campains/FirstCampain.png"
          className="w-100 h-100"
          alt=""
        /> */}
        <div
          className="position-absolute bottom-5   w-100 mb-2"
          style={{ height: "105px" }}
        >
          <div className="row mx-4 mx-md-5 campainDiv d-md-flex ">
            <div className="col-4 px-0">Name</div>
            <div className="col-8">
              <input
                type="text"
                value={data?.name}
                className="w-100"
                onChange={(e) => {
                  setData((prev) => ({ ...prev, name: e.target.value }));
                }}
                name=""
                id=""
              />
            </div>
          </div>
          <div className="row mx-4 mx-md-5 my-1 campainDiv d-md-flex ">
            <div className="col-4 px-0">WhatsApp No</div>
            <div className="col-8">
              <input
                type="number"
                value={data?.phone}
                className="w-100"
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  } else {
                    setData((prev) => ({ ...prev, phone: e.target.value }));
                  }
                }}
                name=""
                id=""
              />
            </div>
          </div>
          <div className="row  mt-4 mx-5 d-md-flex justify-content-center   campainDiv">
            <div className="col-12 col-md-8 mx-auto">
              <button
                className="btn-danger btn"
                onClick={() => {
                  handlePromo();
                }}
              >
                <b>Get promo code on WhatsApp</b>
              </button>
            </div>
          </div>
          <div className="row mx-4 mx-md-5 campainDiv d-md-flex ">
            <small className="text-danger text-center errorDiv"></small>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
