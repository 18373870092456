import $ from "jquery";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useEffect, useState } from "react";
import NavBarDesktop from "./NavBarDesktop";
import { isValid } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../App";
import Cookies from "universal-cookie";
import Popup from "../LiveScoreCardpopups/Popup";
import VenueSearch from "../VenueListing/venueSearch";
import GetApp from "../GetApp/GetApp";

export function Navbar({ MainLayout }) {
  const { Auth } = useContext(UserContext);
  const { pathname } = useLocation();
  const userid = window.localStorage.getItem("userid");
  const userFullName = window.localStorage.getItem("UserName");
  const UserMobileNo = window.localStorage.getItem("UserMobileNo");
  const userProfileImage = window.localStorage.getItem("UserProfileImage");
  const UserType = window.localStorage.getItem("UserType");
  const userLocation = window.localStorage.getItem("userLocation");
  const [isGuest, SetIsGuest] = useState(false);
  const cookies = new Cookies();
  const [Isapp, setIsapp] = useState(false);
  useEffect(() => {
    if (cookies.get("Isapp") === undefined) {
      setIsapp(false);
    } else {
      setIsapp(true);
    }
  }, [cookies.get("Isapp")]);
  useEffect(() => {
    if (
      isNaN(userid) ||
      userid === null ||
      userid === 0 ||
      UserType === "Guest" ||
      UserType === "" ||
      UserType === null
    ) {
      SetIsGuest(true);
    } else {
      SetIsGuest(false);
    }
  }, [Auth]);
  function logout() {
    localStorage.clear();
    cookies.remove("AuthToken", { path: "/" });
    cookies.remove("userid", { path: "/" });
    // removeCookie("IsPersist");
    window.location = "/home";
  }
  const ShowCreatedMatches = () => {
    setShow(false);

    if (isGuest) {
      window.localStorage.setItem("RedirectURL", window.location.href);
      window.location = "/welcome";
      // SetIsGuestPopup(true);
      // SetRedirectURL("/createdmatches");
    } else {
      window.location = "/created-matches";
    }
  };

  const navigate = useNavigate();
  const ShowJoinedMatches = () => {
    setShow(false);

    if (isGuest) {
      window.localStorage.setItem("RedirectURL", window.location.href);
      window.location.href = "/welcome";
    } else {
      window.location.href = "/joined-matches";
    }
  };
  const ShowBookedVenues = () => {
    setShow(false);

    if (isGuest) {
      window.localStorage.setItem("RedirectURL", window.location.href);
      window.location = "/welcome";
    } else {
      navigate("/venue-booking-details");
    }
  };

  const loingPage = () => {
    window.location = "/welcome";
  };
  const signUp = () => {
    window.localStorage.setItem("UserType", "User");
    window.location = "/registration";
  };
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Close = () => {
    setShow(false);
  };
  const ShowProfile = () => {
    window.location.href = "/user-profile";
  };
  const SubmenuShow = () => {
    var findimg = $(".imagerotate").find("img");
    if (!$(".Submenu").hasClass("d-none")) {
      findimg.css("transform", "");
    } else {
      findimg.css("transform", "rotate(90deg)");
    }
    $(".Submenu").toggleClass("d-none");
  };
  return (
    <>
      <div class="navbar-desktop-only">
        <div className={`desktop is-sticky`}>
          <NavBarDesktop
            ShowJoinedMatches={ShowJoinedMatches}
            ShowCreatedMatches={ShowCreatedMatches}
            ShowBookedVenues={ShowBookedVenues}
            loingPage={loingPage}
            isGuest={isGuest}
            logout={logout}
            signUp={signUp}
            MainLayout={MainLayout}
            Close={Close}
            setShow={setShow}
            SubmenuShow={SubmenuShow}
            UserMobileNo={UserMobileNo}
            ShowProfile={ShowProfile}
            userFullName={userFullName}
            userProfileImage={userProfileImage}
            handleClose={handleClose}
            show={show}
            handleShow={handleShow}
          />
        </div>
      </div>
      <div class="navbar-mobile-only">
        <div className={`px-md-5 is-sticky`}>
          <div className="row mx-0 px-md-2 d-flex align-items-center">
            <div className="col-6 p-md-0">
              {pathname !== "/campaign" ? (
                <a href={"/home"}>
                  <img
                    src="/images/cricketFlash.png"
                    style={{ height: "3.5rem", width: "8rem" }}
                    className="mt-1 mb-2 ms-0"
                  />
                </a>
              ) : (
                <img
                  src="/images/cricketFlash.png"
                  style={{ height: "3.5rem", width: "8rem" }}
                  className="mt-1 mb-2 ms-0"
                />
              )}
            </div>
            <div className="col-4">{!Isapp && <GetApp header="true" />}</div>
            <div className="col-2 align-content-center p-0">
              <div className="row justify-content-center">
                <div
                  className={`${
                    !isGuest ? "col-3 p-0 text-end" : "col-4 text-end p-0"
                  }`}
                >
                  {pathname !== "/campaign" && (
                    <button
                      variant="primary"
                      onClick={handleShow}
                      style={{ border: "0", backgroundColor: "transparent" }}
                    >
                      <img
                        src="/images/topmenu-icon.svg"
                        className="img-fluid"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>

            <Offcanvas show={show} onHide={handleClose} placement={"end"}>
              {!isGuest ? (
                <>
                  <Offcanvas.Header style={{ background: "#e4e4e6" }}>
                    <div className="row d-flex align-items-center">
                      <div className="col-5 ">
                        {!isValid(userProfileImage) ? (
                          <img
                            src="./images/dummyavtar.png"
                            className="img-fluid profileImage"
                          ></img>
                        ) : (
                          <img
                            src={userProfileImage}
                            className="img-fluid NavbarProfileImageDiv"
                          ></img>
                        )}
                      </div>
                      <div className="col-7">
                        <div className="row d-flex align-items-center">
                          <div className="name col-9">
                            <b>{userFullName}</b>
                          </div>
                          <div className="col-3 ">
                            <img
                              src="/images/EditNewIcon.svg"
                              className=""
                              style={{ width: "15px" }}
                              onClick={ShowProfile}
                            ></img>
                          </div>
                        </div>

                        <div className="mt-2">{UserMobileNo}</div>
                      </div>
                    </div>
                  </Offcanvas.Header>
                </>
              ) : (
                ""
              )}

              <Offcanvas.Body>
                {!isGuest ? (
                  <>
                    <div className="row  d-flex align-items-center">
                      <div className="col-2 mb-2">
                        <img
                          className="img-fluid"
                          src="/images/HomeNavIcon.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 p-0">
                        <a
                          className="nav-link active ms-0 ps-0 pb-0"
                          aria-current="page"
                          href={"/home"}
                        >
                          <b>Home</b>
                        </a>
                      </div>
                    </div>

                    <>
                      {UserType !== "Venueowner" && UserType !== "Staff" && (
                        <>
                          {/* <div
                            className="row  d-flex align-items-center my-2"
                            onClick={SubmenuShow}
                          >
                            <div className="col-2 mb-2">
                              <img
                                className="pt-2 img-fluid"
                                src="/images/mymatchesicon.svg"
                                alt=""
                              />
                            </div>
                            <div className="col-8 p-0">
                              <div
                                className="nav-link ms-0 ps-0"
                                id="navbarDropdown"
                              >
                                My Matches
                              </div>
                            </div>
                            <div className="col-2 imagerotate mb-2">
                              <img
                                className="pt-2"
                                src="/images/Arrow-right.svg"
                              />
                            </div>
                          </div>
                          <div className="d-none Submenu ps-5 ">
                            <div>
                              <a
                                className="nav-link active ms-0 ps-0 pb-0"
                                aria-current="page"
                                onClick={ShowCreatedMatches}
                              >
                                Created Matches
                              </a>
                            </div>

                            <div className="">
                              <a
                                className="nav-link active ms-0 ps-0 pb-0"
                                aria-current="page"
                                onClick={ShowJoinedMatches}
                              >
                                Joined Matches
                              </a>
                            </div>
                          </div> */}

                          <div className="row  d-flex align-items-center my-2">
                            <div className="col-2 mb-1">
                              {" "}
                              <img
                                className="img-fluid"
                                src="/images/bookedvenues.svg"
                                alt=""
                              />
                            </div>
                            <div className="col-10 p-0">
                              <a
                                className="nav-link active ms-0 ps-0 pb-0"
                                aria-current="page"
                                onClick={ShowBookedVenues}
                              >
                                My Bookings
                              </a>
                            </div>
                          </div>
                        </>
                      )}
                      {(UserType === "Venueowner" ||
                        UserType === "Staff" ||
                        UserType === "Hybrid" ||
                        UserType === "Admin") && (
                        <div className="row  d-flex align-items-center my-2">
                          <div className="col-2 mb-1">
                            {" "}
                            <img
                              className="img-fluid"
                              src="/images/my-venues.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-10 p-0">
                            <a
                              className="nav-link active ms-0 ps-0 pb-0"
                              aria-current="page"
                              onClick={() =>
                                (window.location = "/home-venue-owner")
                              }
                            >
                              {UserType === "Admin"
                                ? "Venue Requests"
                                : "My Venues"}
                            </a>
                          </div>
                        </div>
                      )}
                      {(UserType === "Admin" ||
                        UserType === "Hybrid" ||
                        UserType === "Venueowner") && (
                        <div className="row  d-flex align-items-center my-2">
                          <div className="col-2 mb-1">
                            <img
                              className="img-fluid"
                              src="/images/special-offer.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-10 p-0">
                            <a
                              className="nav-link active ms-0 ps-0 pb-0"
                              aria-current="page"
                              onClick={() => (window.location = "/coupon-home")}
                            >
                              View Coupon
                            </a>
                          </div>
                        </div>
                      )}
                      {(UserType === "Hybrid" || UserType === "User") && (
                        <div className="row  d-flex align-items-center my-2">
                          <div className="col-2 mb-1">
                            {" "}
                            <img
                              className="img-fluid"
                              src="/images/mypackagesicon.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-10 p-0">
                            <a
                              className="nav-link active ms-0 ps-0 pb-0"
                              aria-current="page"
                              onClick={() => (window.location = "/mypackages")}
                            >
                              My Packages
                            </a>
                          </div>
                        </div>
                      )}
                      {(UserType === "Hybrid" || UserType === "User") && (
                        <div className="row  d-flex align-items-center my-2">
                          <div className="col-2 mb-1">
                            {" "}
                            <img
                              className="img-fluid"
                              src="/images/referIcon.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-10 p-0">
                            <a
                              className="nav-link active ms-0 ps-0 pb-0"
                              aria-current="page"
                              onClick={() =>
                                (window.location = "/referandearn")
                              }
                            >
                              Refer & Earn
                            </a>
                          </div>
                        </div>
                      )}

                      {/* {((UserType != "Venueowner" && UserType != "Staff") ||
                        UserType === "Hybrid" ||
                        UserType === "Admin") && (
                        <div className="row  d-flex align-items-center my-2">
                          <div className="col-2 mb-1">
                            <img
                              className="img-fluid"
                              src="/images/Navbar-match.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-10 p-0">
                            <a
                              className="nav-link active ms-0 ps-0 pb-0"
                              aria-current="page"
                              onClick={(e) => {
                                setShow(false);
                                window.location.href = "/match-join-request";
                              }}
                            >
                              Match Requests
                            </a>
                          </div>
                        </div>
                      )} */}
                    </>
                  </>
                ) : (
                  <>
                    <div className="row  d-flex align-items-center my-2">
                      <div className="col-2 mb-1">
                        <img
                          className="img-fluid"
                          src="/images/bookvenuelogo.svg"
                          // style={{height: '31px'}}
                          alt=""
                        />
                      </div>
                      <div className="col-10 p-0">
                        <a
                          className="nav-link active ms-0 ps-0"
                          aria-current="page"
                          onClick={() => {
                            if (!userLocation) {
                              setShowModal(true);
                              setShow(false);
                            } else {
                              window.location.href = `/venue-search/${userLocation.toLocaleLowerCase()}/sports/all`;
                            }
                          }}
                        >
                          Book Venue
                        </a>
                      </div>
                    </div>
                  </>
                )}
                <div className="row  d-flex align-items-center my-2">
                  <div className="col-2 mb-1">
                    {" "}
                    <img
                      className="img-fluid"
                      src="/images/packageicon.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-10 p-0">
                    <a
                      className="nav-link active ms-0 ps-0 pb-0"
                      aria-current="page"
                      onClick={() => (window.location = "/packages")}
                    >
                      View Packages
                    </a>
                  </div>
                </div>
                <div className="row  d-flex align-items-center my-2">
                  <div className="col-2 mb-1">
                    <img
                      className="img-fluid"
                      src="/images/contectusicon.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-10 p-0">
                    <a
                      className="nav-link active ms-0 ps-0 pb-0"
                      aria-current="page"
                      onClick={(e) => {
                        setShow(false);
                        window.location.href = "/contact-us";
                      }}
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
                <div className="row  d-flex align-items-center my-2">
                  <div className="col-2 mb-1">
                    {" "}
                    <img
                      className="img-fluid"
                      src="/images/faqicon.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-10 p-0">
                    <a
                      className="nav-link active ms-0 ps-0 pb-0"
                      aria-current="page"
                      onClick={(e) => {
                        setShow(false);
                        window.location.href = "/faq";
                      }}
                    >
                      FAQ
                    </a>
                  </div>
                </div>
                {!isGuest ? (
                  <>
                    <div className="row  d-flex align-items-center my-2">
                      <div className="col-2 mb-1">
                        <img
                          className="img-fluid"
                          src="/images/LogoutIcon.svg"
                          // style={{height: '31px'}}
                          alt=""
                        />
                      </div>
                      <div className="col-10 p-0">
                        <a
                          className="nav-link active ms-0 ps-0"
                          aria-current="page"
                          onClick={logout}
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row d-flex align-items-center">
                      <div className="col-2">
                        <img
                          className="img-fluid"
                          // src="/images/LoginIcon.svg"
                          src="/images/login_signup.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 p-0">
                        <a
                          className="nav-link active ms-0 ps-0 "
                          aria-current="page"
                          onClick={loingPage}
                          style={{ marginLeft: "10px" }}
                        >
                          Login/Signup
                        </a>
                      </div>
                    </div>
                  </>
                )}
                <div className="row m-auto mt-3">
                  <button className="navclsbtn" onClick={Close}>
                    <img src="/images/Closeicon.svg" alt="" />
                  </button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>

            <div className="col-12 mt-2 d-none hdnSearchDiv">
              <form>
                <div className="m-2">
                  <input
                    type="search"
                    className="form-control searchInput"
                    placeholder="Search"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Popup show={showModal} onHide={() => setShowModal(false)}>
        <VenueSearch sport={"all"} />
      </Popup>
    </>
  );
}
