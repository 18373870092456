import React from "react";

export default function MainFooter() {
  const windowWidth = window.innerWidth;
  return (
    <React.Fragment>
      <div
        className="row mx-0 landingfooter  pt-4 mt-5  px-md-5"
        style={{ background: "#F1F1F1" }}
      >
        <div className="col-md-4 text-md-start text-center mb-4 my-md-0">
          {/* <img
            className="img-fluid me-md-5 me-5"
            src="/images/linkedin.svg"
            alt=""
          /> */}
          <a href="https://www.facebook.com/cricbuddy.in/" target="_blank">
            <img
              className="img-fluid me-md-5 me-5"
              src="/images/facebook.svg"
              alt=""
            />
          </a>

          <a href="https://www.instagram.com/cricbuddy.in/" target="_blank">
            <img className="img-fluid" src="/images/instagram.svg" alt="" />
          </a>

          {/* <img className="img-fluid " src="/images/twitter.svg" alt="" /> */}
        </div>
        <div className="col-md-8 text-md-end text-center mb-3 my-md-0 footertext">
          <img className="img-fluid me-2" src="/images/email.svg" alt="" />
          <span className=" me-4">support@cricbuddy.in</span>
          <img className="img-fluid me-2" src="/images/phone-call.svg" alt="" />
          <span className="me-4">+91 9726970725</span>
          {windowWidth > 990 && <span className="">Timing: 10am to 6pm</span>}
        </div>
        <div className="col-md-6 text-md-center text-center my-md-0 footertext"></div>
        <div className="col-md-6 text-md-center text-center my-md-0 footertext">
          {windowWidth <= 990 && <span className="">Timing: 10am to 6pm</span>}
        </div>
        <div className="col-12 text-center  footerlinks mt-4">
          <a href="/">Home</a> | <a href="/contact-us">Contact Us</a> |{" "}
          <a href="/faq">FAQ</a> | <a href="/terms-and-conditions">T&C</a> |{" "}
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
        <div className="col-12 text-center my-3 footerbookmark">
          @2024 Cricbuddy, All rights reserved.
        </div>
      </div>
    </React.Fragment>
  );
}
