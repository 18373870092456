import React, { Fragment, useState } from "react";
import { convertTo12HourFormat, VenueDate } from "../Utils/common";
import Popup from "../LiveScoreCardpopups/Popup";
import PackageHistoryCard from "./PackageHistoryCard";
import PackageDetailsPopup from "./PackageDetailsPopup";
export default function MyPackagesCard({
  packageData,
  payNow,
  setPackageData,
  setSelectedIndex,
  setSelectedPackageId,
  setSelectedPackageDuration,
  userPackagesPage,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedUserPackage, setSelectedUserPackage] = useState();
  const [BookingHistory, setBookingHistory] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [selectedPackageName, setSelectedPackageName] = useState("");
  const [selectedPackageExpiry, setSelectedPackageExpiry] = useState("");
  return (
    <Fragment>
      <div className="col-md-7 col-12 m-auto">
        <div className="row mx-0">
          {packageData?.map((item, index) => (
            <div className="col-md-6 pe-md-0">
              <div className="row mx-0 mb-4 packageListingCard">
                <div className="col-12 d-flex justify-content-between align-items-center p-2">
                  <div
                    className="mt-2 truncate-text"
                    style={{ maxWidth: "175px" }}
                  >
                    <img src="/images/Location-Booking.svg" alt="" />{" "}
                    <b className="">{item?.venuename}</b>
                  </div>
                  <div className="packageTag mt-2 truncate-text">
                    {item?.name} <b>({item?.packageDuration}hrs)</b>
                  </div>
                </div>
                <div className="col-12">
                  <hr className="my-1 px-2" />
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center p-2 flex-wrap">
                  <div>
                    <p>Purchased on</p>
                    <b> {VenueDate(item?.userPurchasedDate).formattedDate}</b>
                  </div>

                  <div>
                    {new Date(item?.userExpiryDate) > new Date() ? (
                      <>
                        <p>Expires on</p>
                        <b>{VenueDate(item?.userExpiryDate).formattedDate}</b>
                      </>
                    ) : (
                      <>
                        <p>Expired on</p>
                        <b>{VenueDate(item?.userExpiryDate).formattedDate}</b>
                      </>
                    )}
                  </div>
                  <div>
                    <p>Hours left</p>
                    <b>
                      {item?.packagePricings?.reduce(
                        (sum, i) => sum + (i.remainingDuration || 0),
                        0
                      )}
                    </b>
                  </div>
                </div>
                <div className="col-12">
                  <hr className="my-1 px-2" />
                </div>
                <div className="col-12 d-flex justify-content-between align-items-start p-2 flex-wrap">
                  <div className="itemPrice">
                    <b
                      className="text-decoration-underline cursor-pointer"
                      style={{ fontSize: "12px" }}
                      onClick={() => {
                        setShowHistory(true);
                        setBookingHistory(
                          item?.packagePricings?.flatMap(
                            (i) => i.venueBookings
                          ) || []
                        );
                        setSelectedVenue(item?.venuename);
                      }}
                    >
                      Booking History
                    </b>
                  </div>
                  <div className="itemPrice">
                    <b style={{ color: "#4caf50" }}>₹ {item?.price}/-</b>
                  </div>
                  {/* <b className="packageOffTag">
                {item?.diffPercentage?.toFixed(0)}% OFF
              </b> */}
                  <div className="text-end">
                    {new Date(item?.userExpiryDate) > new Date() ||
                    item?.packagePricings?.reduce(
                      (sum, i) => sum + (i.remainingDuration || 0),
                      0
                    ) == 0 ? (
                      <button
                        className="packageDetailsBtn"
                        onClick={() => {
                          setShowDetails(true);
                          setSelectedPackage(item?.id);
                          setSelectedUserPackage(item?.userPackageId);
                          setSelectedVenue(item?.venuename);
                          setSelectedPackageName(item?.name);
                          setSelectedPackageExpiry(item?.userExpiryDate);
                        }}
                      >
                        Book Now
                      </button>
                    ) : (
                      <b style={{ opacity: "0.6" }}> Expired</b>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Popup
        show={showHistory}
        venueflag={1}
        onHide={() => setShowHistory(false)}
      >
        <div className="Container mt-2 ">
          <PackageHistoryCard
            BookingHistory={BookingHistory}
            venueName={selectedVenue}
          />
        </div>
      </Popup>
      {showDetails && (
        <Popup
          show={showDetails}
          venueflag={1}
          title={
            <div>
              <h4>
                {selectedPackageName} at{" "}
                <span className="colorClass fw-bold">{selectedVenue}</span>
              </h4>
            </div>
          }
          hideModal={show || showPayment}
          onHide={() => setShowDetails(false)}
        >
          <div className="Container mt-2 ">
            <PackageDetailsPopup
              show={show}
              setShow={setShow}
              selectedPackage={selectedPackage}
              selectedUserPackage={selectedUserPackage}
              myPackages={true}
              venueName={selectedVenue}
              showPayment={showPayment}
              setShowPayment={setShowPayment}
              expiry={selectedPackageExpiry}
            />
          </div>
        </Popup>
      )}
    </Fragment>
  );
}
