import React, { Fragment, useEffect, useState } from "react";
import Loader from "../Utils/Loader";
import {
  CustomDeleteAPI,
  CustomGetAPI,
  CustomPostAPI,
} from "../Utils/APIConnector";
import Popup from "../LiveScoreCardpopups/Popup";
import {
  areAllWeekdaysCovered,
  CheckValidation,
  convertTo12HourFormat,
} from "../Utils/common";
import PackageDays from "../VenueInformation/PricingDetails/PackageDays";
import Swal from "sweetalert2";

export default function EditVenuePage5({ venueId, activeCourtID }) {
  const [isLoading, SetIsLoading] = useState(true);
  const [add, setAdd] = useState(false);
  const [show, setShow] = useState(false);
  const UserType = localStorage.getItem("UserType");
  const phoneno = localStorage.getItem("phoneno");
  const [packageData, setPackageData] = useState([]);
  const [courtData, setCourtData] = useState([]);
  const [selectedPackageData, setSelectedPackageData] = useState({
    Name: "",
    Price: 0,
    Validity: 0,
    selectedCourt: activeCourtID,
    VenueId: venueId,
    weekDay: [],
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedPackageData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDeleteSelectedData = (dayIndex) => {
    const updatedWeekDay = selectedPackageData?.weekDay.filter(
      (_, index) => index !== dayIndex
    );

    setSelectedPackageData({
      ...selectedPackageData,
      weekDay:
        updatedWeekDay.length > 0
          ? updatedWeekDay
          : [
              {
                weekDays: "",
                data: [
                  {
                    startTime: "",
                    endTime: "",
                    amount: "",
                    duration1: "",
                    duration2: "",
                    duration3: "",
                  },
                ],
              },
            ],
    });
  };

  const handleSubmit = async () => {
    var isFormValid = CheckValidation({ formID: "packagePopup" });
    if (!isFormValid) {
      return;
    }
    if (selectedPackageData?.weekDay?.length < 1) {
      alert("Please select at least one day");
      return;
    }
    try {
      SetIsLoading(true);
      await CustomPostAPI(
        "/Packages/CreatePackage",
        JSON.stringify(selectedPackageData)
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result?.status == "Success") {
            setAdd(false);
            setSelectedPackageData({
              name: "",
              price: "",
              validity: 0,
              selectedCourt: activeCourtID,
              venueid: venueId,
              weekDay: [],
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      getPackages();
    }
  };
  useEffect(() => {
    getPackages();
  }, []);
  const getPackages = async () => {
    SetIsLoading(true);
    try {
      const response = await CustomGetAPI(
        `/Packages/PackageByID/${UserType == "Admin" ? 0 : venueId}/0`
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result?.status == "Success") {
            const updatedPackage = result?.packages?.map((item) => ({
              ...item,
              flag: false,
            }));
            setPackageData(updatedPackage);
            setCourtData(result?.courts);

            if (
              (!activeCourtID || activeCourtID == 0) &&
              result?.courts.length == 1
            ) {
              setSelectedPackageData((prev) => ({
                ...prev,
                selectedCourt: 0,
              }));
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      SetIsLoading(false);
    }
  };
  const deletePackage = async (packageID) => {
    SetIsLoading(true);
    try {
      const response = await CustomDeleteAPI(
        `/Packages/DeletePackage/${packageID}`
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result?.status == "Success") {
          }
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      SetIsLoading(false);
      getPackages();
    }
  };
  const body = (
    <div className="row mx-0" id="packagePopup">
      <div className="col-12">
        {" "}
        <div className="row mt-3">
          <div className="col-md-4">Name:</div>
          <div className="col-md-8 parent">
            <input
              type="text"
              className="form-control"
              name="Name"
              required
              value={selectedPackageData?.Name}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <span className="form-text invalid-feedback">
              * This field is Required!
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">Total Price:</div>
          <div className="col-md-8 parent">
            <input
              type="number"
              className="form-control"
              name="Price"
              required
              value={selectedPackageData?.Price}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <span className="form-text invalid-feedback">
              * This field is Required!
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            Validity<small>(In Days):</small>
          </div>
          <div className="col-md-8 parent">
            <input
              type="number"
              className="form-control"
              name="Validity"
              required
              value={selectedPackageData?.Validity}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <span className="form-text invalid-feedback">
              * This field is Required!
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">Surface:</div>
          <div className="col-md-8">
            <select
              className="form-select"
              name="selectedCourt"
              value={selectedPackageData?.selectedCourt}
              disabled={courtData?.length < 2}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option value="0">All</option>
              {courtData?.map((court) => (
                <option value={court?.id}>{court?.courtName}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3 Montserrat fw-600">
          <div className="col-12">
            Booking Day Availability & Hours*
            <br />
          </div>
          {
            <div className="col-12 Montserrat">
              {" "}
              <button
                className="btn mt-3 addPriceBtn"
                onClick={() => {
                  setShow(true);
                }}
              >
                <img src="/images/AddPricingBtn.svg" className="me-1" alt="" />{" "}
                Add Duration
              </button>
            </div>
          }
          <div className="row mt-3 mx-0 px-0">
            {selectedPackageData?.weekDay?.map(
              (day, dayIndex) =>
                day?.weekDays !== "" &&
                day?.data?.length > 0 &&
                day?.data[0]?.startTime !== "" &&
                day?.data[0]?.amount !== "" &&
                day?.data[0]?.amount !== false &&
                day?.data[0]?.amount !== "0" && (
                  <div key={dayIndex} className="col-md-6">
                    <div
                      className="p-3 mb-3"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "4px",
                        border: "0.5px solid #C4C4C4",
                      }}
                    >
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="fw-600" style={{ fontSize: "16px" }}>
                          {day.weekDays}
                        </div>
                        <div className="d-flex">
                          <img
                            className="cursor-pointer ms-2"
                            src="/images/deleteicon.svg"
                            title="Delete Slot"
                            alt="icon"
                            onClick={() => handleDeleteSelectedData(dayIndex)}
                            height="22px"
                          />
                        </div>
                      </div>
                      {day?.data?.map((item, idx) => (
                        <div
                          key={idx}
                          className="mt-2 weekdayPriceText Montserrat"
                        >
                          <div>
                            Time: {convertTo12HourFormat(item.startTime)} to{" "}
                            {convertTo12HourFormat(item.endTime)}
                            <br />
                            Duration: {item.amount} hr
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <button
          className="EditVenueSaveBtn"
          onClick={(e) => {
            handleSubmit();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
  return (
    <Fragment>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          {add ? (
            <div className="row mx-0 Montserrat fs-16 fw-600 mt-3 mb-2">
              <div className="col-6">
                <img
                  src="/images/backArrow.svg"
                  alt=""
                  onClick={() => {
                    setAdd(false);
                    setSelectedPackageData({
                      name: "",
                      price: "",
                      validity: 0,
                      selectedCourt: activeCourtID,
                      venueid: venueId,
                      weekDay: [],
                    });
                  }}
                />
              </div>
              <div className="col-6 text-end">Add Package</div>
            </div>
          ) : (
            <div className="row mx-0 Montserrat fs-16 fw-600 mt-3 mb-2">
              <div className="col-6">Packages</div>
              <div className="col-6 text-end">
                {phoneno == "0000000000" && (
                  <button
                    className="btnAddCoupon"
                    onClick={() => {
                      setAdd(true);
                    }}
                  >
                    + Add Package
                  </button>
                )}
              </div>
            </div>
          )}

          {add ? (
            body
          ) : (
            <div className="row mx-0">
              {packageData?.length < 1 ? (
                <div className="col-12 mt-3 text-center">No Package Found</div>
              ) : (
                <>
                  {packageData?.map((item, index) => (
                    <div className="col-md-6 my-2">
                      <div className="row mx-0 packagesDiv align-items-center">
                        <div className="col-10 fw-bold">{item.name}</div>
                        <div className="col-1 px-1 d-flex">
                          <img
                            src="/images/deleteicon.svg"
                            alt=""
                            class="img-fluid w-100 cursor-pointer"
                            onClick={() => {
                              Swal.fire({
                                icon: "warning",
                                title: "Do you want to delete package",
                                text: "Note:- Users that have already purchased can still use this package",
                              }).then((result) => {
                                if (result?.isConfirmed) {
                                  deletePackage(item?.id);
                                }
                              });
                            }}
                          />
                        </div>
                        <div className="col-1 px-1">
                          {item?.flag ? (
                            <i
                              class="fa fa-chevron-circle-up cursor-pointer"
                              style={{ color: "#a51e22" }}
                              aria-hidden="true"
                              onClick={() => {
                                setPackageData((prev) =>
                                  prev.map((pkg, i) =>
                                    i === index
                                      ? { ...pkg, flag: !pkg.flag }
                                      : { ...pkg, flag: false }
                                  )
                                );
                              }}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-chevron-circle-down cursor-pointer"
                              aria-hidden="true"
                              style={{ color: "#a51e22" }}
                              onClick={() => {
                                setPackageData((prev) =>
                                  prev.map((pkg, i) =>
                                    i === index
                                      ? { ...pkg, flag: !pkg.flag }
                                      : { ...pkg, flag: false }
                                  )
                                );
                              }}
                            ></i>
                          )}
                        </div>
                        <div
                          className="col-12 fw-bold "
                          style={{ color: "#4caf50" }}
                        >
                          ₹{item.price}
                        </div>
                        <div className="col-6 ">
                          <p className="mb-0">Validity (In Days)</p>
                          <b>{item.validity}</b>
                        </div>
                        <div className="col-6 text-end">
                          <p className="mb-0">Court</p>
                          <b>
                            {item?.packagePricings[0]?.venueSportId == 0
                              ? "All"
                              : courtData?.find(
                                  (court) =>
                                    court?.id ===
                                    item?.packagePricings[0]?.venueSportId
                                )?.courtName || "Court"}
                          </b>
                        </div>
                        {item?.flag && (
                          <>
                            <hr className="my-1" />
                            <div className="col-12 fw-bold font-13 ">
                              Booking Day Availability & Hours:
                            </div>
                            <div className="col-12 ">
                              <div className="row mx-0">
                                {item?.packagePricings?.map((item, idx) => (
                                  <div
                                    key={idx}
                                    className="mt-2 weekdayPriceText Montserrat py-2"
                                    style={{
                                      backgroundColor: "white",
                                      borderRadius: "4px",
                                      border: "0.5px solid #C4C4C4",
                                    }}
                                  >
                                    <div className="fw-semibold">
                                      Days:{item?.weekDays} <br />
                                      Time:
                                      {item.startTime == item.endTime ? (
                                        "24hrs"
                                      ) : (
                                        <>
                                          {convertTo12HourFormat(
                                            item.startTime
                                          )}{" "}
                                          to{" "}
                                          {convertTo12HourFormat(item.endTime)}
                                        </>
                                      )}
                                      <br />
                                      Duration: {item.duration} hr
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </>
      )}
      {show && (
        <Popup
          show={show}
          onHide={() => {
            setShow(false);
          }}
          venueflag={1}
        >
          <PackageDays
            dispatch={setSelectedPackageData}
            setShow={setShow}
            // ShowEnterData={selectedPackageData?.weekDay}
          />
        </Popup>
      )}
    </Fragment>
  );
}
