import React, { Fragment, useEffect, useState } from "react";
import { CustomGetAPI } from "./Utils/APIConnector";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "./Utils/Loader";
import Cookies from "universal-cookie";

export default function NearbyVenue() {
  const [searchParams] = useSearchParams();
  const [nearByData, setNearByData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const loction = useLocation();
  const cookies = new Cookies();

  const userLocation = localStorage.getItem("userLocation");
  const location = searchParams.get("location");
  const time = searchParams.get("time");
  const duration = searchParams.get("duration");
  const sport = searchParams.get("sport");

  cookies.set(
    "nearByObj",
    JSON.stringify({
      dateTime: time,
      selectedSport: sport,
      slotDuration: duration,
    }),
    {
      path: "/",
      expires: new Date(Date.now() + 86400000),
    }
  );
  useEffect(() => {
    getNearByVenue();
  }, []);

  const getNearByVenue = async () => {
    setIsLoading(true);
    try {
      await CustomGetAPI(
        `/venue/Searchvenuesbytime/${time.split("T")[0]}/${
          time.split("T")[1]
        }/${duration}/${location}/${sport}`
      ).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result.status == "Success") {
            setNearByData(result?.venues);
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Fragment>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <div className="row mx-0">
            <div className="col-1 ps-0">
              <img
                className="img-fluid cursor-pointer"
                src="/images/backArrow.svg"
                height={15}
                onClick={() => {
                  navigate(`/venue-search/${userLocation}/${location}`);
                }}
              />
            </div>
            <div className="col-11">
              <b>Venues matching your requirements are listed below</b>
            </div>
          </div>
          {nearByData?.map((nearby) => (
            <div
              className="row mx-0 my-3 py-2 align-items-center"
              style={{ border: "1px solid #E7666A", borderRadius: "10px" }}
            >
              <div className="col-3">
                <img
                  className="nearbyimg"
                  src={nearby?.venueImages[0]?.image}
                  alt=""
                />
              </div>
              <div className="col-9">
                <div className="truncate-text">
                  <b>{nearby?.name}</b>
                </div>
                <div style={{ opacity: "0.5", fontSize: "12px" }}>
                  {nearby?.venueAddresses[0]?.addressline2},{" "}
                  {nearby?.venueAddresses[0]?.city?.name}
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div
                    style={{
                      background: "#EFEFEF",
                      border: "0.5px solid #0000004D",
                      borderRadius: "10px",
                      padding: "5px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    ₹ {nearby?.minSlotTime}/Hr{" "}
                  </div>
                  <button
                    className="packageDetailsBtn"
                    onClick={() => {
                      localStorage.setItem(
                        "venueRedirectURL",
                        loction?.pathname + loction?.search
                      );
                      navigate(
                        `/venue-search/${nearby?.venueAddresses[0]?.city?.name?.toLocaleLowerCase()}/${nearby?.name
                          ?.replace(/\s+/g, "-")
                          ?.toLocaleLowerCase()}`,
                        { state: { isNearby: true } }
                      );
                    }}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </Fragment>
  );
}
