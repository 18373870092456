import React, { Fragment, useState } from "react";
import PackageDetailsPopup from "./PackageDetailsPopup";
import Popup from "../LiveScoreCardpopups/Popup";

export default function OffersPopup({
  selectedOffers,
  selectedVenue,
  showDetails,
  setShowDetails,
}) {
  const [showPayment, setShowPayment] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedPackageName, setSelectedPackageName] = useState("");
  const [selectedPackage, setSelectedPackage] = useState();
  return (
    <Fragment>
      <div className="row" style={{ fontWeight: "500" }}>
        <div className="col-12">
          <p>Select Package</p>
        </div>
        {selectedOffers?.map((offer) => (
          <div className="col-12 mb-3">
            <div
              className="d-flex justify-content-between p-2"
              style={{ border: "1px solid #0000001A", borderRadius: "8px" }}
            >
              <div>{offer?.validity} (Days)</div>
              <div>{offer?.packageDuration} hrs</div>
              <div style={{ color: "#E7666A" }}>
                ₹ {offer?.price}{" "}
                <span
                  style={{
                    color: "#00000080",
                    textDecoration: "line-through",
                    fontWeight: "400",
                  }}
                >
                  {offer?.actualprice}
                </span>
              </div>
              {/* <div style={{ color: "#4CAF50" }}>
                {offer?.diffPercentage?.toFixed(0)}% OFF
              </div> */}
              <div>
                <button
                  className="packageDetailsBtn"
                  onClick={() => {
                    setShowDetails(true);
                    setSelectedPackageName(offer?.name);
                    setSelectedPackage(offer?.id);
                  }}
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showDetails && (
        <Popup
          show={showDetails}
          venueflag={1}
          title={
            <div>
              <h4>
                {selectedPackageName} at{" "}
                <span className="colorClass fw-bold">{selectedVenue}</span>
              </h4>
            </div>
          }
          hideModal={show || showPayment}
          onHide={() => setShowDetails(false)}
        >
          <div className="Container mt-2 ">
            <PackageDetailsPopup
              selectedPackage={selectedPackage}
              show={show}
              setShow={setShow}
              showPayment={showPayment}
              setShowPayment={setShowPayment}
              venueName={selectedVenue}
            />
          </div>
        </Popup>
      )}
    </Fragment>
  );
}
